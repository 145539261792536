import React, {PureComponent} from 'react';
import SensorToggle from './SensorToggle';
import {Dialog, Grid, withStyles} from '@material-ui/core';
import {snakeCaseToNormal} from '../../util';

const styles = (theme) => ({
  container: {
    width: '75rem',
    padding: '1rem',
    backgroundColor: '#E7E9EC',
  },
  titleText: {
    fontWeight: 'bold',
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  gridStyle: {
    backgroundColor: '#FFFFFF',
    margin: '1rem',
    width: '20rem',
    borderRadius: '1rem',
    boxShadow: '0.25rem 0.25rem #BDBFC1',
  },
  subTitle: {
    fontWeight: 'bold',
    color: '#6C6C6C',
  },
});

class RobotSensorControlModal extends PureComponent {
  render() {
    const {
      classes,
      open,
      onClose,
      disabledFryerSensorsArray,
      getDispenserLockSensor,
      getGripperSensor,
      numFryerSensors,
      relayState,
      getElevatorSensor,
      instance,
    } = this.props;

    let fryerLocksRelayState;
    if (relayState !== undefined) {
      fryerLocksRelayState = relayState.filter(
        (item) => item.name === 'fryer_locks'
      );
    } else {
      fryerLocksRelayState = true;
    }

    return (
      <div>
        <Dialog open={open} onClose={onClose} maxWidth="lg">
          <div className={classes.container}>
            <div>
              <p className={classes.titleText}>Robot Sensor Control</p>
            </div>
            <Grid container className={classes.gridContainer}>
              <Grid xs={3} className={classes.gridStyle}>
                <SensorToggle
                  title={'Gripper'}
                  toggle={getGripperSensor}
                  instance={instance}
                  toggleName={'getGripperSensor'}
                />
              </Grid>
              <Grid xs={3} className={classes.gridStyle}>
                <SensorToggle
                  title={'Elevator'}
                  toggle={getElevatorSensor}
                  instance={instance}
                  toggleName={'getElevatorSensor'}
                />
              </Grid>
              <Grid xs={3} className={classes.gridStyle}>
                <SensorToggle
                  title={'Dispenser Lock'}
                  toggle={getDispenserLockSensor}
                  instance={instance}
                  toggleName={'getDispenserLockSensor'}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.gridContainer}>
              <Grid xs={12}>
                <p className={classes.subTitle}>Fryer Locks</p>
              </Grid>
              {Object.keys(numFryerSensors).length > 0 && (
                <>
                  {Object.keys(numFryerSensors).map((sensorKey, index) => (
                    <Grid xs={3} className={classes.gridStyle} key={index}>
                      <SensorToggle
                        title={snakeCaseToNormal(sensorKey)}
                        toggle={disabledFryerSensorsArray}
                        instance={instance}
                        toggleName={'disabledFryerSensorsArray'}
                      />
                    </Grid>
                  ))}
                </>
              )}
              <Grid xs={3} className={classes.gridStyle}>
                <SensorToggle
                  title={'Fryer Relay'}
                  toggle={fryerLocksRelayState}
                  instance={instance}
                  toggleName={'relayState'}
                />
              </Grid>
            </Grid>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(RobotSensorControlModal);

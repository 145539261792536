import React from 'react';
import PropTypes from 'prop-types';

const DispenserNotLocked = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 50 50"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Dispenser Not Locked</title>
      <path
        d="M8.51983 30.421C8.51983 32.4921 6.83646 34.171 4.75992 34.171C2.68337 34.171 1 32.4921 1 30.421C1 28.35 2.68337 26.671 4.75992 26.671C6.83646 26.671 8.51983 28.35 8.51983 30.421Z"
        fill={props.color}
      />
      <path
        d="M30.3998 30.421C30.3998 32.4921 28.7165 34.171 26.6399 34.171C24.5634 34.171 22.88 32.4921 22.88 30.421C22.88 28.35 24.5634 26.671 26.6399 26.671C28.7165 26.671 30.3998 28.35 30.3998 30.421Z"
        fill={props.color}
      />
      <path
        d="M31.3983 21.5464V24.0327C31.3983 25.003 30.6097 25.7895 29.6369 25.7895H1.76139C0.788602 25.7895 0 25.003 0 24.0327V22.0591C0 21.0889 0.788602 20.3024 1.76139 20.3024H14.7227C15.6955 20.3024 16.4841 19.5158 16.4841 18.5456V14.259C16.4841 13.2887 15.6955 12.5022 14.7227 12.5022H1.76139C0.788606 12.5022 0 11.7157 0 10.7454V1.75676C0 0.78653 0.788602 0 1.76139 0H29.6369C30.6097 0 31.3983 0.786531 31.3983 1.75677V4.22497C30.9751 4.40589 30.5786 4.66864 30.2331 5.01323L27.6117 7.62774L24.9903 5.01323C23.5355 3.56228 21.1769 3.56229 19.7221 5.01323C18.2673 6.46418 18.2673 8.81663 19.7221 10.2676L22.3435 12.8821L19.7094 15.5093C18.2546 16.9602 18.2546 19.3126 19.7094 20.7636C21.1642 22.2145 23.5228 22.2145 24.9776 20.7636L27.6117 18.1364L30.2458 20.7636C30.5878 21.1047 30.9798 21.3657 31.3983 21.5464Z"
        fill={props.color}
      />
      <path
        d="M20.79 16.938C20.0362 17.6896 20.0362 18.9083 20.79 19.6599C21.5437 20.4115 22.7656 20.4115 23.5194 19.6599L27.6125 15.5774L31.7056 19.6599C32.4594 20.4116 33.6813 20.4116 34.4351 19.6599C35.1881 18.9083 35.1881 17.6897 34.4351 16.9381L30.3412 12.8554L34.4351 8.7724C35.1889 8.02077 35.1889 6.80217 34.4351 6.05054C33.6813 5.29891 32.4594 5.29891 31.7064 6.05054L27.6125 10.1336L23.5187 6.05063C22.7649 5.299 21.5437 5.299 20.79 6.05063C20.0362 6.80217 20.0362 8.02087 20.79 8.7725L24.883 12.8553L20.79 16.938Z"
        fill={props.color}
      />
    </svg>
  );
};

export default DispenserNotLocked;

DispenserNotLocked.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string.isRequired,
};

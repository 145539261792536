import React from 'react';
import PropTypes from 'prop-types';

const Empty = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 80 80"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Empty</title>
      <path
        stroke="#F69892"
        fill="#F69892"
        d="M40.047 4.935a6.664 6.664 0 00-9.427 0L22 13.555l-8.62-8.62a6.664 6.664 0 00-9.427 0 6.664 6.664 0 000 9.427l8.62 8.62-8.62 8.62a6.664 6.664 0 000 9.426 6.645 6.645 0 004.714 1.953 6.645 6.645 0 004.713-1.953l8.62-8.62 8.62 8.62a6.645 6.645 0 004.713 1.953 6.645 6.645 0 004.714-1.953 6.664 6.664 0 000-9.427l-8.62-8.62 8.62-8.62a6.664 6.664 0 000-9.426z"
      ></path>
    </svg>
  );
};

export default Empty;

Empty.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string.isRequired,
};

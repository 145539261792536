import React from 'react';

const Diagnostics = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.3984 8.98193H6.59844C6.28018 8.98193 5.97495 9.10811 5.74991 9.3327C5.52487 9.5573 5.39844 9.86191 5.39844 10.1795V37.7244C5.39844 38.042 5.52487 38.3466 5.74991 38.5712C5.97495 38.7958 6.28018 38.922 6.59844 38.922H41.3984C41.7167 38.922 42.0219 38.7958 42.247 38.5712C42.472 38.3466 42.5984 38.042 42.5984 37.7244V10.1795C42.5984 9.86191 42.472 9.5573 42.247 9.3327C42.0219 9.10811 41.7167 8.98193 41.3984 8.98193ZM40.1984 36.5268H7.79844V11.3771H40.1984V36.5268Z"
        fill="#525252"
      />
      <path
        d="M13.8712 32.9342C14.0575 32.9342 14.2412 32.891 14.4079 32.8078C14.5745 32.7247 14.7194 32.6039 14.8312 32.4552L21.4192 23.8325L25.2592 26.7546C25.5123 26.9465 25.8314 27.0303 26.1464 26.9876C26.4613 26.9449 26.7465 26.7793 26.9392 26.5271L32.5072 19.2456L32.9992 22.4193C33.0373 22.7092 33.1803 22.9752 33.4014 23.1672C33.6224 23.3591 33.9062 23.4637 34.1992 23.4612H34.3552C34.6499 23.403 34.912 23.2367 35.0898 22.995C35.2675 22.7533 35.348 22.4538 35.3152 22.1558L34.4872 16.084C34.4681 15.9414 34.4233 15.8035 34.3552 15.6768C34.2798 15.5594 34.1867 15.4544 34.0792 15.3654C33.9688 15.2712 33.837 15.2054 33.6952 15.1738C33.5413 15.138 33.3812 15.138 33.2272 15.1738L27.1432 16.0001C26.8249 16.043 26.5368 16.2103 26.3421 16.4652C26.1475 16.7202 26.0622 17.0418 26.1052 17.3594C26.1482 17.677 26.3158 17.9646 26.5712 18.1589C26.8267 18.3532 27.1489 18.4382 27.4672 18.3953L30.5992 17.7726L25.7992 24.0959L21.9472 21.1738C21.6941 20.9819 21.375 20.8981 21.0601 20.9408C20.7451 20.9835 20.4599 21.1491 20.2672 21.4013L12.9592 30.9822C12.767 31.2348 12.683 31.5532 12.7257 31.8675C12.7685 32.1819 12.9345 32.4664 13.1872 32.6588C13.3806 32.82 13.6199 32.9164 13.8712 32.9342Z"
        fill="#525252"
      />
    </svg>
  );
};

export default Diagnostics;

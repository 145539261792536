import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Spinner from 'react-spinkit';
import {getInfo} from '../util';
import {init_ros} from '../ros_init';

const LoadingScreen = ({classes, instance, email, siteId, deviceid}) => {
  let thing_name = siteId + '-' + deviceid;

  if (deviceid === '') {
    thing_name = siteId;
  }

  useEffect(() => {
    if (instance) {
      void init_ros(thing_name, instance, email);
    }
  }, [instance]);

  return (
    <div className={classes.root}>
      <Typography variant="h1" gutterBottom>
        Connecting to {getInfo(instance).name}...
      </Typography>
      <Spinner
        name="circle"
        style={{
          width: '20em',
          height: '20em',
        }}
      />
    </div>
  );
};

LoadingScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  instance: PropTypes.string.isRequired,
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

export default withStyles(styles)(LoadingScreen);

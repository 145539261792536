import React from 'react';
import PropTypes from 'prop-types';

const Barrier = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 44 44"
    >
      <g clipPath="url(#clip0_513_31185)">
        <g clipPath="url(#clip1_513_31185)">
          <path
            fill={props.color}
            d="M43.313 8.066H41.86a4.676 4.676 0 001.35-2.407 4.607 4.607 0 00-.254-2.734 4.728 4.728 0 00-1.771-2.13A4.89 4.89 0 0038.5-.008a4.89 4.89 0 00-2.686.801 4.728 4.728 0 00-1.771 2.13 4.607 4.607 0 00-.254 2.735 4.676 4.676 0 001.35 2.407H8.86a4.677 4.677 0 001.35-2.407 4.608 4.608 0 00-.254-2.734A4.729 4.729 0 008.186.795 4.89 4.89 0 005.5-.008a4.89 4.89 0 00-2.686.801 4.729 4.729 0 00-1.771 2.13A4.608 4.608 0 00.789 5.66a4.677 4.677 0 001.35 2.407H.688a.695.695 0 00-.487.197A.665.665 0 000 8.74v7.394c0 .178.072.35.201.475a.695.695 0 00.486.197h1.375v5.378H.688a.696.696 0 00-.487.197.665.665 0 00-.201.475v7.395c0 .178.072.349.201.475a.696.696 0 00.486.197h1.375v10.756c0 .356.145.698.403.95s.608.394.973.394h4.124c.365 0 .715-.142.973-.394a1.33 1.33 0 00.402-.95V30.922h26.126v10.756c0 .356.144.698.402.95s.608.394.972.394h4.126c.364 0 .714-.142.972-.394a1.33 1.33 0 00.403-.95V30.922h1.374c.183 0 .358-.07.487-.197A.665.665 0 0044 30.25v-7.395a.665.665 0 00-.201-.475.696.696 0 00-.486-.197h-1.376v-5.378h1.376c.182 0 .357-.07.486-.197a.665.665 0 00.201-.475V8.74a.665.665 0 00-.201-.476.695.695 0 00-.486-.197zm-8.25-3.36c0-.665.201-1.315.579-1.868A3.422 3.422 0 0137.184 1.6a3.51 3.51 0 011.987-.191c.666.13 1.279.45 1.76.92.48.47.808 1.069.94 1.72a3.291 3.291 0 01-.195 1.943c-.26.614-.7 1.139-1.266 1.508a3.493 3.493 0 01-4.34-.42 3.328 3.328 0 01-1.008-2.375zM37.37 9.41l2.812 6.05H34.82l-2.813-6.05h5.363zm-6.448 12.772H8.937v-5.378h26.126v5.378h-4.141zM23.62 9.411l2.812 6.05H21.07l-2.813-6.05h5.363zm-13.75 0l2.812 6.05H7.32L4.505 9.41H9.87zM2.063 4.705c0-.664.201-1.314.579-1.867A3.422 3.422 0 014.185 1.6a3.51 3.51 0 011.986-.191c.666.13 1.279.45 1.76.92.48.47.808 1.069.94 1.72a3.292 3.292 0 01-.195 1.943c-.26.614-.7 1.139-1.266 1.508a3.493 3.493 0 01-4.34-.42 3.328 3.328 0 01-1.007-2.375zm5.255 24.872l-2.813-6.05h5.363l2.812 6.05H7.318zm13.75 0l-2.813-6.05h5.363l2.812 6.05h-5.362zm13.75 0l-2.813-6.05h5.363l2.812 6.05h-5.362z"
          ></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_513_31185">
          <path fill="#fff" d="M0 0H44V44H0z"></path>
        </clipPath>
        <clipPath id="clip1_513_31185">
          <path fill="#fff" d="M0 0H44V44H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Barrier;

Barrier.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number]),
};

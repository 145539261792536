const get = (key) => {
  return localStorage.getItem(key);
};

const set = (key, val) => {
  localStorage.setItem(key, val);
};

const del = (key) => {
  localStorage.removeItem(key);
};

const clear = () => {
  localStorage.clear();
};

const storage = {
  get,
  set,
  del,
  clear,
};

export default storage;

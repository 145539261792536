import {Card, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {PureComponent, useState} from 'react';
import {DISPENSER_STATUS, EXISTING_FOOD} from '../enums';
import {IconComponent, toPascalCase} from '../util';

const TextTypography = withStyles({
  root: {
    paddingTop: '0.3rem',
    fontSize: '1.5rem',
    fontWeight: 600,
    textAlign: 'center',
  },
})(Typography);

const styles = (theme) => ({
  mainBody: {
    position: 'relative',
    maxHeight: '9.375rem',
    maxWidth: '95%',
    margin: '0 auto',
  },
  hopperPortionCard: {
    height: '20%',
    position: 'relative',
  },
  hopperBtns: {
    display: 'inline-block',
    marginRight: '2rem',
    textAlign: 'center',
  },
  hopperBtnsHasIssue: {
    position: 'absolute',
    top: '0.9375rem',
  },
  iconContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  cornerIcon: {
    position: 'absolute',
    left: '64%',
    bottom: '-44%',
    height: 'auto',
    zIndex: 1,
  },
});

class Dispenser extends PureComponent {
  stateBox(classes) {
    const {hopperState} = this.props;
    const isOffline = false;
    const isLowTemp = false;

    const dispenserState = (state) => {
      let icon = '';
      if (state === DISPENSER_STATUS.ERROR) {
        icon = 'Broken';
      }
      if (state === DISPENSER_STATUS.EMPTY) {
        icon = 'Empty';
      }
      return icon;
    };

    const isNotFull =
      hopperState &&
      hopperState.sides &&
      hopperState.sides[0].status !== DISPENSER_STATUS.FULL &&
      hopperState.sides[1].status !== DISPENSER_STATUS.FULL;

    return (
      <Card
        className={classes.hopperPortionCard}
        style={{
          border: isNotFull ? '0.25rem solid red' : '',
        }}
      >
        {hopperState && Object.entries(hopperState).length !== 0 && (
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              className={classes.hopperBtns}
              style={{opacity: isOffline || isLowTemp ? '0.5' : 1}}
            >
              <TextTypography>Hopper Left</TextTypography>
              <div className={classes.iconContainer}>
                <IconComponent
                  alt="left-food"
                  icon2={EXISTING_FOOD[hopperState.sides[0].food_type]}
                  width="3vw"
                  color="#000000"
                />
                {hopperState.sides[0].status !== 'full' && (
                  <div className={classes.cornerIcon}>
                    <IconComponent
                      alt="corner-icon"
                      icon2={dispenserState(hopperState.sides[0].status)} // Ensure CORNER_ICON is defined or imported correctly
                      width="2.5vw" // Adjust size as needed
                      color="#000000"
                    />
                  </div>
                )}
              </div>
              <TextTypography>
                {toPascalCase(hopperState.sides[0].food_type)}
              </TextTypography>
            </div>
            <div
              className={classes.hopperBtns}
              style={{opacity: isOffline || isLowTemp ? '0.5' : 1}}
            >
              <TextTypography>Hopper Right</TextTypography>
              <div className={classes.iconContainer}>
                <IconComponent
                  alt="left-food"
                  icon2={EXISTING_FOOD[hopperState.sides[1].food_type]}
                  width="3vw"
                  color="#000000"
                />
                {hopperState.sides[1].status !== 'full' && (
                  <div className={classes.cornerIcon}>
                    <IconComponent
                      alt="corner-icon"
                      icon2={dispenserState(hopperState.sides[1].status)} // Ensure CORNER_ICON is defined or imported correctly
                      width="2.5vw" // Adjust size as needed
                      color="#000000"
                    />
                  </div>
                )}
              </div>

              <TextTypography>
                {toPascalCase(hopperState.sides[1].food_type)}
              </TextTypography>
            </div>
            {(isOffline || isLowTemp) && (
              <div className={classes.hopperBtnsHasIssue}>
                <IconComponent
                  icon2={isOffline ? 'Broken' : 'TemperatureWarning'}
                  width={isOffline ? '8vw' : '5vw'}
                  color="#ef9c8e"
                />
              </div>
            )}
          </div>
        )}
      </Card>
    );
  }

  render() {
    const {classes} = this.props;
    return <div className={classes.mainBody}>{this.stateBox(classes)}</div>;
  }
}

export default withStyles(styles)(Dispenser);

import {withStyles} from '@material-ui/core';

const styles = withStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '41.25rem',
    minHeight: '12.5rem',
    padding: '0 1.25rem',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '2.2rem',
  },
  title: {
    fontSize: '1.65rem',
    fontWeight: 'bold',
    color: '#838383',
    padding: 0,
    margin: 0,
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flex: 1.25,
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentText: {
    fontSize: '1.3rem',
    color: '#B74C3E',
    fontWeight: 400,
    padding: 0,
    margin: 0,
    letterSpacing: 0,
    textAlign: 'center',
  },
  subtext: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    fontStyle: 'italic',
    textAlign: 'center',
    color: '#838383',
    padding: '0.5rem',
    margin: 0,
  },
  bottomPane: {
    display: 'flex',
    flex: 0.8,
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.625rem',
    paddingTop: '0.75rem',
    paddingBottom: '2rem',
  },
  leftRightBtn: {
    width: '12.5rem',
    height: '50%',
    border: '0.0625rem solid #7E7D7E',
  },
}));

export default styles;

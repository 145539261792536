import React from 'react';
import ReactGA from 'react-ga';
import {withRouter} from 'react-router-dom';

class PageTracker extends React.Component {
  trackPage = (page) => {
    ReactGA.set({page});
    ReactGA.pageview(page);
  };

  componentDidMount() {
    const page = this.props.location.pathname;
    this.trackPage(page);

    let activeInstances = Object.keys(this.props.instances);
    if (page !== '/' && activeInstances.length === 0) {
      window.location.href = '/';
    }
  }

  componentWillReceiveProps(nextProps) {
    const page = this.props.location.pathname;
    let activeInstances = Object.keys(this.props.instances);

    if (page === '/' && activeInstances.length > 0) {
      activeInstances.forEach(function (item, index) {
        activeInstances.shift();
      });
    }

    const currentPage = this.props.location.pathname;
    const nextPage = nextProps.location.pathname;

    if (currentPage !== nextPage) {
      this.trackPage(nextPage);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(PageTracker);

import React from 'react';

const Basket = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8601 31.8721L14.4481 33.6361C14.555 33.9208 14.75 34.164 15.0047 34.3302C15.2594 34.4964 15.5605 34.5769 15.8641 34.5601H18.3721L17.6401 31.8481L13.8601 31.8721ZM19.0921 31.7881L19.8241 34.5001L24.1801 34.4161L23.6161 31.7041L19.0921 31.7881ZM12.5761 28.0081L13.4401 30.6001H17.2921L16.5961 27.9961L12.5761 28.0081ZM25.0561 31.7041L25.6201 34.4161H29.3401V31.6921L25.0561 31.7041ZM18.0481 27.9361L18.7561 30.5401L23.3521 30.4561L22.8121 27.8521L18.0481 27.9361ZM11.3281 24.3361L12.1561 26.8081L16.2481 26.7361L15.5881 24.2641L11.3281 24.3361ZM30.7081 31.6561V34.3801H32.8921C33.0702 34.3865 33.2478 34.3577 33.4148 34.2955C33.5818 34.2332 33.7349 34.1386 33.8653 34.0171C33.9957 33.8956 34.1008 33.7496 34.1747 33.5875C34.2487 33.4253 34.2899 33.2502 34.2961 33.0721V31.6321L30.7081 31.6561ZM24.2521 27.8761L24.7921 30.4801L29.2561 30.4081L29.1961 27.7921L24.2521 27.8761ZM17.0521 24.2761L17.7241 26.7481L22.5241 26.6641L22.0081 24.1801L17.0521 24.2761ZM30.6481 27.8041V30.4081H34.2481L34.1881 27.7921L30.6481 27.8041ZM23.4481 24.2041L23.9641 26.6881L29.1481 26.5921V24.1081L23.4481 24.2041ZM30.5281 24.0721V26.5681L34.1281 26.4961V24.0121L30.5281 24.0721Z"
        fill="#525252"
      />
      <path
        d="M34.9414 24.9119V18.5879C34.9537 17.9329 35.2194 17.3082 35.6826 16.845C36.1458 16.3818 36.7705 16.1162 37.4254 16.1039H40.3294C40.6477 16.1039 40.9529 16.2303 41.1779 16.4554C41.403 16.6804 41.5294 16.9856 41.5294 17.3039C41.5294 17.6221 41.403 17.9274 41.1779 18.1524C40.9529 18.3775 40.6477 18.5039 40.3294 18.5039H37.4254C37.407 18.5038 37.3888 18.5076 37.372 18.5149C37.3551 18.5223 37.34 18.5331 37.3276 18.5467C37.3151 18.5602 37.3057 18.5762 37.2998 18.5937C37.2939 18.6111 37.2917 18.6296 37.2934 18.6479V22.5719C37.2781 23.1897 37.025 23.7778 36.5868 24.2137C36.1487 24.6496 35.5593 24.8997 34.9414 24.9119Z"
        fill="#525252"
      />
      <path
        d="M9.625 22.356C9.625 22.0377 9.75143 21.7325 9.97647 21.5075C10.2015 21.2824 10.5067 21.156 10.825 21.156L34.129 20.724V23.124L10.825 23.556C10.5067 23.556 10.2015 23.4296 9.97647 23.2045C9.75143 22.9795 9.625 22.6743 9.625 22.356Z"
        fill="#525252"
      />
    </svg>
  );
};

export default Basket;

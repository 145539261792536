import React, {PureComponent} from 'react';
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

export class SlotStatusReason extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      reason: '',
    };
    this.submitBtn = this.submitBtn.bind(this);
  }

  reasonPreSelect = (reason) => {
    this.setState({reason: reason});
  };

  submitBtn() {
    const {reason} = this.state;

    this.props.handleModalVisibility(false);
    this.props.handleSlotToggle(reason);
  }

  render() {
    const {
      slotObj,
      classes,
      handleModalVisibility,
      enableReasons,
      disableReasons,
      inSingleStepModal,
    } = this.props;

    const disabled = !slotObj.disabled;
    const slotPath = slotObj.slot_path;
    const slotNo = slotPath.substring(slotPath.lastIndexOf('/') + 1);
    const interfacePath = slotPath.split('/').slice(-2, -1)[0];
    const newInterfacePath =
      interfacePath === 'interface'
        ? 'Autobasket'
        : interfacePath.charAt(0).toUpperCase() + interfacePath.slice(1);
    const reasons = disabled ? disableReasons : enableReasons;
    const lastIndex = reasons.length - 1;

    return (
      <Paper
        className={
          inSingleStepModal ? classes.paperSingleStepModal : classes.paper
        }
      >
        <div className={classes.header}>
          {!inSingleStepModal && (
            <Button
              className={classes.backBtn}
              onClick={() => handleModalVisibility(false)}
            >
              <svg
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 12C-1.79184e-08 11.5901 0.167765 11.198 0.464286 10.915L11.4643 0.41497C12.0635 -0.15704 13.013 -0.134959 13.585 0.46429C14.157 1.06354 14.135 2.01303 13.5357 2.58504L3.67242 12L13.5357 21.415C14.135 21.987 14.157 22.9365 13.585 23.5357C13.013 24.135 12.0635 24.157 11.4643 23.585L0.464286 13.085C0.167765 12.802 1.79184e-08 12.4099 0 12Z"
                  fill="black"
                />
              </svg>
            </Button>
          )}
          <Typography
            variant="h6"
            color="inherit"
            className={classes.headerTitle}
          >
            {`${disabled ? 'Disable' : 'Enable'} ${newInterfacePath} ${slotNo}`}
          </Typography>
          {inSingleStepModal && (
            <Button
              className={classes.closeBtn}
              onClick={() => handleModalVisibility(false)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.00621 12L0.626423 3.62021C0.22872 3.22321 0.00501251 2.68448 0.0045166 2.12254C0.00402069 1.5606 0.226774 1.02148 0.623775 0.623777C1.02078 0.226074 1.55951 0.00236781 2.12145 0.00187155C2.68339 0.00137528 3.22251 0.224129 3.62021 0.621131L12 9.00092L20.3798 0.621131C20.7775 0.223427 21.3169 0 21.8793 0C22.4418 0 22.9812 0.223427 23.3789 0.621131C23.7766 1.01883 24 1.55823 24 2.12067C24 2.68311 23.7766 3.22251 23.3789 3.62021L14.9991 12L23.3789 20.3798C23.7766 20.7775 24 21.3169 24 21.8793C24 22.4418 23.7766 22.9812 23.3789 23.3789C22.9812 23.7766 22.4418 24 21.8793 24C21.3169 24 20.7775 23.7766 20.3798 23.3789L12 14.9991L3.62021 23.3789C3.22251 23.7766 2.68311 24 2.12067 24C1.55823 24 1.01883 23.7766 0.62113 23.3789C0.223427 22.9812 0 22.4418 0 21.8793C0 21.3169 0.223427 20.7775 0.62113 20.3798L9.00621 12Z"
                  fill="#000000"
                />
              </svg>
            </Button>
          )}
        </div>
        {!slotObj.disabled && (
          <Typography className={classes.warning}>
            Flippy will STOP cooking food in the disabled basket
          </Typography>
        )}
        <Typography
          className={
            inSingleStepModal
              ? classes.reasonTitleSingleStepModal
              : classes.reasonTitle
          }
        >
          {`Select Reason for ${disabled ? 'Disabling' : 'Enabling'}`}
        </Typography>
        <List className={classes.list}>
          {reasons.map((reason, index) => (
            <div
              className={
                inSingleStepModal
                  ? classes.listItemContainerSingleStepModal
                  : classes.listItemContainer
              }
              key={`${reason}-${index}`}
            >
              <ListItem
                key={index}
                button
                onClick={() => this.reasonPreSelect(reason)}
                selected={this.state.reason === reason}
                disableGutters
                className={
                  inSingleStepModal
                    ? classes.listItemSingleStepModal
                    : classes.listItem
                }
                style={
                  this.state.reason === reason
                    ? {backgroundColor: '#9BCEFD', fontWeight: '900'}
                    : {}
                }
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={reason}
                />
              </ListItem>
            </div>
          ))}
        </List>
        <div className={classes.submitBtnWrap}>
          <Button
            onClick={(e) => this.submitBtn(e)}
            variant="contained"
            disabled={!reasons.includes(this.state.reason)}
            className={classes.submitBtn}
          >
            {`${disabled ? 'Disable' : 'Enable'} Basket`}
          </Button>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(SlotStatusReason);

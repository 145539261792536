import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import ReactGA from 'react-ga';
import {withRouter} from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import DraggableDialog from '../components/DraggableDialog.jsx';
import MisoDialogTitle from '../components/MisoDialogTitle.jsx';

class ElevatorConfirmation extends PureComponent {
  constructor() {
    super();
    this.initialState = {
      resetting: false,
      resetSuccess: false,
    };
    this.state = this.initialState;
  }

  componentDidMount() {
    const page = this.props.location.pathname;
    ReactGA.modalview(page + '/elevator-confirmation');
  }

  render() {
    const {classes, open, onClose, jogging, jogRobot, openElevatorModal} =
      this.props;
    var window_title = '';
    var window_query = '';

    if (!jogging) {
      window_title = 'Jog Robot';
      window_query = 'Are you sure you want to abort action and jog the robot?';
    } else {
      window_title = 'Unjog Robot';
      window_query = 'Are you sure you want to unjog the robot?';
    }

    return (
      <div>
        <DraggableDialog
          open={open}
          onClose={onClose}
          aria-labelledby="jog-confirmation-dialog"
          fullWidth={true}
        >
          <MisoDialogTitle id="jog-confirmation-dialog" onClose={onClose}>
            {window_title}
          </MisoDialogTitle>
          <p
            style={{
              color: 'red',
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '1.5rem',
            }}
          >
            {window_query}
          </p>
          <div className={classes.buttonContainer}>
            <Button
              onClick={() => {
                jogRobot();
                onClose();
                openElevatorModal();
              }}
            >
              <Typography variant="h4" color="inherit">
                Yes
              </Typography>
            </Button>
            <Button
              onClick={() => {
                jogging ? openElevatorModal() : onClose();
              }}
              style={{maxWidth: '60%'}}
            >
              <Typography variant="h4" color="inherit">
                {jogging ? 'No - Continue to Elevator Modal' : 'No'}
              </Typography>
            </Button>
          </div>
        </DraggableDialog>
      </div>
    );
  }
}

ElevatorConfirmation.propTypes = {
  classes: PropTypes.object.isRequired,
  jogging: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  jogRobot: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  location: PropTypes.object.isRequired,
};

const styles = {
  buttonContainer: {
    float: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
};

export default withStyles(styles)(withRouter(ElevatorConfirmation));

import React from 'react';
import PropTypes from 'prop-types';

const BasketSwap = (props) => {
  return (
    <svg
      version="1.0"
      width={props.width}
      height={props.height}
      viewBox="0 0 315.000000 279.000000"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
    >
      <title> BasketSwap </title>
      <g transform="scale(0.5)" fill={props.color} stroke="none">
        <path d="m323.683 60.235c-1.619-1.531-2.968-2.828-3.794-3.654-32.053-32.053-82.198-34.989-117.565-8.807-3.566 2.64-4.587 3.763-4.587 3.763-3.164 2.799-8.033 2.639-11.001-.33l-10.548-10.548c-3.013-3.013-2.882-7.679.193-10.523 0 0 .568-.674 3.799-3.297 47.314-38.409 116.966-35.593 161.006 8.446.92.92 2.873 2.778 5.167 4.927v-25.131c0-4.255 3.403-7.551 7.6-7.551h14.917c4.262 0 7.6 3.363 7.6 7.511v60.254c0 8.317-6.742 15.059-15.059 15.059h-60.254c-4.151 0-7.511-3.403-7.511-7.6v-14.919c0-4.262 3.381-7.6 7.551-7.6zm-135.366 391.53c1.619 1.531 2.968 2.828 3.794 3.654 32.795 32.795 84.528 35.109 119.996 6.943 2.693-2.139 2.192-1.847 2.192-1.847 3.152-2.832 7.996-2.69 10.965.278l10.548 10.548c3.013 3.013 2.924 7.666-.099 10.494 0 0 .742-.576-1.725 1.523-47.332 40.287-118.456 38.073-163.173-6.644-.92-.921-2.873-2.778-5.167-4.927v25.131c0 4.255-3.403 7.551-7.6 7.551h-14.918c-4.262 0-7.6-3.363-7.6-7.511v-60.254c0-8.317 6.742-15.059 15.059-15.059h60.254c4.151 0 7.511 3.403 7.511 7.6v14.917c0 4.262-3.381 7.6-7.551 7.6h-22.486zm233.33-97.883h-105.412c-8.317 0-15.059-6.742-15.059-15.059v-165.647c0-8.317 6.742-15.059 15.059-15.059h105.412c8.317 0 15.059 6.742 15.059 15.059v165.647c0 8.317-6.742 15.059-15.059 15.059zm-225.882 0h-105.412c-8.317 0-15.059-6.742-15.059-15.059v-165.647c0-8.317 6.742-15.059 15.059-15.059h105.412c8.317 0 15.059 6.742 15.059 15.059v165.647c0 8.317-6.743 15.059-15.059 15.059z" />
      </g>
    </svg>
  );
};

export default BasketSwap;

BasketSwap.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number]),
};

import {setAuthenticated} from '../../actions/appAction';
import {GOOGLE_AUTH_TOKEN} from '../../constants/strings';

import storage from '../../storage';

const resolve = () => (axiosConf) => {
  const {data} = axiosConf;
  return data;
};

const reject = (store) => (axiosConf) => {
  const {response} = axiosConf;

  if (!response) {
    return Promise.reject('Something went wrong');
  }

  const {data} = response;

  if (data.error.includes('invalid_token')) {
    storage.del(GOOGLE_AUTH_TOKEN);
    store.dispatch(setAuthenticated(false));
  }

  return Promise.reject(data);
};

const response = {resolve, reject};

export default response;

import {withStyles, Card, Button} from '@material-ui/core';
import React from 'react';

const styles = (theme) => ({
  mainDiv: {
    width: '20rem',
    display: 'flex',
    alignItems: 'center',
    padding: '1.2rem',
    justifyContent: 'space-between',
    marginLeft: '0.5rem',
    border: 'solid',
    borderSize: '0.1875rem',
    borderColor: '#f46c8f',
    backgroundColor: '#f9d4de',
  },
  btnDisable: {
    paddingLeft: '1rem',
  },
  mainText: {
    fontSize: '0.85rem',
  },
});

class ModeToggle extends React.Component {
  render() {
    const {
      classes,
      name,
      icon,
      status,
      troubleshootRequester,
      openJogConfirmation,
      openTroubleshootConfirmation,
    } = this.props;

    return (
      <Card className={classes.mainDiv}>
        <div
          className={classes.mainText}
          style={{display: 'flex', alignItems: 'center'}}
        >
          {icon} {/* Assuming the icon is JSX */}
          <span style={{marginLeft: '0.5rem'}}>
            {name} {name === 'Jogging' ? 'Mode: ' : ''}{' '}
            <b>{status && name === 'Jogging' && 'Enabled'}</b>
            <br />
            <b>{troubleshootRequester}</b>
          </span>
        </div>
        <div className={classes.btnDisable}>
          <Button
            variant="outlined"
            onClick={() => {
              if (name === 'Jogging') {
                openJogConfirmation();
              } else {
                openTroubleshootConfirmation();
              }
            }}
          >
            {name === 'Jogging' ? 'Disable' : 'Verify With Customer'}
          </Button>
        </div>
      </Card>
    );
  }
}

export default withStyles(styles)(ModeToggle);

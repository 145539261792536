import {
  SHOW_SUPPORT_MODAL,
  HIDE_SUPPORT_MODAL,
  SHOW_LOADER,
  HIDE_LOADER,
  SHOW_ADMIN_MODAL,
  HIDE_ADMIN_MODAL,
  SHOW_CHANGE_COOKING_TIME,
  HIDE_CHANGE_COOKING_TIME,
  SHOW_L2ALERT_MODAL,
  HIDE_L2ALERT_MODAL,
  SET_ERROR_SNACKBAR,
  SET_IDLE_UI,
  SHOW_JOG_MODAL,
  HIDE_JOG_MODAL,
  SHOW_RESOLVE_COLLISION_MODAL,
  HIDE_RESOLVE_COLLISION_MODAL,
  TOGGLE_LOADING_POWER_STATE,
} from '../actions/uiActions';

const initialState = {
  loader: false,
  supportModal: false,
  adminModal: false,
  changeCookingTimeModal: false,
  l2AlertModal: false,
  error: null,
  idle: null,
  jogModal: false,
  resolveCollisionModal: false,
  version: '',
  loadingPowerState: {
    status: false,
    text: '',
  },
};

export const ui = (state = initialState, {type, payload}) => {
  switch (type) {
    case SHOW_LOADER:
      return {...state, loader: true};
    case HIDE_LOADER:
      return {...state, loader: false};
    case SHOW_SUPPORT_MODAL:
      return {...state, supportModal: true};
    case HIDE_SUPPORT_MODAL:
      return {...state, supportModal: false};
    case SHOW_ADMIN_MODAL:
      return {...state, adminModal: true};
    case HIDE_ADMIN_MODAL:
      return {...state, adminModal: false};
    case SHOW_CHANGE_COOKING_TIME:
      return {...state, changeCookingTimeModal: true};
    case HIDE_CHANGE_COOKING_TIME:
      return {...state, changeCookingTimeModal: false};
    case SHOW_L2ALERT_MODAL:
      return {...state, l2AlertModal: true};
    case HIDE_L2ALERT_MODAL:
      return {...state, l2AlertModal: false};
    case SET_ERROR_SNACKBAR:
      return {...state, error: payload};
    case SET_IDLE_UI:
      return {...state, idle: payload};
    case SHOW_JOG_MODAL:
      return {...state, jogModal: true};
    case HIDE_JOG_MODAL:
      return {...state, jogModal: false};
    case SHOW_RESOLVE_COLLISION_MODAL:
      return {...state, resolveCollisionModal: true};
    case HIDE_RESOLVE_COLLISION_MODAL:
      return {...state, resolveCollisionModal: false};
    case TOGGLE_LOADING_POWER_STATE:
      return {...state, loadingPowerState: payload};
    default:
      return state;
  }
};

import React, {PureComponent} from 'react';
import {Card, CardContent, Dialog, Switch, Typography} from '@material-ui/core';
import MisoDialogTitle from '../components/MisoDialogTitle';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {disableSlot, mqtt_ap} from '../ros_init';
import {DISABLING_LEVEL} from '../enums';

import SlotStatusReason from '../modals/SlotStatusReason';
import moment from 'moment';

const styles = {
  dialogTitleWrap: {
    padding: '2rem',
    marginBottom: '0.25rem',
  },
  dialogTitle: {
    padding: 0,
    fontSize: '1.75rem',
    lineHeight: '2rem',
    fontWeight: '700',
  },
  basketsListWrap: {
    padding: '0 2rem',
  },
  basketsList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    overflow: 'auto',
    padding: '1rem',
    backgroundColor: '#E7E9EC',
    borderRadius: '1.5rem',
  },
  card: {
    width: '16rem',
    height: '6rem',
    marginBottom: '0.75rem',
    borderRadius: '0.75rem',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 0,
  },
  cardTitleWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardTitle: {
    fontSize: '1.25rem',
    fontWeight: '700',
    lineHeight: '1.5rem',
    color: '#5C5C5C',
  },
  switch: {},
  disableReason: {
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: '1.125rem',
    textAlign: 'center',
    color: '#D9D9D9',
  },
  disableTime: {
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: '1.125rem',
    textAlign: 'center',
    color: '#D9D9D9',
  },
};

// Reasons for disabling / enabling slots
const disableReasons = ['Broken', 'Other'];

const enableReasons = ['Repaired', 'Replaced', 'Other'];

class BasketModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSlotReasonModalOpen: false,
      selectedBasketNo: null,
      selectedReason: '',
      selectedBasket: {},
    };
    this.handleToggleReasonSelection =
      this.handleToggleReasonSelection.bind(this);
    this.handleSlotToggle = this.handleSlotToggle.bind(this);
  }

  onComponentDidMount = () => {
    this.setState(this.initialState);
  };

  handleToggleReasonSelection(value, desiredBasket) {
    this.setState({
      isSlotReasonModalOpen: true,
      selectedBasketNo: value,
      selectedReason: '',
      selectedBasket: desiredBasket,
    });
  }

  async modifySlotHelper(service, verb, is_disabled, slotPath, reason) {
    const {instance, user} = this.props;
    service[instance].setData = {
      user: {permissions: DISABLING_LEVEL.SUPPORT, name: user},
      component_path: slotPath,
      disabled: is_disabled,
      reason: reason,
    };
    mqtt_ap.submit_request(service[instance]).catch((error) => {
      console.log('Submit_Request error: ' + error);
    });
  }

  getEnableSlotFunc = (reason) => {
    const {selectedBasket} = this.state;
    const slotPath = selectedBasket.slot_path;
    return this.modifySlotHelper.bind(
      this,
      disableSlot,
      'Enabled',
      false,
      slotPath,
      reason
    );
  };
  getDisableSlotFunc = (reason) => {
    const {selectedBasket} = this.state;
    const slotPath = selectedBasket.slot_path;
    return this.modifySlotHelper.bind(
      this,
      disableSlot,
      'Disabled',
      true,
      slotPath,
      reason
    );
  };

  handleSlotToggle(reason) {
    const {selectedBasket} = this.state;

    const func = selectedBasket.disabled
      ? this.getEnableSlotFunc(reason)
      : this.getDisableSlotFunc(reason);
    func();
  }

  convertDateString(dateStr) {
    return moment(dateStr, 'MM/DD hh:mm A Z').format('M/D/YY HH:mm');
  }

  render() {
    const {classes, open, onClose, basketList, baskets, rosHeaderTimeStamp} =
      this.props;
    const newBasketList = Object.values(basketList).flat();

    return (
      <div>
        {baskets.length > 0 && (
          <Dialog
            open={open}
            onClose={() => {
              onClose();
              this.handleToggleReasonSelection();
            }}
            PaperProps={{
              style: {
                width: '40.625rem',
                height: '90%',
                maxWidth: 'initial',
              },
            }}
          >
            <div className={classes.dialogTitleWrap}>
              <MisoDialogTitle
                classes={{root: classes.dialogTitle}}
                onClose={onClose}
                disableTypography
              >
                Baskets
              </MisoDialogTitle>
            </div>
            {!this.state.selectedBasket ||
            !this.state.selectedBasket.basket_id ? (
              <div className={classes.basketsListWrap}>
                <div className={classes.basketsList}>
                  {newBasketList.map((basketId, index) => {
                    const desiredBasket = baskets.find(
                      (basket) => basket.basket_id === basketId
                    );
                    return (
                      <Card className={classes.card}>
                        <CardContent className={classes.cardContent}>
                          <div className={classes.cardTitleWrap}>
                            <Typography className={classes.cardTitle}>
                              Basket {basketId}
                            </Typography>
                            <Switch
                              checked={!desiredBasket.disabled}
                              color="primary"
                              className={classes.switch}
                              size="small"
                              onClick={() =>
                                this.handleToggleReasonSelection(
                                  basketId,
                                  desiredBasket
                                )
                              }
                            />
                          </div>
                          {desiredBasket.disabled && (
                            <>
                              <Typography className={classes.disableReason}>
                                Disabled - {desiredBasket.disable_reason}
                              </Typography>
                              <Typography className={classes.disableTime}>
                                {moment(desiredBasket.disable_time).format(
                                  'M/D/YY HH:mm'
                                )}
                              </Typography>
                            </>
                          )}
                        </CardContent>
                      </Card>
                    );
                  })}
                </div>
              </div>
            ) : (
              <SlotStatusReason
                slotObj={this.state.selectedBasket}
                handleModalVisibility={this.handleToggleReasonSelection}
                handleSlotToggle={this.handleSlotToggle}
                enableReasons={enableReasons}
                disableReasons={disableReasons}
                inSingleStepModal={false}
              />
            )}
          </Dialog>
        )}
      </div>
    );
  }
}

BasketModal.propTypes = {
  classes: PropTypes.object.isRequired,
  toggle: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(BasketModal);

import {response, request} from './interceptor';
import serverConfig, {server} from './server';
import {getAuthorized} from '../actions/appAction';
import {useRedux} from '../hooks';

import googleServer, {googleService, googleSignIn} from './google';

/**
 * @param {import('redux').Store} store
 */
const apiConfig = (store) => {
  serverConfig.interceptors.request.use(
    request.resolve(store),
    request.reject(store)
  );
  googleServer.interceptors.response.use(
    response.resolve(store),
    response.reject(store)
  );

  googleSignIn(async () => {
    const [, dispatch] = useRedux((state) => state.appState);
    dispatch(getAuthorized());
  });
};

export {apiConfig, server, googleServer, googleService};

import React from 'react';
import PropTypes from 'prop-types';

const PumpkinCroissantBites = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 80 80"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Pumpkin Croissant Bites</title>
      <g
        id="food-icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="icons/pumkin-croissant-bites"
          fill={props.color}
          fillRule="nonzero"
        >
          <g>
            <path d="M77.261 37.825a2 2 0 011.984 2.255l-4.384 38.174A2 2 0 0172.878 80H7.122a2 2 0 01-1.983-1.746L.755 40.08a2 2 0 011.984-2.255zM44.174 44a5.223 5.223 0 00-5.217 5.217v.095a7.261 7.261 0 00-2.745 1.115c-3.135 2.09-5.082 6.36-5.082 11.143a14.442 14.442 0 003.367 9.669A7.742 7.742 0 0040.261 74a7.74 7.74 0 005.764-2.762 14.44 14.44 0 003.366-9.668c0-4.782-1.946-9.05-5.078-11.14a7.265 7.265 0 00-2.748-1.117v-.096a2.612 2.612 0 012.609-2.608 1.304 1.304 0 100-2.609zm-12.391 3.913c-5.395 0-9.783 5.576-9.783 12.43 0 6.848 4.388 12.418 9.783 12.418.45-.004.899-.048 1.341-.13l-.074-.075a16.408 16.408 0 01-3.876-10.986c0-5.43 2.28-10.323 5.952-12.77.032-.022.067-.036.1-.057a7.985 7.985 0 00-3.443-.83zm16.956 0a7.985 7.985 0 00-3.443.83l.11.063c3.666 2.45 5.942 7.34 5.942 12.764a16.41 16.41 0 01-3.876 10.986c-.024.026-.05.048-.074.074.442.083.891.126 1.341.13 5.395 0 9.783-5.57 9.783-12.417 0-6.854-4.388-12.43-9.783-12.43zm-22.285-26.19c.094-.104.17-.224.27-.324l7.389-7.459a8.27 8.27 0 018.984-1.859l5.597-5.644-4.827-4.873a5.564 5.564 0 00-7.734 0L21.306 16.53l5.148 5.192zm19.433-7.777l7.684 7.757 7.685-7.757a8.271 8.271 0 0111.774 0l.899.907a5.499 5.499 0 00-1.245-5.83l-7.388-7.46a5.564 5.564 0 00-7.735 0l-11.99 12.098c.11.096.217.184.316.285zm-9.754 2.039l-7.389 7.458c-.1.101-.177.218-.268.326l6.228 6.288A8.388 8.388 0 0137.143 36h24.286a5.504 5.504 0 00-1.602-3.904l-15.96-16.111a5.564 5.564 0 00-7.734 0zm27.143 0l-7.684 7.757 6.255 6.315A8.388 8.388 0 0164.286 36h9.408l4.705-4.749a5.56 5.56 0 000-7.808l-7.389-7.458a5.564 5.564 0 00-7.734 0zM6.97 13.945a8.271 8.271 0 0111.774 0l.542.547 7.98-8.055-4.827-4.873a5.564 5.564 0 00-7.735 0L7.316 9.022a5.498 5.498 0 00-1.245 5.83l.899-.906zM6.306 36h27.98a5.504 5.504 0 00-1.602-3.904l-15.96-16.111a5.564 5.564 0 00-7.734 0L1.6 23.443a5.56 5.56 0 000 7.808L6.306 36z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PumpkinCroissantBites;

PumpkinCroissantBites.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

const TestBehavior = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 315.000000 279.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <title>Test Behavior</title>
      <g
        transform="translate(0.000000,279.000000) scale(0.100000,-0.100000)"
        fill={props.color}
        stroke="none"
      >
        <path
          d="M474 2419 c-18 -20 -19 -52 -19 -1019 0 -967 1 -999 19 -1019 l19
            -21 1099 0 1098 0 20 32 c20 32 20 54 20 1008 0 954 0 976 -20 1008 l-20 32
            -1098 0 -1099 0 -19 -21z m2108 -209 l3 -90 -992 0 -993 0 0 90 0 90 989 0
            990 0 3 -90z m-2 -970 l0 -740 -990 0 -990 0 0 740 0 740 990 0 990 0 0 -740z"
        />
        <path
          d="M1146 1709 c-90 -105 -143 -159 -152 -155 -8 3 -57 40 -109 82 -53
            42 -97 75 -98 73 -2 -2 -22 -27 -45 -56 l-43 -52 118 -93 c65 -51 137 -108
            161 -127 l43 -33 37 43 c20 24 103 120 184 213 165 191 159 172 77 237 l-36
            28 -137 -160z"
        />
        <path
          d="M1545 1746 c-24 -24 -25 -30 -25 -160 0 -195 -50 -177 491 -174 413
            3 438 4 458 22 19 18 21 30 21 151 0 116 -2 136 -19 156 l-19 24 -441 3 -441
            3 -25 -25z m805 -151 l0 -35 -345 0 -345 0 0 30 0 30 343 3 c188 1 343 3 345
            5 1 1 2 -14 2 -33z"
        />
        <path
          d="M815 1170 l-49 -50 94 -95 95 -95 -80 -82 c-44 -45 -88 -88 -98 -96
            -18 -14 -16 -18 34 -68 l53 -54 98 97 98 98 98 -98 98 -97 49 50 50 50 -98 98
            -97 97 97 97 97 98 -49 50 -49 50 -98 -97 -98 -98 -98 98 -98 97 -49 -50z"
        />
        <path
          d="M1564 1096 c-17 -8 -34 -21 -38 -31 -3 -9 -6 -74 -6 -144 0 -189 -51
            -171 486 -171 l441 0 21 23 c20 21 22 33 22 153 -1 139 -6 155 -54 174 -44 16
            -833 13 -872 -4z m786 -171 l0 -35 -345 0 -345 0 0 35 0 35 345 0 345 0 0 -35z"
        />
      </g>
    </svg>
  );
};

export default TestBehavior;

TestBehavior.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number]),
};

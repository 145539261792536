import {colors} from '../../styles';

const styles = () => ({
  tableContainer: {
    height: '100%',
  },
  table: {
    width: '100%',
    height: '100%',
    borderCollapse: 'collapse',
  },
  tableName: {
    fontSize: '0.85em',
    padding: '0.75em',
    color: '#000000',
    textAlign: 'left',
    backgroundColor: colors.lightGray,
  },
  tableHeader: {
    padding: '1em',
    color: '#000000',
  },
  tableData: {
    padding: '0.5em 0.5em',
    color: '#000000',
  },
});

export default styles;

import React from 'react';
import PropTypes from 'prop-types';

const OffsetTuningIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      version="1"
      viewBox="0 0 313 311"
    >
      <path
        d="M370 2470v-270h130v400l113 1c61 1 151 1 200 1l87-1v139H370v-270z"
        transform="matrix(.1 0 0 -.1 0 311)"
      ></path>
      <path
        d="M1990 2670v-71l173 1c94 1 184 1 200 1l27-1v-210H670V710H500v410H370V580h300V370h2020v2020h-170v-170h-130v170l33 1c17 0 46 2 62 2l30 2 3 173 2 172h-530v-70zm530-1820V580h-530v130h400v410h130V850zM840 650v-60H670v120h170v-60z"
        transform="matrix(.1 0 0 -.1 0 311)"
      ></path>
    </svg>
  );
};

export default OffsetTuningIcon;

OffsetTuningIcon.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string.isRequired,
};

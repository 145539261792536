import {googleService} from '../api';
import {GOOGLE_AUTH_TOKEN} from '../constants/strings';
import storage from '../storage';
import {
  SET_AUTHENTICATED,
  SET_AUTH_USER,
  CLEAR_AUTH_USER,
  SET_AUTH_LOADING,
} from './actionTypes';

export function getAuthorized() {
  return async function (dispatch) {
    try {
      dispatch(setAuthLoading(true));
      const res = await googleService.get('/tokeninfo', {
        id_token: storage.get(GOOGLE_AUTH_TOKEN),
      });
      dispatch(setAuthenticated(true));
      dispatch(setAuthUser(res));
    } catch (err) {
      console.log('GET-AUTHORIZED-REJECT', err);
    } finally {
      dispatch(setAuthLoading(false));
    }
  };
}

export const setAuthenticated = (payload) => ({
  type: SET_AUTHENTICATED,
  payload,
});

export const clearAuthhUser = () => ({
  type: CLEAR_AUTH_USER,
});

export const setAuthUser = (payload) => ({
  type: SET_AUTH_USER,
  payload,
});

export const setAuthLoading = (payload) => ({
  type: SET_AUTH_LOADING,
  payload,
});

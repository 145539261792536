const styles = (theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 2rem',
    padding: '1rem',
    backgroundColor: '#E7E9EC',
    borderRadius: '1.5rem',
  },
  paperSingleStepModal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    padding: '0 3rem',
    backgroundColor: '#E7E9EC',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.5rem',
  },
  backBtn: {
    height: '2.5rem',
    width: '2.5rem',
    minWidth: 'auto',
    marginRight: '2rem',
  },
  closeBtn: {
    height: '2.5rem',
    width: '2.5rem',
    minWidth: 'auto',
    marginLeft: 'auto',
  },
  warning: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '3.5rem',
    marginBottom: '1.5rem',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    fontWeight: 700,
    color: '#FC648F',
    border: '0.1875rem solid #FC648F',
    borderRadius: '0.75rem',
    background:
      'linear-gradient(0deg, #FCD2DE, #FCD2DE), ' +
      'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));',
  },
  headerTitle: {
    fontSize: '1.75rem',
    fontWeight: 700,
    lineHeight: '2rem',
  },
  reasonTitle: {
    marginBottom: '1.5rem',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    textAlign: 'center',
    fontWeight: 700,
    color: '#525252',
  },
  reasonTitleSingleStepModal: {
    marginBottom: '1rem',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    fontWeight: 700,
    textAlign: 'center',
    color: '#5C5C5C',
  },
  list: {
    maxHeight: '35vh',
    marginBottom: '1.5625rem',
    padding: '0 1.5rem',
    overflowY: 'auto',
  },
  listItemContainer: {
    marginBottom: '1rem',

    '&>:hover': {
      backgroundColor: '#91CCFD',
    },
  },
  listItemContainerSingleStepModal: {
    marginBottom: '0.5rem',

    '&>:hover': {
      backgroundColor: '#91CCFD',
    },
  },
  listItem: {
    height: '4rem',
    borderRadius: '0.75rem',
    backgroundColor: 'white',
    boxShadow: '0 0.25rem 0.25rem 0 #00000040',
  },
  listItemSingleStepModal: {
    height: '3rem',
    borderRadius: '0.75rem',
    backgroundColor: 'white',
    boxShadow: '0 0.25rem 0.25rem 0 #00000040',
  },
  listItemText: {
    textAlign: 'center',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    color: '#525057',
    fontWeight: 500,
  },
  submitBtnWrap: {},
  submitBtn: {
    height: '4rem',
    width: '100%',
    backgroundColor: 'rgba(82,80,87,0.6)',
    borderRadius: '0.75rem',
    color: 'white',
    boxShadow: '0.125rem 0.25rem 0.25rem 0 #0000004D',
    '&:hover': {
      backgroundColor: '#525057',
    },
  },
});

export default styles;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '3rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  redMessage: {
    color: 'red',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  icon: {
    fontSize: '5.5rem',
  },
  smallIcon: {
    fontSize: '2em',
    marginBottom: '1.25rem',
  },
  smallImgIcon: {
    width: '3rem',
    height: '3rem',
  },
  mediumIcon: {
    fontSize: '3em',
    marginBottom: '1.25rem',
  },
  extraSmallIcon: {
    fontSize: '2rem',
    marginBottom: '1rem',
  },
  buttonContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  alarmIcon: {
    marginLeft: 'auto',
  },
  smallButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '0.5rem',
  },
  smallButton: {
    width: '7rem',
    height: '4.275rem',
  },
  smallButtonWide: {
    height: '7rem',
    marginBottom: '1rem',
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  rebootText: {
    textAlign: 'center',
  },
  smallButtonLabel: {
    position: 'absolute',
    bottom: theme.spacing.unit,
    fontSize: '1em',
  },
  'left-pane': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    borderRight: 'solid 0.0625rem rgba(0, 0, 0, 0.1)',
    paddingRight: '1rem',
  },
  'center-pane': {
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    alignItems: 'center',
  },
  'robot-arm-pane': {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    height: '95%',
  },
  'robot-arm-ctrller-pane': {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: '0.9375rem',
    width: '48.5%',
    height: '50%',
    transform: 'scale(0.9)',
  },
  'robot-arm-button': {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'darkgray',
  },
  'right-pane': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    borderLeft: 'solid 0.0625rem rgba(0, 0, 0, 0.1)',
    paddingLeft: '1rem',
  },
  'vertical-separator': {
    width: '90%',
    height: '0.0625rem',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    padding: 0,
    margin: 0,
  },
  containerStyle: {
    display: 'flex',
    flexDirection: 'column',
    width: '50em',
    height: '18em',
    padding: '1rem 1rem 0.5rem',
    alignItems: 'center',
  },
  negativeRootBtnStyle: {
    maxHeight: '9em',
    background: theme.palette.secondary.main,
  },
  negativeIconBtnStyle: {
    fontWeight: '900',
    fontSize: '3rem',
  },
  positiveBtnStyle: {
    minWidth: '5em',
    fontWeight: '900',
    background: '#e0e0e0',
    fontSize: '3rem',
    padding: '0.2em',
  },
  buttonSmallIcon: {
    fontSize: '3rem',
    paddingBottom: '1.5rem',
  },
  rightContainer: {
    flex: '1',
    borderLeft: `0.125rem solid ${theme.palette.divider}`,
    marginLeft: '2rem',
    paddingLeft: '2rem',
  },
});

export default styles;

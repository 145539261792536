import {withStyles} from '@material-ui/core';

const styles = withStyles((theme) => ({
  toggleList: {
    display: 'flex',
    flexDirection: 'column',
  },
  topPane: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0.35rem 0.5rem',
    borderBottom: 'solid 0.03125rem rgba(0, 0, 0, 0.1)',
    boxShadow: '0.05rem 0.05rem 0.1rem 0.025rem rgba(0, 0, 0, 0.1)',
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '0.25rem',
  },
  title: {
    fontSize: '0.8rem',
    padding: 0,
    margin: 0,
  },
  iconStatus: {
    fontSize: '1rem',
  },
  bodyPane: {
    display: 'flex',
    flexDirection: 'column',
  },
  toggleItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0.2rem 0.6rem 0.2rem 0.75rem',
    borderBottom: 'solid 0.03125rem rgba(0, 0, 0, 0.1)',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  itemName: {
    fontSize: '0.85rem',
    fontWeight: 'bold',
    paddingRight: '0.3rem',
    margin: 0,
  },
  itemLeft: {flexGrow: 1},
  itemRight: {display: 'flex', alignItems: 'center'},
}));

export default styles;

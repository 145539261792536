import React from 'react';
import PropTypes from 'prop-types';

const Information = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 284.000000 261.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <title>Information</title>
      <g
        transform="translate(0.000000,261.000000) scale(0.100000,-0.100000)"
        fill={props.color}
        stroke="none"
        id="helper-icons"
      >
        <path
          d="M1264 2360 c-397 -72 -719 -364 -841 -765 -25 -85 -27 -101 -27 -285
            0 -184 2 -200 27 -285 113 -369 373 -629 742 -742 85 -25 101 -27 285 -27 184
            0 200 2 285 27 369 112 630 373 742 742 25 85 27 101 27 285 0 184 -2 200 -27
            285 -113 369 -377 633 -742 741 -78 23 -112 27 -255 30 -91 1 -188 -1 -216 -6z
            m393 -364 c89 -46 98 -186 15 -245 -47 -33 -100 -36 -151 -9 -95 51 -108 161
            -28 236 44 41 106 48 164 18z m-26 -498 c-10 -35 -60 -206 -110 -381 -51 -174
            -91 -330 -89 -345 2 -19 9 -28 24 -30 21 -3 51 27 122 117 14 18 23 22 36 14
            15 -8 11 -17 -32 -79 -111 -157 -233 -221 -336 -176 -51 23 -76 62 -76 117 0
            19 38 169 85 333 47 163 85 311 85 329 0 41 -23 63 -66 63 -35 0 -47 21 -22
            36 16 10 311 61 360 63 l37 1 -18 -62z"
        />
      </g>
    </svg>
  );
};

export default Information;

Information.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string.isRequired,
};

import {withStyles} from '@material-ui/core/styles';

const styles = withStyles((theme) => ({
  root: {
    margin: '0.7%',
    display: 'flex',
    flexDirection: 'grid',
    gridAutoFlow: 'row',
  },
  paper: {
    width: '15vw',
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  label: {
    padding: theme.spacing.unit,
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  offline: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.grey[500],
  },
  logo: {
    width: '5em',
    height: '5em',
    display: 'block',
    marginInline: 'auto',
  },
  leftIcon: {
    top: '0.9375rem',
    left: '0.9375rem',
    fontSize: '1.5rem',
    color: '#212121',
    position: 'absolute',
  },
  btnRightIcon: {
    color: '#212121',
    padding: 0,
    margin: 0,
    border: 'none',
    backgroundColor: 'transparent',
    '& :hover': {
      cursor: 'pointer',
    },
  },
  rightIcon: {
    fontSize: '1.5rem',
  },

  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  numRedAlertsContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: '1rem',
  },

  container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default styles;

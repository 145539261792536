import React from 'react';
import {connect} from 'react-redux';
import {Button, Dialog} from '@material-ui/core';

import styles from './styles';

import JogButton from '../../components/JogButton';

class ConfirmationModal extends React.PureComponent {
  render() {
    const {
      classes,
      open,
      title,
      content,
      renderContent,
      onClose,
      textPositive,
      onClickPositive,
      textNegative,
      onClickNegative,
      onClickNegativeJog,
      rebooting,
      negativeBtnStyle,
      positiveBtnStyle,
      containerStyle,
      bottomPaneStyle,
    } = this.props;

    const _content = renderContent ? (
      renderContent()
    ) : (
      <p className={classes.centerText}>{content}</p>
    );

    return (
      <Dialog maxWidth="md" open={open} onClose={onClose}>
        <div className={containerStyle || classes.confirmationModal}>
          <div className={classes.topPane}>
            <p className={classes.title}>{title}</p>
          </div>
          <div className={classes.centerPane}>{_content}</div>
          <div className={bottomPaneStyle || classes.bottomPane}>
            {onClickPositive && (
              <Button
                className={positiveBtnStyle || classes.positiveBtn}
                onClick={onClickPositive}
              >
                {textPositive || 'Yes'}
              </Button>
            )}
            {onClickNegativeJog && (
              <JogButton
                label={textNegative || 'No'}
                onClick={onClickNegativeJog}
                disabled={rebooting}
                classes={negativeBtnStyle}
              ></JogButton>
            )}
            {onClickNegative && (
              <Button
                className={negativeBtnStyle || classes.negativeBtn}
                onClick={onClickNegative}
              >
                {textNegative || 'No'}
              </Button>
            )}
          </div>
        </div>
      </Dialog>
    );
  }
}
const mapState = ({instances}) => ({
  instances,
});
const mapDispatch = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

export default connect(mapState, mapDispatch)(styles(ConfirmationModal));

import {
  CLEAR_AUTH_USER,
  SET_AUTHENTICATED,
  SET_AUTH_LOADING,
  SET_AUTH_USER,
} from '../actions/actionTypes';

const initState = {
  isLoading: false,
  authenticated: false,
  authUser: {},
};

const appState = (state = initState, action) => {
  switch (action.type) {
    case SET_AUTHENTICATED: {
      const authenticated = action.payload;
      return {...state, authenticated};
    }

    case SET_AUTH_LOADING: {
      const isLoading = action.payload;
      return {...state, isLoading};
    }

    case SET_AUTH_USER: {
      const authUser = action.payload;
      return {...state, authUser};
    }

    case CLEAR_AUTH_USER: {
      return {...state, authUser: initState.authUser};
    }

    default:
      return state;
  }
};

export {initState as appStateInitState};
export default appState;

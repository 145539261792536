import {Button, Card, Grid, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {PureComponent} from 'react';

import {colors} from '../styles';

const TextTypography = withStyles({
  root: {
    paddingTop: '0.5rem',
    paddingLeft: '0.5rem',
    fontSize: '1.2rem',
    fontWeight: 600,
    textAlign: 'left',
  },
})(Typography);

const styles = (theme) => ({
  mainBody: {
    position: 'relative',
    maxHeight: '9.375rem',
    maxWidth: '95%',
    margin: '0 auto',
  },
  elevatorPortionCard: {
    width: '28vw',
    height: '20%',
    position: 'relative',
    border: '0.0625rem solid #000000',
  },
  errorElevatorPortionCard: {
    width: '28vw',
    height: '20%',
    position: 'relative',
    border: '0.1875rem solid #FC648F',
    background: '#FCD2DE',
  },
  hopperHeader: {
    height: '5.625rem',
    justifyContent: 'space-between',
    '& .clear': {
      margin: '0.9375rem 0.625rem 0.625rem 0.625rem',
      border: '0.0625rem solid black',
      borderRadius: '0.0625rem',
      fontWeight: 600,
      background: colors.grayish,
      float: 'right',
    },
    '& .action': {
      margin: '1.5625rem 0.625rem 0.9375rem 0.625rem',
      fontWeight: 400,
    },
  },
  confirmBox: {
    display: 'flex',
    float: 'right',
    '& .confirm': {
      margin: '0.9375rem 0.625rem 0.625rem 0.625rem',
      border: '0.0625rem solid black',
      borderRadius: '0.0625rem',
      fontWeight: 600,
      background: colors.lightRed,
    },
    '& .cancelClear': {
      cursor: 'pointer',
    },
  },
  hopperBtns: {
    display: 'flex',
    justifyContent: 'center',
  },
  hopperBtnBody: {
    background: colors.grayish,
    display: 'flex',
    border: `0.0625rem solid ${colors.lightGray}`,
    borderRadius: '0.25rem',
    alignItems: 'center',
    width: '100%',
    margin: '0.625rem',
    justifyContent: 'space-around',
  },

  countBox: {
    display: 'block',
    alignItems: 'center',
    textAlign: 'center',
    borderRight: `0.0625rem solid ${colors.lightGray}`,

    '& .size': {
      fontSize: '1.5em',
      fontWeight: 500,
    },
  },
  arrowBox: {
    textAlign: 'center',
    '& .sizeLarge': {
      fontSize: '1.5em',
      fontWeight: 500,
    },
    '& .arrowIcon': {
      transform: 'rotate(180deg)',
    },
  },
  warningOverlay: {
    alignItems: 'center',
    backgroundColor: '#ED7C9D',
    display: 'flex',
    color: '#000000',
    fontSize: '2em',
    fontWeight: 'bold',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    opacity: '0.9',
    width: '100%',
    zIndex: 1,
  },
});

class Elevator extends PureComponent {
  stateBox(classes) {
    const {
      elevatorState,
      openElevatorModal,
      openElevatorConfirmation,
      jogging,
    } = this.props;

    const missingBin =
      elevatorState.notifications &&
      elevatorState.notifications.length > 0 &&
      elevatorState.notifications.filter(
        (alarms) => alarms.code === 'elevator_bin_missing'
      );

    const doorOpen =
      elevatorState.notifications &&
      elevatorState.notifications.length > 0 &&
      elevatorState.notifications.filter(
        (alarms) => alarms.code === 'elevator_trapdoor_stuck'
      );

    return (
      <Button
        onClick={() => {
          jogging ? openElevatorModal() : openElevatorConfirmation();
        }}
      >
        <Card
          className={
            elevatorState.notifications &&
            elevatorState.notifications.length > 0
              ? classes.errorElevatorPortionCard
              : classes.elevatorPortionCard
          }
        >
          <div>
            <TextTypography>Position: {elevatorState.pose}</TextTypography>
            <TextTypography>
              Door:{' '}
              {elevatorState.pose === 'dump' ||
              (doorOpen && doorOpen.length > 0)
                ? 'Open'
                : 'Closed'}
            </TextTypography>
            <TextTypography>
              Bin:{' '}
              {missingBin && missingBin.length > 0 ? 'Missing' : 'In Place'}
            </TextTypography>
            <TextTypography>
              Food:{' '}
              {elevatorState.ticket && elevatorState.ticket.length > 0
                ? 'Full'
                : 'Empty'}
            </TextTypography>
          </div>
        </Card>
      </Button>
    );
  }

  render() {
    const {classes} = this.props;
    return <div className={classes.mainBody}>{this.stateBox(classes)}</div>;
  }
}
export default withStyles(styles)(Elevator);

import axios from 'axios';
import {GOOGLE_CLIENT_ID} from '../../constants/env';
import {GOOGLE_AUTH_TOKEN} from '../../constants/strings';

import storage from '../../storage';
import googleService from './services';

const googleServer = axios.create({
  // baseURL: 'https://www.googleapis.com/oauth2/v3/tokeninfo',
  baseURL: 'https://oauth2.googleapis.com',
});

const getGoogleInstance = (callback) => {
  let scanner;
  const reader = () => {
    if (window.google)
      if (window.google.accounts) {
        callback(window.google.accounts);
        clearInterval(scanner);
      }
  };
  scanner = setInterval(reader, 100);
};

const googleSignIn = (googleCallback) => {
  window.google = {};
  const callback = (account) => {
    const {id: google} = account;
    const handleGoogleCallback = (res) => {
      const idToken = res.credential;
      storage.set(GOOGLE_AUTH_TOKEN, idToken);
      googleCallback();
    };

    google.initialize({
      client_id: GOOGLE_CLIENT_ID,
      callback: handleGoogleCallback,
    });
    google.renderButton(document.getElementById('google-btn'), {
      theme: 'outline',
      size: 'large',
    });
  };
  getGoogleInstance(callback);
};

export {googleService, googleSignIn};
export default googleServer;

import {
  Button,
  ClickAwayListener,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {AiFillCloseCircle} from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import {setOffsetUpdated} from '../actions';
import {setOffsetData} from '../actions/index';
import axes from '../assets/axes.svg';
import MisoDialogTitle from '../components/MisoDialogTitle';
import {OFFSET_DIRECTIONS} from '../enums';
import {revertOffset, updateOffset} from '../ros_init';
import store from '../store/index';
import {
  IconComponent,
  callService,
  getInfo,
  isOffsetXYZRequired,
  offsetFormChecker,
  tooltipInfo,
  transformToSnakeCase,
} from '../util';

const CustomTextField = withStyles(() => ({
  root: {
    width: '50%',

    '& .MuiInputBase-root-139 > .MuiInputBase-input-149': {
      textAlign: 'center',
    },

    '& .MuiInputBase-root-197 > .MuiInputBase-input-207': {
      textAlign: 'center',
    },
  },
}))((props) => {
  const {classes, offsetDirections, value} = props;

  return (
    <TextField
      variant="outlined"
      value={offsetDirections[value]}
      className={classes.root}
    />
  );
});
const useStyles = () => ({
  modalHeader: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  closeModalBtn: {
    position: 'absolute',
    right: '0em',
    top: '0.5em',
  },
  gridBody: {
    padding: '0em 2em 2em 2em',
  },
  formCtrlBody: {
    marginBottom: '1vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btnBody: {
    borderRadius: '50%',
    border: '0.0625rem solid gray',
    height: '4vh',
    width: '2.5vw',
    margin: '2em',
    cursor: 'pointer',
  },
  btnText: {
    fontSize: '1.5em',
  },
  centerMe: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexColumn: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
  },
  infoButton: {
    justifyContent: 'flex-end',
    minWidth: '3rem',
  },
  success: {
    border: '0.0625rem solid lime',
    width: '50%',
    display: 'block',
    height: '7vh',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1em',
    background: '#00ff0052',
    textAlign: 'center',
  },
  error: {
    border: '0.0625rem solid #f50057',
    width: '50%',
    display: 'block',
    height: '7vh',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1em',
    background: '#f5005757',
    textAlign: 'center',
  },
  none: {
    display: 'none',
  },
  required: {
    color: 'red',
    fontSize: '0.75em',
  },
  infoPadding: {
    paddingLeft: '0.75rem',
  },
});

export const OffsetTuningModal = (props) => {
  const {onClose, open, fryerNumber, offsetUpdated, instance} = props;
  let imgRef = useRef();
  let hangRef = useRef();
  let slotRef = useRef();
  let fryerRef = useRef();
  let xRef = useRef();
  let yRef = useRef();
  let zRef = useRef();

  const classes = useStyles();

  const [fryerNumArray, setFryerNumArray] = React.useState([]);
  const [enlarged, setEnlarged] = React.useState(false);
  const [offsetFryerNum, setOffsetFryerNum] = React.useState({
    name: 'Fryer Number',
    value: '',
  });
  const [offsetSlot, setOffsetSlot] = React.useState({
    name: 'Left/Right Slot',
    value: '',
  });
  const [offsetFryingSlot, setOffsetFryingSlot] = React.useState({
    name: 'Frying/Hanging Slot',
    value: '',
  });
  const [offsetUsername, setOffsetusername] = React.useState('');
  const [offsetIssue, setOffsetIssue] = React.useState('');
  const [triggerResponse, setTriggerResponse] = React.useState(false);
  const [offsetDirections, setOffsetDirections] = React.useState({
    X: 0.0,
    Y: 0.0,
    Z: 0.0,
  });
  const [requireChecker, setRequireChecker] = React.useState([]);
  const [triggerRequire, setTriggerRequire] = React.useState(false);
  const [updateOffsetSuccess, setUpdateOffsetSuccess] = React.useState(false);

  React.useEffect(() => {
    let initArray = [];
    let payload = [
      offsetFryerNum,
      offsetSlot,
      offsetFryingSlot,
      offsetUsername,
      offsetIssue,
    ];

    for (let x = 1; x <= fryerNumber; x++) {
      initArray.push(x);
    }
    if (offsetUpdated && Object.keys(offsetUpdated).length !== 0) {
      setTriggerResponse(true);
    }

    if (triggerResponse === true) {
      setTimeout(() => {
        //Note: Reset the offsetUpdated state
        if (
          offsetUpdated.from === 'Revert Offset Tuning' ||
          offsetUpdated.from === 'Update Offset'
        ) {
          store.dispatch(setOffsetUpdated(instance, {}));
          setUpdateOffsetSuccess(false);
        }
        if (offsetUpdated.from === 'Revert Offset Test') {
          setUpdateOffsetSuccess(false);
        }
        setTriggerResponse(false);
        if (
          updateOffsetSuccess &&
          (offsetUpdated.description !== '' ||
            offsetUpdated.description !== undefined)
        ) {
          onClose();
        } else if (
          !offsetUpdated.success &&
          Object.keys(offsetUpdated).length !== 0
        ) {
          if (offsetUpdated.description.includes('basket_id')) {
            let reqMsg = {
              update_mode: 'delta',
              waypoint: 'target',
              reference_type: 'absolute',
              slot_path: `${offsetFryerNum.value}/${offsetSlot.value}/${offsetFryingSlot.value}`,
              offset: {
                x: offsetDirections.X,
                y: offsetDirections.Y,
                z: offsetDirections.Z,
              },
              reporter: offsetUsername,
              issue: offsetIssue,
              technique: 'manual',
            };
            callService(
              updateOffset,
              instance,
              reqMsg,
              'Update Offset',
              null,
              setSuccessUpdateOffsetCallback
            );
          }
        }
      }, 1000);
    }
    setFryerNumArray(initArray);
    setRequireChecker(
      offsetFormChecker(payload),
      !isOffsetXYZRequired(offsetDirections)
    );
  }, [
    fryerNumber,
    triggerResponse,
    offsetFryerNum,
    offsetSlot,
    offsetFryingSlot,
    offsetUpdated,
    offsetUsername,
    offsetIssue,
    updateOffsetSuccess,
  ]);

  const selectFryerNum = (event) => {
    let newVal = `fryer_${event.target.value}`;
    setOffsetFryerNum({
      name: event.target.value,
      value: newVal,
    });
  };

  const selectLeftRightSlot = (event) => {
    let newVal = transformToSnakeCase(event.target.value);
    setOffsetSlot({
      name: event.target.value,
      value: newVal,
    });
  };

  const enlargeImage = () => {
    setEnlarged(true);
  };

  const closeImage = () => {
    setEnlarged(false);
  };

  const selectFryHangSlot = (event) => {
    let initVal;

    if (offsetSlot.value === 'right_slot') {
      initVal = 'right';
    } else {
      initVal = 'left';
    }
    setOffsetFryingSlot({
      name: event.target.value,
      value: transformToSnakeCase(initVal + event.target.value),
    });
  };

  const setUsername = (event) => {
    setOffsetusername(event.target.value);
  };

  const setTextField = (event) => {
    setOffsetIssue(event.target.value);
  };

  const changeMe = (event) => {
    let initObj = {
      X: offsetDirections.X,
      Y: offsetDirections.Y,
      Z: offsetDirections.Z,
    };
    if (event.currentTarget.name === 'decrement') {
      if (offsetDirections[event.currentTarget.value] > -0.01) {
        let subtracting = offsetDirections[event.currentTarget.value] - 0.0025;
        initObj[event.currentTarget.value] = parseFloat(subtracting.toFixed(4));
      }
    } else {
      if (offsetDirections[event.currentTarget.value] < 0.01) {
        let adding = offsetDirections[event.currentTarget.value] + 0.0025;
        initObj[event.currentTarget.value] = parseFloat(adding.toFixed(4));
      }
    }
    setOffsetDirections(initObj);
  };

  const revertingOffset = () => {
    const reqMsg = {};
    callService(revertOffset, instance, reqMsg, 'Revert Offset Tuning');
    setTimeout(() => {
      setTriggerResponse(true);
    }, 1000);
    setTriggerRequire(false);
  };

  const setSuccessUpdateOffsetCallback = () => {
    setUpdateOffsetSuccess(true);
  };

  const resetAllFields = () => {
    setOffsetFryerNum({name: 'Fryer Number', value: ''});
    setOffsetSlot({name: 'Left/Right Slot', value: ''});
    setOffsetFryingSlot({name: 'Frying/Hanging Slot', value: ''});
    setOffsetDirections({X: 0.0, Y: 0.0, Z: 0.0});
    setOffsetusername('');
    setOffsetIssue('');
  };

  const submitOffset = () => {
    //NOTE: for cache memory
    store.dispatch(
      setOffsetData(instance, {
        fryerNumber: offsetFryerNum,
        slotInfo: offsetSlot,
        behaviorType: offsetFryingSlot,
        username: offsetUsername,
        issue: offsetIssue,
        xyz: {
          X: offsetDirections.X,
          Y: offsetDirections.Y,
          Z: offsetDirections.Z,
        },
      })
    );

    let payload = [
      offsetFryerNum,
      offsetSlot,
      offsetFryingSlot,
      offsetUsername,
      offsetIssue,
    ];
    let reqMsg = {
      update_mode: 'delta',
      waypoint: 'target',
      reference_type: 'absolute',
      slot_path: `${offsetFryerNum.value}/${offsetSlot.value}/${offsetFryingSlot.value}`,
      offset: {
        x: offsetDirections.X,
        y: offsetDirections.Y,
        z: offsetDirections.Z,
      },
      reporter: offsetUsername,
      issue: offsetIssue,
      technique: 'manual',
    };

    if (
      offsetFormChecker(payload).every((data) => data === true) &&
      !isOffsetXYZRequired(offsetDirections)
    ) {
      callService(
        updateOffset,
        instance,
        reqMsg,
        'Update Offset',
        null,
        setSuccessUpdateOffsetCallback
      );
      //reset
      resetAllFields();
      setTriggerRequire(false);
    } else {
      setTriggerRequire(true);
    }
  };

  const closeThisModal = () => {
    resetAllFields();
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeThisModal}
        aria-labelledby="jog-dialog"
        fullWidth
        maxWidth="md"
      >
        {!enlarged ? (
          <div>
            <div style={classes.modalHeader}>
              <MisoDialogTitle style={{width: '20%'}}>
                Offset Tuning - {getInfo(instance).name}
              </MisoDialogTitle>
              {triggerResponse && (
                <div
                  style={
                    Object.keys(offsetUpdated).length !== 0 &&
                    offsetUpdated.success
                      ? classes.success
                      : Object.keys(offsetUpdated).length !== 0 &&
                          !offsetUpdated.description.includes('basket_id')
                        ? classes.error
                        : classes.none
                  }
                >
                  <Typography>
                    {Object.keys(offsetUpdated).length !== 0 &&
                    offsetUpdated.success
                      ? 'Success'
                      : Object.keys(offsetUpdated).length !== 0 &&
                          !offsetUpdated.description.includes('basket_id')
                        ? 'Error'
                        : ''}
                  </Typography>
                  {!offsetUpdated.success &&
                    Object.keys(offsetUpdated).length !== 0 &&
                    !offsetUpdated.description.includes('basket_id') && (
                      <Typography>{offsetUpdated.description}</Typography>
                    )}
                </div>
              )}
              <IconButton
                aria-label="offsetTuning"
                onClick={closeThisModal}
                data-tip="Offset Tuning Modal"
                style={classes.closeModalBtn}
              >
                <AiFillCloseCircle />
              </IconButton>
            </div>
            <Grid container>
              <Grid item container xs={12} spacing={8}>
                <Grid xs={4} item style={classes.gridBody}>
                  {!requireChecker[0] && triggerRequire && (
                    <Typography style={classes.required}>
                      This Field is Required *
                    </Typography>
                  )}
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    style={classes.formCtrlBody}
                  >
                    <InputLabel htmlFor="fryer-label">Fryer Number</InputLabel>
                    <Select
                      value={offsetFryerNum.name}
                      label="Fryer Number"
                      onChange={selectFryerNum}
                      input={
                        <OutlinedInput
                          labelWidth={114}
                          name="fryer"
                          id="fryer-label"
                        />
                      }
                      fullWidth
                      notched
                    >
                      {fryerNumArray.length > 0 &&
                        fryerNumArray.map((fryerNum, index) => {
                          return (
                            <MenuItem key={index} value={fryerNum}>
                              {fryerNum}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <div style={classes.infoPadding}>
                      <ClickAwayListener
                        onClickAway={() => {
                          ReactTooltip.hide(fryerRef.current);
                        }}
                      >
                        <div>
                          <div
                            ref={fryerRef}
                            data-for="fryer-custom-event"
                            data-tip="Select the Fryer Number. Numbering starts from the left most fryer."
                          >
                            <IconComponent
                              icon2="Information"
                              height={30}
                              width={30}
                              color="#000000"
                            />
                          </div>
                          <ReactTooltip
                            id="fryer-custom-event"
                            globalEventOff="click"
                            place="right"
                            html={true}
                            event="click"
                          />
                        </div>
                      </ClickAwayListener>
                    </div>
                  </FormControl>
                  {!requireChecker[1] && triggerRequire && (
                    <Typography style={classes.required}>
                      This Field is Required *
                    </Typography>
                  )}
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    style={classes.formCtrlBody}
                  >
                    <InputLabel htmlFor="slot-label">
                      Left/Right Slot
                    </InputLabel>
                    <Select
                      disabled={offsetFryerNum.value === ''}
                      value={offsetSlot.name}
                      label="Left/Right Slot"
                      onChange={selectLeftRightSlot}
                      input={
                        <OutlinedInput
                          labelWidth={114}
                          name="slot"
                          id="slot-label"
                        />
                      }
                      fullWidth
                      notched
                    >
                      <MenuItem value="Left Slot">
                        <em>Left Slot</em>
                      </MenuItem>
                      <MenuItem value="Right Slot">
                        <em>Right Slot</em>
                      </MenuItem>
                    </Select>
                    <div style={classes.infoPadding}>
                      <ClickAwayListener
                        onClickAway={() => {
                          ReactTooltip.hide(slotRef.current);
                        }}
                      >
                        <div>
                          <div
                            ref={slotRef}
                            data-for="slot-custom-event"
                            data-tip="Select the side of the fryer where the issue is occuring."
                          >
                            <IconComponent
                              icon2="Information"
                              height={30}
                              width={30}
                              color="#000000"
                            />
                          </div>
                          <ReactTooltip
                            id="slot-custom-event"
                            globalEventOff="click"
                            place="right"
                            html={true}
                            event="click"
                          />
                        </div>
                      </ClickAwayListener>
                    </div>
                  </FormControl>
                  {!requireChecker[2] && triggerRequire && (
                    <Typography style={classes.required}>
                      This Field is Required *
                    </Typography>
                  )}
                  <FormControl
                    variant="outlined"
                    fullWidth={true}
                    style={classes.formCtrlBody}
                  >
                    <InputLabel htmlFor="hang-label">
                      Frying/Hanging Slot
                    </InputLabel>
                    <Select
                      disabled={offsetSlot.value === ''}
                      value={offsetFryingSlot.name}
                      label="Frying/Hanging Slot"
                      onChange={selectFryHangSlot}
                      input={
                        <OutlinedInput
                          labelWidth={164}
                          name="slot"
                          id="hang-label"
                        />
                      }
                      fullWidth
                      notched
                    >
                      <MenuItem value="Frying Slot">
                        <em>Frying Slot</em>
                      </MenuItem>
                      <MenuItem value="Hanging Slot">
                        <em>Hanging Slot</em>
                      </MenuItem>
                    </Select>
                    <div style={classes.infoPadding}>
                      <ClickAwayListener
                        onClickAway={() => {
                          ReactTooltip.hide(hangRef.current);
                        }}
                      >
                        <div>
                          <div
                            ref={hangRef}
                            data-for="hang-custom-event"
                            data-tip="Select which part of the slot is experiencing issues. </br>For example, if Flippy can't grab a basket or is </br>not dropping the basket properly in a fryer slot, then select Frying slot. </br>If Flippy can't grab a hanging basket or experiences a </br>mishang or a collision while hanging, then select Hanging slot."
                          >
                            <IconComponent
                              icon2="Information"
                              height={30}
                              width={30}
                              color="#000000"
                            />
                          </div>
                          <ReactTooltip
                            id="hang-custom-event"
                            globalEventOff="click"
                            place="right"
                            html={true}
                            event="click"
                          />
                        </div>
                      </ClickAwayListener>
                    </div>
                  </FormControl>
                  {!requireChecker[3] && triggerRequire && (
                    <Typography style={classes.required}>
                      This Field is Required *
                    </Typography>
                  )}
                  <TextField
                    variant="outlined"
                    label="Username"
                    style={{width: '85%'}}
                    value={offsetUsername}
                    onChange={setUsername}
                  />
                </Grid>
                <Grid xs={4} item>
                  {isOffsetXYZRequired(offsetDirections) && triggerRequire && (
                    <Typography style={classes.required}>
                      Fill up at least on of the X, Y, Z fields *
                    </Typography>
                  )}
                  {OFFSET_DIRECTIONS.map((value, index) => {
                    return (
                      <div style={{textAlign: 'center'}} key={index}>
                        <div style={classes.centerMe}>
                          <span>{value}</span>
                          <div style={classes.infoPadding}>
                            <ClickAwayListener
                              onClickAway={() => {
                                value === 'X'
                                  ? ReactTooltip.hide(xRef.current)
                                  : value === 'Y'
                                    ? ReactTooltip.hide(yRef.current)
                                    : ReactTooltip.hide(zRef.current);
                              }}
                            >
                              <div>
                                <a
                                  href={(ref) =>
                                    value === 'X'
                                      ? (xRef.current = ref)
                                      : value === 'Y'
                                        ? (yRef.current = ref)
                                        : (zRef.current = ref)
                                  }
                                  data-for={`${value}-custom-event`}
                                  data-tip={tooltipInfo(value)}
                                >
                                  <IconComponent
                                    icon2="Information"
                                    height={30}
                                    width={30}
                                    color="#000000"
                                  />
                                </a>
                                <ReactTooltip
                                  id={`${value}-custom-event`}
                                  globalEventOff="click"
                                  place="right"
                                  html={true}
                                  event="click"
                                />
                              </div>
                            </ClickAwayListener>
                          </div>
                        </div>
                        <div style={classes.centerMe}>
                          <button
                            style={classes.btnBody}
                            disabled={value > -1}
                            value={value}
                            name="decrement"
                            onClick={changeMe}
                          >
                            <Typography style={classes.btnText}>-</Typography>
                          </button>
                          <CustomTextField
                            offsetDirections={offsetDirections}
                            value={value}
                          />
                          <button
                            style={classes.btnBody}
                            disabled={value > 1}
                            value={value}
                            name="increment"
                            onClick={changeMe}
                          >
                            <Typography style={classes.btnText}>+</Typography>
                          </button>
                        </div>
                        {index < 2 && <hr />}
                      </div>
                    );
                  })}
                </Grid>
                <Grid xs={4} item>
                  <div style={classes.flexColumn}>
                    <div style={classes.centerMe}>
                      <span>Coordinate Axes</span>
                      <div style={classes.infoPadding}>
                        <ClickAwayListener
                          onClickAway={() => {
                            ReactTooltip.hide(imgRef.current);
                          }}
                        >
                          <div>
                            <div
                              ref={imgRef}
                              data-for="img-custom-event"
                              data-tip="Click on the image to get an enlarged view"
                            >
                              <IconComponent
                                icon2="Information"
                                height={25}
                                width={25}
                                color="#000000"
                              />
                            </div>
                            <ReactTooltip
                              id="img-custom-event"
                              globalEventOff="click"
                              place="left"
                              html={true}
                              event="click"
                            />
                          </div>
                        </ClickAwayListener>
                      </div>
                    </div>
                    <Button onClick={enlargeImage}>
                      <img alt="axes" src={axes} height={180} width={300} />
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={8}>
                <Grid xs={8} item style={classes.gridBody}>
                  {!requireChecker[4] && triggerRequire && (
                    <Typography style={classes.required}>
                      This Field is Required *
                    </Typography>
                  )}
                  <textarea
                    placeholder="Detailed explanation for why the change is being made."
                    // defaultValue="Detailed explanation for why the change is being made."
                    onChange={setTextField}
                    value={offsetIssue}
                    style={{width: '100%', height: '10vh', textIndent: '0.2cm'}}
                  />
                </Grid>
                <Grid xs={4} item style={classes.flexColumn}>
                  <Button
                    variant="outlined"
                    style={{height: '5vh', width: '85%', marginBottom: '1vh'}}
                    onClick={submitOffset}
                  >
                    <Typography>Submit</Typography>
                  </Button>
                  <Button
                    variant="outlined"
                    style={{height: '5vh', width: '85%'}}
                    onClick={revertingOffset}
                  >
                    <Typography>Revert last offset change</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Grid container>
            <Grid item xs={12} style={{textAlign: 'center'}}>
              <img alt="axes" src={axes} height={825} width={940} />
            </Grid>
            <Grid item xs={12}>
              <IconButton
                aria-label="offsetTuning"
                onClick={closeImage}
                data-tip="Offset Tuning Modal"
                style={classes.closeModalBtn}
              >
                <Close style={{color: 'black'}} fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Dialog>
    </div>
  );
};

export default OffsetTuningModal;

OffsetTuningModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import DraggableDialog from '../../components/DraggableDialog';
import {
  getAlertDesign,
  getInstanceStatus,
  smartCut,
  toPascalCase,
} from '../../util';
import styles from './styles';
import ToggleList from './ToggleList';

class RealtimeAlert extends PureComponent {
  getSmartPosition = (position) => {
    let x = position.x,
      y = position.y;
    const {innerWidth: screen_width, innerHeight: screen_height} = window;
    const modal_width = 223;
    const modal_height = 320;

    const invalidWidth = position.x + modal_width > screen_width;
    const invalidHeight = position.y + modal_height > screen_height;

    if (invalidWidth) x = x - modal_width;
    if (invalidHeight) y = y - modal_height;

    return {x, y};
  };

  render() {
    const {getSmartPosition} = this;
    const {
      position,
      classes,
      instancesStatus,
      selectedInstance,
      open,
      onClose,
    } = this.props;

    const instanceStatus = getInstanceStatus(instancesStatus, selectedInstance);

    const {
      alertStyle: {borderColor},
    } = getAlertDesign(instanceStatus.info);

    return (
      <DraggableDialog
        open={open}
        onClose={onClose}
        backdropStyle={{backgroundColor: 'transparent'}}
        paperStyle={{
          position: 'absolute',
          boxShadow: '0 0.15625rem 0.3125rem 0.03125rem rgba(0, 0, 0, 0.25)',
          margin: 0,
          padding: 0,
          left: getSmartPosition(position).x,
          top: getSmartPosition(position).y,
        }}
      >
        <div className={classes.realtimeAlert}>
          <div className={classes.topPane}>
            <p className={classes.title}>
              {String(selectedInstance.site_id).toUpperCase()}
            </p>
            <div
              className={classes.status}
              style={{backgroundColor: borderColor}}
            />
          </div>
          <div className={classes.bodyPane}>
            {instanceStatus &&
              Object.keys(instanceStatus.info).map((key, index) => {
                return (
                  <ToggleList
                    key={index}
                    title={toPascalCase(smartCut(key, 30))}
                    statuses={instanceStatus.info[key]}
                  />
                );
              })}
          </div>
        </div>
      </DraggableDialog>
    );
  }
}

const mapState = ({instances}) => ({
  instances,
});
const mapDispatch = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

export default connect(mapState, mapDispatch)(styles(RealtimeAlert));

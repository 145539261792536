import {withStyles} from '@material-ui/core';

const styles = withStyles(() => ({
  signInPane: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    gap: '1.09375rem',
  },
  topPane: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flex: 2,
    backgroundColor: '#fff',
  },
  misoLogo: {
    width: '50rem',
    color: '#3a2e33',
  },
  centerPane: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
    margin: 0,
    padding: 0,
  },
  text: {
    color: '#b2b2b2',
    fontSize: '2.5rem',
    margin: 0,
    padding: 0,
  },
  bottomPane: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 2,
    backgroundColor: '#fff',
  },
}));

export default styles;

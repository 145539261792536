import React, {PureComponent} from 'react';
import {IoMdArrowDropdown, IoMdArrowDropup} from 'react-icons/io';

import {getStatus, toReadableString} from '../../../util';
import styles from './styles';

class ToggleList extends PureComponent {
  constructor() {
    super();
    this.state = {
      toggle: false,
    };
  }

  formatLastUpdated = (last_updated) => {
    // Create a new date object using the UTC string
    const utcDate = new Date(last_updated);

    // Convert UTC Timestamp to Local Timestamp
    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    );

    const time = localDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
    const extraSpaces = '\u00A0\u00A0\u00A0'; // Adding three non-breaking spaces
    const formattedDate =
      time +
      extraSpaces +
      utcDate
        .toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
        })
        .replace(/\//g, '-');

    return formattedDate;
  };

  renderToggleIcon = () => {
    const {classes} = this.props;
    const {toggle} = this.state;
    if (toggle) {
      return <IoMdArrowDropup className={classes.iconStatus} />;
    }
    return <IoMdArrowDropdown className={classes.iconStatus} />;
  };

  render() {
    const {title, classes, statuses} = this.props;
    const {toggle} = this.state;
    const {renderToggleIcon} = this;

    const keys = statuses.stats ? Object.keys(statuses.stats) : null;

    return (
      <div>
        {statuses.stats && (
          <div className={classes.toggleList}>
            <div
              className={classes.topPane}
              onClick={() => {
                this.setState((prev) => ({...prev, toggle: !toggle}));
              }}
            >
              <p className={classes.title}>{title}</p>
              <div className={classes.right}>
                {getStatus(statuses.status, [])}
                {renderToggleIcon()}
              </div>
            </div>
            {toggle && keys && (
              <div className={classes.bodyPane}>
                {keys.map((key, index) => (
                  <div key={index} className={classes.toggleItem}>
                    <div className={classes.itemLeft}>
                      <p className={classes.itemName}>
                        {toReadableString(key)}
                      </p>
                    </div>
                    <div className={classes.itemRight}>
                      <p className={classes.itemName}>
                        {statuses.last_updated[key] !== undefined &&
                        statuses.last_updated[key] !== null &&
                        statuses.stats[key] !== 0
                          ? this.formatLastUpdated(statuses.last_updated[key])
                          : ''}
                      </p>
                      <p className={classes.itemName}>
                        {getStatus(
                          Number(statuses.stats[key]),
                          Object.keys(statuses.stats)
                        )}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default styles(ToggleList);

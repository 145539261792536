import React from 'react';

const CameraVideo = () => {
  return (
    <svg
      width="36"
      height="20"
      viewBox="0 0 36 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.1856 2.52684L26.9856 6.11964V1.96397C26.9762 1.53408 26.7968 1.12536 26.4866 0.826969C26.1763 0.528579 25.7605 0.364727 25.3297 0.371154H2.24165C2.0289 0.367985 1.81761 0.406673 1.61984 0.485009C1.42208 0.563344 1.24172 0.679793 1.08906 0.827704C0.936392 0.975614 0.814413 1.15209 0.730096 1.34705C0.64578 1.54201 0.600785 1.75164 0.597656 1.96397V17.94C0.600785 18.1523 0.64578 18.3619 0.730096 18.5569C0.814413 18.7519 0.936392 18.9283 1.08906 19.0762C1.24172 19.2242 1.42208 19.3406 1.61984 19.4189C1.81761 19.4973 2.0289 19.536 2.24165 19.5328H25.2937C25.7245 19.5392 26.1403 19.3754 26.4506 19.077C26.7608 18.7786 26.9402 18.3699 26.9496 17.94V13.7604L34.1496 17.3532C34.2735 17.4154 34.4111 17.4457 34.5497 17.4411C34.6883 17.4365 34.8235 17.3972 34.943 17.3269C35.0624 17.2566 35.1623 17.1574 35.2333 17.0385C35.3044 16.9197 35.3444 16.7849 35.3496 16.6466V3.23342C35.3419 3.09994 35.3017 2.97033 35.2327 2.85572C35.1637 2.74112 35.0678 2.64495 34.9533 2.57546C34.8389 2.50597 34.7092 2.46525 34.5755 2.45678C34.4418 2.44831 34.308 2.47235 34.1856 2.52684Z"
        fill="#525252"
      />
    </svg>
  );
};

export default CameraVideo;

import {withStyles} from '@material-ui/core';

const styles = withStyles((theme) => ({
  realtimeAlert: {
    display: 'flex',
    flexDirection: 'column',
    width: '22rem',
    height: '20rem',
  },
  topPane: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 0.75rem 0 0.5rem',
    margin: 0,
    height: '2rem',
    borderBottom: 'solid 0.0625rem rgba(0, 0, 0, 0.2)',
  },
  title: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    padding: 0,
    margin: 0,
  },
  status: {
    width: '0.5rem',
    height: '0.5rem',
    padding: 0,
    margin: 0,
    borderRadius: '0.5rem',
    border: 'solid 0.125rem rgba(0, 0, 0, 0.75)',
  },
  bodyPane: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    flex: 8.5,
    '&::-webkit-scrollbar': {
      padding: '0.125rem',
      width: '0.6rem',
      background: 'rgba(0, 0, 0, 0.065)',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#fff',
      borderRadius: '1rem',
    },
  },
}));

export default styles;

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props) {
  return (
    <Draggable cancel=".nodrag">
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog(props) {
  const {children, backdropStyle, paperStyle, ...other} = props;

  return (
    <Dialog
      BackdropProps={{
        style: backdropStyle,
      }}
      PaperComponent={PaperComponent}
      PaperProps={{
        style: paperStyle,
      }}
      {...other}
    >
      {children}
    </Dialog>
  );
}

import {Switch, withStyles} from '@material-ui/core';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import {
  disableFryerSensor,
  getDisabledFryerSensors,
  getDispenserLockSensorParam,
  getElevatorSensorParam,
  getGripperSensorParam,
  mqtt_ap,
  setRelayController,
  USER_ID,
} from '../../ros_init';
import {callService, transformToSnakeCase} from '../../util';
import {MQTTRequest, MQTTRequestType} from '../../aws_iot/thing_mqtt_request';
import store from '../../store/index';
import {
  setDisabledFryerSensorsArray,
  setGetDispenserLockSensor,
  setGetElevatorSensor,
  setGetGripperSensor,
} from '../../actions/index';

const styles = (theme) => ({
  container: {
    width: '18rem',
    margin: '0.5rem',
  },
  switchDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textOn: {
    color: '#009688',
  },
  textTitle: {
    fontWeight: 'bold',
  },
});

class SensorToggle extends PureComponent {
  handleSwitch = async (checked, title) => {
    const lowerCaseTitle = title.toLowerCase();

    if (lowerCaseTitle.includes('fryer lock')) {
      await this.toggleFryerLockSensor(title, checked);
    } else if (lowerCaseTitle.includes('relay')) {
      await this.toggleFryerRelay(checked);
    } else if (lowerCaseTitle.includes('gripper')) {
      await this.toggleGripperSensor(checked);
    } else if (lowerCaseTitle.includes('dispenser')) {
      await this.toggleDispenserLockSensor(checked);
    } else if (lowerCaseTitle.includes('elevator')) {
      await this.toggleElevatorSensor(checked);
    }
  };

  toggleFryerLockSensor = async (title, toggle) => {
    let request = {name: transformToSnakeCase(title), data: toggle};
    try {
      await callService(
        disableFryerSensor,
        this.props.instance,
        request,
        'Toggle Fryer Sensor'
      );

      const disabledFryerSensorsCallback = (disabledFryerSensorsArray) => {
        return store.dispatch(
          setDisabledFryerSensorsArray(
            this.props.instance,
            disabledFryerSensorsArray
          )
        );
      };

      await mqtt_ap.submit_request(
        getDisabledFryerSensors,
        disabledFryerSensorsCallback,
        true
      );
    } catch (error) {
      console.log('Submit_Request error: ' + error);
    }
  };

  toggleFryerRelay = async (toggle) => {
    let request = {name: 'fryer_locks', command: toggle ? 'on' : 'off'};
    try {
      await callService(
        setRelayController,
        this.props.instance,
        request,
        'Toggle Fryer Relay'
      );
    } catch (error) {
      console.log('Submit_Request error: ' + error);
    }
  };

  toggleGripperSensor = async (toggle) => {
    const setGripperSensorParam = new MQTTRequest({
      request_type: MQTTRequestType.ROS_PARAM_SET,
      ros_topic: '/install/behaviors/enable_gripper_sensor',
      type: '',
      data: {value: toggle},
      user: USER_ID,
    });

    try {
      await mqtt_ap.submit_request(setGripperSensorParam, undefined, true);

      const getGripperSensorParamCallback = (gripperSensor) => {
        return store.dispatch(
          setGetGripperSensor(this.props.instance, gripperSensor.value)
        );
      };

      await mqtt_ap.submit_request(
        getGripperSensorParam,
        getGripperSensorParamCallback,
        true
      );
    } catch (error) {
      console.log('Submit_Request error: ' + error);
    }
  };

  toggleDispenserLockSensor = async (toggle) => {
    const setDispenserLockSensorParam = new MQTTRequest({
      request_type: MQTTRequestType.ROS_PARAM_SET,
      ros_topic: '/smart_chef/enable_dispenser_lock',
      type: '',
      data: {value: toggle},
      user: USER_ID,
    });

    try {
      await mqtt_ap.submit_request(
        setDispenserLockSensorParam,
        undefined,
        true
      );

      const getDispenserLockSensorParamCallback = (dispenserLockSensor) => {
        return store.dispatch(
          setGetDispenserLockSensor(
            this.props.instance,
            dispenserLockSensor.value
          )
        );
      };

      await mqtt_ap.submit_request(
        getDispenserLockSensorParam,
        getDispenserLockSensorParamCallback,
        true
      );
    } catch (error) {
      console.log('Submit_Request error: ' + error);
    }
  };

  toggleElevatorSensor = async (toggle) => {
    const setElevatorSensorParam = new MQTTRequest({
      request_type: MQTTRequestType.ROS_PARAM_SET,
      ros_topic: '/elevator/enable_sensors',
      type: '',
      data: {value: toggle},
      user: USER_ID,
    });

    try {
      await mqtt_ap.submit_request(setElevatorSensorParam, undefined, true);

      const getElevatorSensorParamCallback = (elevatorSensor) => {
        return store.dispatch(
          setGetElevatorSensor(this.props.instance, elevatorSensor.value)
        );
      };

      await mqtt_ap.submit_request(
        getElevatorSensorParam,
        getElevatorSensorParamCallback,
        true
      );
    } catch (error) {
      console.log('Submit_Request error: ' + error);
    }
  };

  render() {
    const {classes, title, toggle} = this.props;

    return (
      <div className={classes.container}>
        <p className={classes.textTitle}>{title}</p>
        <div className={classes.switchDiv}>
          <span>Off</span>
          <Switch
            checked={
              title.toLowerCase().includes('fryer lock')
                ? toggle && toggle.value && Object.values(toggle).length > 0
                  ? !Object.values(toggle.value).includes(
                      transformToSnakeCase(title)
                    )
                  : true
                : toggle
            }
            onClick={(e) => this.handleSwitch(e.target.checked, title)}
            color="primary"
          />
          <span className={classes.textOn}>On</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const toggleValue = Array.isArray(
    state.instances[ownProps.instance][ownProps.toggleName]
  )
    ? state.instances[ownProps.instance][ownProps.toggleName].find(
        (item) => item.name === 'fryer_locks'
      ).is_closed || false
    : state.instances[ownProps.instance][ownProps.toggleName] || false;

  return {
    toggle: toggleValue,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(SensorToggle));

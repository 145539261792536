import React from 'react';

const PoseCalibration = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.005 27.5083H36.7508C37.6609 27.5083 38.4013 28.2394 38.4016 29.1381V31.2865C38.4016 32.1851 37.6612 32.9162 36.7511 32.9162H36.0053C35.2469 32.9162 34.6067 32.4083 34.4143 31.7191H33.3937L35.5847 36.7563C35.6136 36.8228 35.6283 36.8944 35.6278 36.9668L35.5919 42.6781C35.5904 42.9206 35.421 43.1304 35.1821 43.1859C35.1414 43.1954 35.1006 43.2001 35.0602 43.2001C34.8632 43.2001 34.6779 43.0915 34.5859 42.912L28.8491 31.7192H25.955V32.3605C25.955 33.2591 25.2146 33.9902 24.3046 33.9902H23.6985C22.7885 33.9902 22.0481 33.2591 22.0481 32.3605V31.7192H19.1541L13.4172 42.912C13.3252 43.0915 13.1399 43.2001 12.943 43.2001C12.9025 43.2001 12.8617 43.1954 12.821 43.1859C12.5821 43.1304 12.4127 42.9205 12.4112 42.6781L12.3753 36.9668C12.3748 36.8944 12.3895 36.8228 12.4184 36.7563L14.6094 31.7191H13.5889C13.3964 32.4083 12.7562 32.9162 11.9979 32.9162H11.252C10.3419 32.9162 9.60156 32.1851 9.60156 31.2865V29.1381C9.60156 28.2395 10.3419 27.5084 11.252 27.5084H11.9979C12.7563 27.5084 13.3964 28.0163 13.5889 28.7055H15.9202L19.8392 19.6957C19.259 18.88 18.9169 17.8878 18.9169 16.8175C18.9169 14.7322 20.2113 12.9404 22.048 12.1826V10.4923C21.4759 10.2361 21.0768 9.66715 21.0768 9.00753V6.42972C21.0768 5.53115 21.8172 4.80005 22.7272 4.80005H25.2756C26.1857 4.80005 26.9261 5.53115 26.926 6.42972V9.00746C26.926 9.66708 26.5269 10.236 25.9548 10.4922V12.1826C27.7916 12.9403 29.0859 14.7321 29.0859 16.8174C29.0859 17.8877 28.7439 18.88 28.1637 19.6957L32.0826 28.7054H34.414C34.6064 28.0161 35.2466 27.5083 36.005 27.5083ZM23.6984 26.4343H24.3044C25.2145 26.4343 25.9549 27.1654 25.9549 28.0641V28.7054H27.3043L24.0014 22.2614L20.6986 28.7054H22.0479V28.0641C22.0479 27.1654 22.7883 26.4343 23.6984 26.4343ZM26.4016 16.704C26.4016 18.0294 25.327 19.104 24.0016 19.104C22.6761 19.104 21.6016 18.0294 21.6016 16.704C21.6016 15.3785 22.6761 14.304 24.0016 14.304C25.327 14.304 26.4016 15.3785 26.4016 16.704Z"
        fill="#525252"
      />
    </svg>
  );
};

export default PoseCalibration;

import {IconButton} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {toggleLoadingPowerState} from '../actions/uiActions';
import {POWER_STATE} from '../enums';
import {
  clear_ros,
  flippy_power_ap,
  setRelayController,
  reset_ros,
} from '../ros_init';

import {FaAngleLeft} from 'react-icons/fa';
import PowerSettingsModal from '../modals/PowerSettingsModal';
import store from '../store';
import {callService} from '../util';
import {RESET_ALL} from '../actions/actionTypes';

var power_button_in_use = false;

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  toggleMenuButton: {
    width: '5rem',
    height: '3rem',
    borderRadius: '1.5rem',
    border: '0.125rem solid #808080',
  },
  containerStyle: {
    display: 'flex',
    flexDirection: 'column',
    width: '34.375rem',
    padding: '1rem 1rem 0.5rem',
    alignItems: 'center',
  },
  FSConfirmationModalTitle: {
    fontWeight: '900',
    marginBottom: '0.5rem',
    textTransform: 'capitalize',
  },
  FSConfirmationModalSubTitle: {
    fontWeight: '900',
    textTransform: 'uppercase',
    marginBottom: '-0.5rem',
  },

  btnStyle: {
    backgroundColor: '#629388',
    minWidth: '12.5rem',
    borderRadius: '1.5625rem',
    color: '#FFF',
    fontWeight: '900',
    '&:hover': {
      backgroundColor: '#629388',
    },
  },
  bottomPaneStyle: {
    gap: '3.125rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuTitleText: {
    fontWeight: 'bold',
    color: 'black',
    textAlign: 'center',
    width: '100%',
  },
  menuContainer: {
    border: '0.0625rem solid black',
  },
  menuListStyle: {
    borderTop: '0.0625rem solid black',
    backgroundColor: '#E2E2E2',
  },
  powerOn: {
    fontWeight: 'bold',
  },
  powerModalTitle: {
    fontWeight: '900',
    marginBottom: '0.5rem',
    color: '#6B6B6B',
    textAlign: 'center',
  },
  quickStartText: {
    textAlign: 'start',
    color: '#838383',
    fontSize: '1.1rem',
  },
  menuListTitle: {
    '&:hover, &:focus, &:active, &.Mui-selected': {
      backgroundColor: 'inherit',
    },
  },
});

class MenuListComposition extends React.Component {
  state = {
    open: false,
    isForceStopConfirmationOpen: false,
    isDispenser: false,
    isSoftware: false,
    isElectronics: false,
    isPLC: false,
    isController: false,
    isComputer: false,
    powerOn: false,
    flippyRestart: false,
    confirmRestart: false,
    quickStart: false,
  };

  componentDidUpdate() {
    if (
      this.props.instanceStates &&
      this.props.instanceStates.uiState &&
      this.props.loadingPowerState.text === 'On'
    ) {
      this.resetPowerLoadingState();
    }
  }

  handleMenuOpenToggle = () => {
    this.setState((state) => ({open: !state.open}));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({open: false, isElectronics: false, isSoftware: false});
  };

  resetPowerLoadingState = () => {
    store.dispatch(
      toggleLoadingPowerState({
        status: false,
        text: '',
      })
    );
  };

  handleFlippyPowerToggle = async (
    event,
    powerRequest,
    errorMsg,
    successMsg
  ) => {
    if (!power_button_in_use) {
      power_button_in_use = true;
      this.handleClose(event);
      store.dispatch(
        toggleLoadingPowerState({
          status: true,
          text:
            powerRequest === 'start_flippy_without_tests'
              ? POWER_STATE.POWERING_ON
              : powerRequest === 'start_flippy_with_tests'
                ? POWER_STATE.RUNNING_BIT_FOR
                : POWER_STATE.POWERING_OFF,
        })
      );
      clear_ros();
      await flippy_power_ap
        .requestPowerFlippy(powerRequest)
        .then(() => {
          {
            console.log(successMsg);
          }
        })
        .catch((error) => {
          console.log(errorMsg + ` Error: ${error}`);
          setTimeout(() => {
            let failure_hint;
            if (error.includes('command')) {
              failure_hint =
                'You might want to wait or coordinate with other RSAs.';
            } else {
              if (
                powerRequest === 'start_flippy_without_tests' ||
                powerRequest === 'start_flippy_with_tests'
              ) {
                if (
                  error.includes('timeout') ||
                  error.includes('ROS Master') ||
                  error.includes('timed out')
                ) {
                  failure_hint =
                    'You might want to "force shutdown" first before powering on.';
                }
              }
            }

            window.alert(
              `Request to ${powerRequest} failed. Error: ${error}\n` +
                `hint: ` +
                (failure_hint || 'Try again or contact Admin.')
            );
          }, 1);
        });
      reset_ros(this.props.instance);
      this.resetPowerLoadingState();

      power_button_in_use = false;
    } else {
      this.handleClose(event);
    }
    if (powerRequest === 'kill_flippy' || powerRequest === 'stop_flippy') {
      store.dispatch({
        type: RESET_ALL,
        host: this.props.instance,
      });
      if (powerRequest === 'kill_flippy') {
        this.handleForceStopConfirmation();
      }
    }
  };

  handleStart = async (event) => {
    const errorMsg = `Power on request failed.`;
    const successMsg = `Machine powered on.`;
    this.handleFlippyPowerToggle(
      event,
      'start_flippy_without_tests',
      errorMsg,
      successMsg
    );
  };

  handleStartWithBIT = async (event) => {
    const errorMsg = `Power on with BIT request failed.`;
    const successMsg = `Machine powered on.`;
    this.handleFlippyPowerToggle(
      event,
      'start_flippy_with_tests',
      errorMsg,
      successMsg
    );
  };

  handleStop = async (event) => {
    const errorMsg = `Shutdown request failed.`;
    const successMsg = `Machine powered off.`;
    this.handleFlippyPowerToggle(event, 'stop_flippy', errorMsg, successMsg);
  };
  handleForceStopConfirmation = () => {
    this.setState({
      isForceStopConfirmationOpen: !this.state.isForceStopConfirmationOpen,
    });
  };
  handleForceStop = async (event) => {
    const errorMsg = `Force shutdown request failed`;
    const successMsg = `Machine powered off`;
    this.handleFlippyPowerToggle(event, 'kill_flippy', errorMsg, successMsg);
    this.setState({isForceStopConfirmationOpen: false});
  };

  handleSettings = () => {
    this.setState({
      isDispenser: !this.state.isDispenser,
      open: false,
    });
  };

  handleFlippySoftware = () => {
    this.setState({
      isSoftware: !this.state.isSoftware,
    });
  };

  handleFlippyElectronics = () => {
    this.setState({
      isElectronics: !this.state.isElectronics,
    });
  };

  toggleRelay = async (relayName, toggle, restart) => {
    let request = {name: relayName, command: toggle};
    let requestRestart = {name: relayName, command: 'on'};

    // Initial toggle call
    await callService(
      setRelayController,
      this.props.instance,
      request,
      `Toggle ${relayName} Relay`
    );

    // If restart is true, perform the restart after a 15-second delay
    if (restart) {
      setTimeout(async () => {
        await callService(
          setRelayController,
          this.props.instance,
          requestRestart,
          `Restart ${relayName} Relay`
        );
      }, 15000); // Delay set to 15 seconds
    }
  };

  handleRelayPowerOff = (relayName, relayState) => {
    this.toggleRelay(relayName, relayState ? 'off' : 'on', false);
    this.setState({
      isDispenser:
        relayName === 'dispenser'
          ? !this.state.isDispenser
          : this.state.isDispenser,
      isPLC: relayName === 'plc' ? !this.state.isPLC : this.state.isPLC,
      isController:
        relayName === 'yaskawa'
          ? !this.state.isController
          : this.state.isController,
      open: false,
    });
  };

  handleConfirmRestartFlippy = () => {
    this.setState({
      confirmRestart: true,
    });
  };

  handleRestartRelay = (relayName) => {
    this.toggleRelay(relayName, 'off', true);
    this.setState({
      isDispenser: relayName === 'dispenser' ? false : this.state.isDispenser,
      isPLC: relayName === 'plc' ? false : this.state.isPLC,
      isController: relayName === 'yaskawa' ? false : this.state.isController,
      isElectronics:
        relayName === 'plc' || relayName === 'yaskawa'
          ? false
          : this.state.isElectronics,
      confirmRestart: false,
    });
  };

  handleRestartComputer = (relayName) => {
    this.toggleRelay(relayName, 'trigger', false);
    this.setState({
      isComputer: relayName === 'compute_node' ? false : this.state.isComputer,
      flippyRestart:
        relayName === 'machine_reset' ? false : this.state.flippyRestart,
      isElectronics:
        relayName === 'compute_node' || relayName === 'machine_reset'
          ? false
          : this.state.isElectronics,
      confirmRestart: false,
      open: false,
    });
  };

  handlePowerOn = () => {
    this.setState({
      powerOn: !this.state.powerOn,
    });
  };

  handlePLC = () => {
    this.setState({
      isPLC: !this.state.isPLC,
      open: false,
    });
  };

  handleController = () => {
    this.setState({
      isController: !this.state.isController,
      open: false,
    });
  };

  handleComputer = () => {
    this.setState({
      isComputer: !this.state.isComputer,
      open: false,
    });
  };

  handleFlippyRestart = () => {
    this.setState({
      flippyRestart: !this.state.flippyRestart,
      open: false,
    });
  };

  render() {
    const {classes, component, instanceStates, relayState} = this.props;
    const {
      open,
      isSoftware,
      isElectronics,
      isDispenser,
      confirmRestart,
      isForceStopConfirmationOpen,
      powerOn,
      isPLC,
      isController,
      isComputer,
      flippyRestart,
    } = this.state;

    const isFlippyOn = instanceStates && instanceStates.isFlippyOn;
    let dispenserRelayState;
    let plcRelayState;
    let controllerRelayState;

    if (isFlippyOn && relayState !== undefined && relayState.length > 0) {
      dispenserRelayState =
        relayState.find((item) => item.name === 'dispenser').is_closed || false;
      plcRelayState =
        relayState.find((item) => item.name === 'plc').is_closed || false;
      controllerRelayState =
        relayState.find((item) => item.name === 'yaskawa').is_closed || false;
    }

    return (
      <div className={classes.root}>
        <div>
          <IconButton
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={this.handleMenuOpenToggle}
            className={classes.toggleMenuButton}
          >
            {component}
          </IconButton>
          <Popper
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            style={{zIndex: '9999'}}
          >
            {({TransitionProps, placement}) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper style={{width: '18rem'}}>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList
                      className={classes.menuContainer}
                      disablePadding={true}
                    >
                      {isSoftware ? (
                        <div>
                          <MenuItem
                            className={classes.menuListTitle}
                            disableRipple
                          >
                            <IconButton
                              aria-label="AngleLeft"
                              onClick={this.handleFlippySoftware}
                              data-tip="Angle Left"
                            >
                              <FaAngleLeft color="black" />
                            </IconButton>
                            <p className={classes.menuTitleText}>
                              {'Software'}
                            </p>
                          </MenuItem>
                          <MenuItem
                            onClick={this.handleStop}
                            className={classes.menuListStyle}
                          >
                            {'Safe Shutdown'}
                          </MenuItem>
                          <MenuItem
                            onClick={this.handleForceStopConfirmation}
                            className={classes.menuListStyle}
                          >
                            {'Force Shutdown'}
                          </MenuItem>
                        </div>
                      ) : isElectronics ? (
                        <div>
                          <MenuItem className={classes.menuListTitle}>
                            <IconButton
                              aria-label="AngleLeft"
                              onClick={this.handleFlippyElectronics}
                              data-tip="Angle Left"
                            >
                              <FaAngleLeft color="black" />
                            </IconButton>
                            <p className={classes.menuTitleText}>
                              {"Flippy's Electronics"}
                            </p>
                          </MenuItem>
                          <MenuItem
                            className={classes.menuListStyle}
                            onClick={this.handlePLC}
                          >
                            {'PLC'}
                          </MenuItem>
                          <MenuItem
                            className={classes.menuListStyle}
                            onClick={this.handleController}
                          >
                            {'Controller'}
                          </MenuItem>
                          <MenuItem
                            className={classes.menuListStyle}
                            onClick={this.handleComputer}
                          >
                            {'Computer'}
                          </MenuItem>
                        </div>
                      ) : (
                        <div>
                          <MenuItem
                            className={classes.menuListTitle}
                            disableRipple
                          >
                            <p className={classes.menuTitleText}>
                              {'Power Menu'}
                            </p>
                          </MenuItem>
                          {isFlippyOn && (
                            <MenuItem
                              className={classes.menuListStyle}
                              onClick={this.handleFlippyElectronics}
                            >
                              {"Flippy's Electronics"}
                            </MenuItem>
                          )}
                          <MenuItem
                            className={classes.menuListStyle}
                            onClick={
                              isFlippyOn
                                ? this.handleFlippySoftware
                                : this.handlePowerOn
                            }
                          >
                            {isFlippyOn ? (
                              "Flippy's Software"
                            ) : (
                              <p className={classes.powerOn}>
                                Power On Software
                              </p>
                            )}
                          </MenuItem>
                          {isFlippyOn && (
                            <>
                              <MenuItem
                                className={classes.menuListStyle}
                                onClick={this.handleSettings}
                              >
                                {'Dispenser'}
                              </MenuItem>
                              <MenuItem
                                className={classes.menuListStyle}
                                onClick={this.handleFlippyRestart}
                              >
                                {'Flippy'}
                              </MenuItem>
                            </>
                          )}
                        </div>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        {isDispenser && (
          <div>
            <PowerSettingsModal
              open={isDispenser}
              title={
                confirmRestart
                  ? "Are you sure you want to RESTART Flippy's Dispenser?"
                  : "Flippy's Dispenser"
              }
              textLeft={confirmRestart ? 'Continue' : 'Restart'}
              textRight={
                confirmRestart
                  ? ''
                  : dispenserRelayState
                    ? 'Power Off'
                    : 'Power On'
              }
              onClickLeft={() =>
                confirmRestart
                  ? this.handleRestartRelay('dispenser')
                  : this.handleConfirmRestartFlippy()
              }
              onClickRight={() =>
                this.handleRelayPowerOff('dispenser', dispenserRelayState)
              }
              onClose={() =>
                this.setState({isDispenser: false, confirmRestart: false})
              }
            />
          </div>
        )}
        {isForceStopConfirmationOpen && (
          <div>
            <PowerSettingsModal
              open={isForceStopConfirmationOpen}
              title={'Are you sure you want to Force Shutdown Software?'}
              content={
                'Flippy will stop cooking and all food in the queue will be removed.'
              }
              textLeft={'Continue'}
              onClickLeft={this.handleForceStop}
              onClose={() =>
                this.setState({isForceStopConfirmationOpen: false})
              }
            />
          </div>
        )}
        {!isFlippyOn && powerOn && (
          <div>
            <PowerSettingsModal
              open={powerOn}
              title={'Startup Software'}
              content={
                <p className={classes.quickStartText}>
                  Quick Start?{' '}
                  <i>
                    (This will skip Built in Test on Startup and expidite the
                    startup process, only select if short on time)
                  </i>
                </p>
              }
              textLeft={'Continue'}
              onQuickStart={() =>
                this.setState({quickStart: !this.state.quickStart})
              }
              onClickLeft={
                this.state.quickStart
                  ? this.handleStart
                  : this.handleStartWithBIT
              }
              onClose={() => this.setState({powerOn: false})}
            />
          </div>
        )}
        {isPLC && (
          <div>
            <PowerSettingsModal
              open={isPLC}
              title={
                confirmRestart
                  ? "Are you sure you want to RESTART Flippy's PLC?"
                  : "Flippy's PLC"
              }
              content={
                confirmRestart
                  ? 'Flippy will stop cooking and all food in the queue will be removed.'
                  : ''
              }
              textLeft={confirmRestart ? 'Continue' : 'Restart'}
              textRight={
                confirmRestart ? '' : plcRelayState ? 'Power Off' : 'Power On'
              }
              onClickLeft={() =>
                confirmRestart
                  ? this.handleRestartRelay('plc')
                  : this.handleConfirmRestartFlippy()
              }
              onClickRight={() =>
                this.handleRelayPowerOff('plc', plcRelayState)
              }
              onClose={() =>
                this.setState({isPLC: false, confirmRestart: false})
              }
            />
          </div>
        )}
        {isController && (
          <div>
            <PowerSettingsModal
              open={isController}
              title={
                confirmRestart
                  ? "Are you sure you want to RESTART Flippy's Robot Controller?"
                  : "Flippy's Robot Controller"
              }
              content={
                confirmRestart
                  ? 'Flippy will stop cooking and all food in the queue will be removed.'
                  : ''
              }
              textLeft={confirmRestart ? 'Continue' : 'Restart'}
              textRight={
                confirmRestart
                  ? ''
                  : controllerRelayState
                    ? 'Power Off'
                    : 'Power On'
              }
              onClickLeft={() =>
                confirmRestart
                  ? this.handleRestartRelay('yaskawa')
                  : this.handleConfirmRestartFlippy()
              }
              onClickRight={() =>
                this.handleRelayPowerOff('yaskawa', controllerRelayState)
              }
              onClose={() =>
                this.setState({
                  isController: false,
                  confirmRestart: false,
                  isElectronics: false,
                })
              }
            />
          </div>
        )}
        {isComputer && (
          <div>
            <PowerSettingsModal
              open={isComputer}
              title={
                confirmRestart
                  ? "Are you sure you want to RESTART Flippy's Computer?"
                  : "Flippy's Computer"
              }
              content={
                confirmRestart
                  ? 'Flippy will stop cooking and all food in the queue will be removed.'
                  : ''
              }
              textLeft={confirmRestart ? 'Continue' : 'Restart'}
              onClickLeft={() =>
                confirmRestart
                  ? this.handleRestartComputer('compute_node')
                  : this.handleConfirmRestartFlippy()
              }
              onClose={() =>
                this.setState({
                  isComputer: false,
                  confirmRestart: false,
                  isElectronics: false,
                })
              }
            />
          </div>
        )}
        {flippyRestart && (
          <div>
            <PowerSettingsModal
              open={flippyRestart}
              title={'Are you sure you want to RESTART Flippy?'}
              content={
                'Flippy will stop cooking and all food in the queue will be removed.'
              }
              textLeft={'Continue'}
              onClickLeft={() => this.handleRestartComputer('machine_reset')}
              onClose={() =>
                this.setState({
                  flippyRestart: false,
                  isElectronics: false,
                })
              }
            />
          </div>
        )}
      </div>
    );
  }
}

MenuListComposition.propTypes = {
  classes: PropTypes.object.isRequired,
  instanceStates: PropTypes.object.isRequired,
  component: PropTypes.element,
  instance: PropTypes.string,
  loadingPowerState: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    loadingPowerState: state.ui.loadingPowerState,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(MenuListComposition)
);

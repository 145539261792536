import React from 'react';

const GripHorizontal = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5614 12.9718H7.92141C7.65631 12.9718 7.44141 13.1867 7.44141 13.4518V22.0918C7.44141 22.3569 7.65631 22.5718 7.92141 22.5718H16.5614C16.8265 22.5718 17.0414 22.3569 17.0414 22.0918V13.4518C17.0414 13.1867 16.8265 12.9718 16.5614 12.9718Z"
        fill="#525252"
      />
      <path
        d="M40.5614 12.9718H31.9214C31.6563 12.9718 31.4414 13.1867 31.4414 13.4518V22.0918C31.4414 22.3569 31.6563 22.5718 31.9214 22.5718H40.5614C40.8265 22.5718 41.0414 22.3569 41.0414 22.0918V13.4518C41.0414 13.1867 40.8265 12.9718 40.5614 12.9718Z"
        fill="#525252"
      />
      <path
        d="M28.5575 12.9718H19.9175C19.6524 12.9718 19.4375 13.1867 19.4375 13.4518V22.0918C19.4375 22.3569 19.6524 22.5718 19.9175 22.5718H28.5575C28.8226 22.5718 29.0375 22.3569 29.0375 22.0918V13.4518C29.0375 13.1867 28.8226 12.9718 28.5575 12.9718Z"
        fill="#525252"
      />
      <path
        d="M16.5614 24.9717H7.92141C7.65631 24.9717 7.44141 25.1866 7.44141 25.4517V34.0917C7.44141 34.3568 7.65631 34.5717 7.92141 34.5717H16.5614C16.8265 34.5717 17.0414 34.3568 17.0414 34.0917V25.4517C17.0414 25.1866 16.8265 24.9717 16.5614 24.9717Z"
        fill="#525252"
      />
      <path
        d="M28.5575 24.9717H19.9175C19.6524 24.9717 19.4375 25.1866 19.4375 25.4517V34.0917C19.4375 34.3568 19.6524 34.5717 19.9175 34.5717H28.5575C28.8226 34.5717 29.0375 34.3568 29.0375 34.0917V25.4517C29.0375 25.1866 28.8226 24.9717 28.5575 24.9717Z"
        fill="#525252"
      />
      <path
        d="M31.4414 25.4518V34.0918C31.4414 34.3569 31.6563 34.5718 31.9214 34.5718H40.5614C40.8265 34.5718 41.0414 34.3569 41.0414 34.0918V25.4518C41.0414 25.1867 40.8265 24.9718 40.5614 24.9718H31.9214C31.6563 24.9718 31.4414 25.1867 31.4414 25.4518Z"
        fill="#525252"
      />
    </svg>
  );
};

export default GripHorizontal;

import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import 'react-dropdown/style.css';
import ReactTooltip from 'react-tooltip';

import Typography from '@material-ui/core/Typography';

import Switch from '@material-ui/core/Switch';
import OccupiedSlot from '../components/OccupiedSlot';
import {DISABLING_LEVEL, SLOT_TYPES} from '../enums';
import SlotStatusReason from '../modals/SlotStatusReason';
import {
  updateTicket,
  mqtt_ap,
  disableSlot,
  // USER_ID,
} from '../ros_init';
import {convertTimestamp} from '../util';
import {borderButtonPadding, colors, mediumTextSize} from '../styles';
import MisoDialogTitle from './MisoDialogTitle';
import {Dialog} from '@material-ui/core';

const darkTheme = (theme) =>
  createMuiTheme({
    ...theme,
    palette: {
      type: 'dark',
    },
  });

const styles = (theme) => ({
  topButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    zIndex: 3,
    position: 'relative',
  },
  slotOuter: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minHeight: '10vh',
    marginLeft: '1%',
    borderRadius: '3pt',
    borderColor: colors.pinkishGrey,
    borderWidth: '0.125rem',
    borderStyle: 'solid',
  },
  adjHeight: {
    minHeight: '9.375rem',
  },
  basketIdContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  actualBasket: {
    float: 'right',
    fontWeight: '900',
    visibility: 'visible',
  },
  feedback: {
    float: 'left',
    fontWeight: '900',
    visibility: 'visible',
  },
  emptyOuter: {
    backgroundColor: theme.palette.grey[300],
  },
  occupiedOuter: {
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[50],
  },
  disabledOuter: {
    backgroundColor: theme.palette.grey[800],
  },
  topLeftButton: {
    alignItems: 'flex-start',
    paddingLeft: borderButtonPadding,
    paddingTop: borderButtonPadding,
  },
  topRight: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.common.white,
  },
  disabledSlotDiv: {
    backgroundColor: 'grey',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: '1rem',
    textAlign: 'center',
    zIndex: 999,
    position: 'absolute',
    width: '210.25%',
    height: '201.7%',
    opacity: '0.95',
  },
  disabledSlotText: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '2.5rem',
    color: theme.palette.common.white,
    textAlign: 'center',
  },
});

const BasketIdTypography = withStyles({
  root: {
    paddingLeft: '0.2rem',
    paddingTop: '0.3rem',
    fontSize: '0.8125rem',
    fontWeight: 600,
    textAlign: 'center',
  },
})(Typography);

const EmptySlot = withStyles((theme) => ({
  emptySlot: {
    textSize: mediumTextSize,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
}))((props) => {
  return (
    <div className={props.classes.emptySlot}>
      <Typography
        className={props.classes.centerText}
        variant="h6"
        color="textPrimary"
      >
        Empty
      </Typography>
    </div>
  );
});

// Reasons for disabling / enabling slots
const autobasketDisableReasons = [
  'Hardware Damage',
  'Collision',
  'Instructed by T2 / Dev Team to Disable',
  'Cleaning',
  'Misgrab',
  'Vision Failure',
  'Planning Failure',
  'Other',
];

const autobasketEnableReasons = [
  'Requested by customer after cleaning',
  'Broken Autobasket slot fixed',
  'Instructed to Tier 2 / T3 to enable',
  'Other',
];

// Reasons for disabling / enabling slots
const shelfDisableReasons = [
  'Shelf Missing / Not There',
  'Localization Hardware Damage',
  'Misgrabs: Flippy is not picking up the Basket',
  'Vision Failure',
  'Localization Hardware Out of Place',
  'Collision',
  'Instructed by T2 / T3 to Disable',
  'Slot Tracking Shelf / Fryer Mismatch',
  'Tuning Slot',
  'Clean Marker',
  'Other',
];

const shelfEnableReasons = [
  'Localization Resolved',
  'Marker visibility restored',
  'Slot tuned',
  'Localization Hardware adjusted',
  'Instructed by Tier 2 / T3 to enable',
  'Shelf cleaned',
  'Other',
];

// Reasons for disabling / enabling slots
const fryerDisableReasons = [
  'Localization Hardware Damage',
  'Misgrabs: Flippy is not picking up the Basket',
  'Vision Failure',
  'Localization Hardware Out of Place',
  'Collision',
  'Instructed by T2 / T3 to Disable',
  'Slot Matching Failure',
  'Tuning Slot',
  'Clean Marker',
  'Other',
];

const fryerEnableReasons = [
  'Localization Resolved',
  'Marker visibility restored',
  'Slot tuned',
  'Localization Hardware adjusted',
  'Instructed by Tier 2 / T3 to enable',
  'Fryer cleaned',
  'Other',
];

class SlotCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      basketHistoryOpen: false,
      isSlotReasonModalOpen: false,
      selectedReason: '',
    };
    this.onSelectFood = this.onSelectFood.bind(this);
    this.getCurrentFoodOption = this.getCurrentFoodOption.bind(this);
    this.handleToggleReasonSelection =
      this.handleToggleReasonSelection.bind(this);
    this.handleSlotToggle = this.handleSlotToggle.bind(this);
  }

  handleToggle = () => {
    this.setState((state) => ({
      ...this.state,
      basketHistoryOpen: state.basketHistoryOpen ? false : true,
    }));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({...this.state, basketHistoryOpen: false});
  };

  onComponentDidMount = () => {
    this.setState(this.initialState);
  };

  handleToggleReasonSelection(isOpen) {
    const {isSlotReasonModalOpen} = this.state;
    this.setState({
      isSlotReasonModalOpen: isOpen !== true ? !isSlotReasonModalOpen : isOpen,
    });
  }

  onSelectFood = async (event, instance, slotUuid) => {
    const foodName = event.target.value;
    console.log(`instance`, instance);
    console.log(`updateTicket[instance]`, updateTicket[instance]);
    updateTicket[instance].setData = {
      ticket_id: {uuid: slotUuid},
      update_food_type: true,
      food_type: foodName,
    };
    await mqtt_ap.submit_request(updateTicket[instance]).catch((error) => {
      console.log('Submit_Request error: ' + error);
    });
  };

  getCurrentFoodOption() {
    const {slotObj} = this.props;
    const {ticket} = slotObj;

    return {
      value: ticket[0].food_type,
    };
  }

  async modifySlotHelper(service, verb, is_disabled, slotPath, reason) {
    const {instance, user} = this.props;
    service[instance].setData = {
      user: {permissions: DISABLING_LEVEL.SUPPORT, name: user},
      component_path: slotPath,
      disabled: is_disabled,
      reason: reason,
    };
    mqtt_ap.submit_request(service[instance]).catch((error) => {
      console.log('Submit_Request error: ' + error);
    });
  }

  getEnableSlotFunc = (reason) => {
    const {slotObj} = this.props;
    const slotPath = slotObj.slot_path;
    return this.modifySlotHelper.bind(
      this,
      disableSlot,
      'Enabled',
      false,
      slotPath,
      reason
    );
  };
  getDisableSlotFunc = (reason) => {
    const {slotObj} = this.props;
    const slotPath = slotObj.slot_path;
    return this.modifySlotHelper.bind(
      this,
      disableSlot,
      'Disabled',
      true,
      slotPath,
      reason
    );
  };

  handleSlotToggle(reason) {
    const {slotObj} = this.props;
    const {disabled} = slotObj;

    const func = disabled
      ? this.getEnableSlotFunc(reason)
      : this.getDisableSlotFunc(reason);
    func();
  }

  onClearSlot = async (instance, slotUuid) => {
    updateTicket[instance].setData = {
      ticket_id: {uuid: slotUuid},
      cancel_ticket: true,
    };
    await mqtt_ap
      .submit_request(updateTicket[instance], undefined, true)
      .catch((error) => {
        console.log('Submit_Request error: ' + error);
      });
  };

  render() {
    const {
      classes,
      slotType,
      slotObj,
      width,
      multiLocation,
      foodMenu,
      rosHeaderTimeStamp,
      instance,
      index,
      fryersState,
    } = this.props;

    const {basket_id, disabled, ticket} = slotObj;

    let outerStyle = {width, minWidth: width, maxWidth: width};
    outerStyle = multiLocation
      ? {...outerStyle, borderColor: colors.azure}
      : outerStyle;

    let slotOuterClassName;
    let content = null;
    if (ticket.length !== 0) {
      slotOuterClassName = classes.occupiedOuter;
      content = (
        <OccupiedSlot
          basketID={basket_id}
          onFreeSlotClick={() => {
            this.onClearSlot(instance, ticket[0].uuid.uuid);
          }}
          onSelectFood={(event) => {
            this.onSelectFood(event, instance, ticket[0].uuid.uuid);
          }}
          currentFoodOption={this.getCurrentFoodOption()}
          slotType={slotType}
          foodMenu={foodMenu}
          rosHeaderTimeStamp={rosHeaderTimeStamp}
          ticket={ticket[0]}
          instance={instance}
        />
      );
    } else {
      slotOuterClassName = classes.emptyOuter;
      content = <EmptySlot />;
    }
    if (disabled) {
      slotOuterClassName = classes.disabledOuter;
      content = (
        <MuiThemeProvider theme={darkTheme}>{content}</MuiThemeProvider>
      );
    }

    let fryerState = {};
    if (index % 2 === 0 && fryersState) {
      fryerState = fryersState[Math.ceil(Number((index + 1) / 2)) - 1];
    }
    return (
      <div
        className={classNames(
          classes.slotOuter,
          slotOuterClassName,
          slotType === SLOT_TYPES.INTERFACE && classes.adjHeight
        )}
        style={outerStyle}
      >
        {index % 2 === 0 &&
          slotType === SLOT_TYPES.FRYER &&
          fryerState &&
          fryerState.disabled &&
          fryerState.disable_reason === 'cleaning' && (
            <div className={classes.disabledSlotDiv}>
              <Typography className={classes.disabledSlotText}>
                Disabled for cleaning
              </Typography>
            </div>
          )}
        <div className={classes.topButtons}>
          <div className={classes.topRight}>
            {disabled && (
              <Typography
                variant="caption"
                color="inherit"
                style={{
                  paddingLeft: '0.5rem',
                  fontSize: '0.75rem',
                  fontWeight: 900,
                }}
              >
                Disabled
                <span className="bold">
                  {slotObj.disable_permissions === DISABLING_LEVEL.SUPPORT
                    ? ' By Support'
                    : slotObj.disable_permissions === DISABLING_LEVEL.UI_USER
                      ? ' By Customer'
                      : slotObj.disable_permissions === DISABLING_LEVEL.FLIPPY
                        ? ' By Flippy'
                        : ''}
                </span>
              </Typography>
            )}
            <Switch
              checked={!slotObj.disabled}
              color="primary"
              data-tip="Enable/Disable Slot"
              onClick={
                slotObj.disabled !== undefined
                  ? this.handleToggleReasonSelection
                  : this.handleSlotToggle
              }
            />
          </div>
        </div>
        {slotObj.length !== 0 && (
          <div className={classes.basketIdContainer}>
            {slotObj.disable_reason && (
              <div className={classes.feedback}>
                <BasketIdTypography
                  style={{color: slotObj.disabled ? 'white' : undefined}}
                >
                  Feedback Timestamp:{' '}
                  {' ' + convertTimestamp(slotObj.disable_time)}
                </BasketIdTypography>
                <BasketIdTypography
                  style={{color: slotObj.disabled && 'white'}}
                >
                  Feedback:{' ' + slotObj.disable_reason}
                </BasketIdTypography>
                <BasketIdTypography
                  style={{color: slotObj.disabled && 'white'}}
                >
                  User:{' ' + slotObj.disable_user}
                </BasketIdTypography>
              </div>
            )}
          </div>
        )}
        {basket_id !== 0 && (
          <div className={classes.actualBasket}>
            <BasketIdTypography style={{color: slotObj.disabled && 'white'}}>
              Actual Basket Id: {basket_id}
            </BasketIdTypography>
          </div>
        )}
        {content}
        <ReactTooltip />
        <Dialog
          open={this.state.isSlotReasonModalOpen}
          onClose={() => this.handleToggleReasonSelection(false)}
          PaperProps={{
            style: {
              width: '40.625rem',
              height: '80%',
              maxWidth: 'initial',
            },
          }}
        >
          <SlotStatusReason
            key={`statusReasonModal-${basket_id}`}
            slotObj={slotObj}
            handleModalVisibility={this.handleToggleReasonSelection}
            handleSlotToggle={this.handleSlotToggle}
            enableReasons={
              slotType === SLOT_TYPES.INTERFACE
                ? autobasketEnableReasons
                : slotType === SLOT_TYPES.SHELF
                  ? shelfEnableReasons
                  : fryerEnableReasons
            }
            disableReasons={
              slotType === SLOT_TYPES.INTERFACE
                ? autobasketDisableReasons
                : slotType === SLOT_TYPES.SHELF
                  ? shelfDisableReasons
                  : fryerDisableReasons
            }
            inSingleStepModal
          />
        </Dialog>
      </div>
    );
  }
}

SlotCard.propTypes = {
  classes: PropTypes.object.isRequired,
  instance: PropTypes.string.isRequired,
  slotType: PropTypes.number.isRequired,
  slotObj: PropTypes.shape({
    basket_id: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    foodType: PropTypes.string.isRequired,
    slot_path: PropTypes.string.isRequired,
    ticket: PropTypes.array.isRequired,
  }).isRequired,
  multiLocation: PropTypes.bool,
  width: PropTypes.string,
  foodMenu: PropTypes.arrayOf(PropTypes.object),
};

export default withStyles(styles)(SlotCard);

import {
  FaExclamationTriangle,
  FaRegCheckCircle,
  FaRegTimesCircle,
  FaQuestion,
} from 'react-icons/fa';
import {
  redErrorStyle,
  greyErrorStyle,
  greenCheckStyle,
  greyQuestionStyle,
  yellowCautionStyle,
} from '../styles';

import {FiWifiOff} from 'react-icons/fi';

const REALTIME_PROPS = ['robot_alarm_status', 'safety_sensor_status'];
const STATUS_PROP_FILTERED = [
  'deviceid',
  'site_id',
  'measure_name',
  'time',
  'num_alarms',
];

const INSTANCE_PROPERTY = [
  'host',
  'name',
  'company',
  'site_id',
  'deviceid',
  'serial',
];

const SAFETY_SENSOR_STATUSES = [
  'num_alarms',
  'collision',
  'general',
  'motion_hold',
  'communication',
  'estopped',
  'unknown',
];

const GREEN_ALERT = {
  Icon: FaRegCheckCircle,
  alertStyle: {
    border: 'solid',
    borderSize: '0.1875rem',
    borderColor: '#b4d290',
    backgroundColor: '#e7f1db',
  },
  severityStatus: 0,
};
const YELLOW_ALERT = {
  Icon: FaExclamationTriangle,
  alertStyle: {
    border: 'solid',
    borderSize: '0.1875rem',
    borderColor: '#f4ec62',
    backgroundColor: '#faf8cf',
  },
  severityStatus: 2,
};
const RED_ALERT = {
  Icon: FaRegTimesCircle,
  alertStyle: {
    border: 'solid',
    borderSize: '0.1875rem',
    borderColor: '#f46c8f',
    backgroundColor: '#f9d4de',
  },
  severityStatus: 3,
};

const GRAY_ALERT = {
  Icon: FiWifiOff,
  alertStyle: {
    border: 'solid',
    borderSize: '0.1875rem',
    borderColor: '#aaafba',
    backgroundColor: '#e7e9ec',
  },
  severityStatus: 1,
};

const GreenCheckLegend = {
  Icon: FaRegCheckCircle,
  legendStyle: {
    ...greenCheckStyle,
  },
};

const YellowCautionLegend = {
  Icon: FaExclamationTriangle,
  legendStyle: {
    ...yellowCautionStyle,
  },
};

const RedErrorLegend = {
  Icon: FaRegTimesCircle,
  legendStyle: {
    ...redErrorStyle,
  },
};

const GrayErrorLegend = {
  Icon: FaRegTimesCircle,
  legendStyle: {
    ...greyErrorStyle,
  },
};

const GrayQuestionLegend = {
  Icon: FaQuestion,
  legendStyle: {
    ...greyQuestionStyle,
  },
};

export {
  REALTIME_PROPS,
  STATUS_PROP_FILTERED,
  SAFETY_SENSOR_STATUSES,
  INSTANCE_PROPERTY,
  GRAY_ALERT,
  GREEN_ALERT,
  YELLOW_ALERT,
  RED_ALERT,
  GreenCheckLegend,
  YellowCautionLegend,
  RedErrorLegend,
  GrayErrorLegend,
  GrayQuestionLegend,
};

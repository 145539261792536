import React, {memo} from 'react';
import {connect} from 'react-redux';
import Spinner from 'react-spinkit';

import {smartCompare} from '../../util';

import styles from './styles';

class Loading extends React.PureComponent {
  render() {
    const {classes, status} = this.props;

    if (!status) return null;

    return (
      <div className={classes.loadingPane}>
        <Spinner
          data-tip="Loading"
          name="circle"
          fadeIn="none"
          style={{
            width: '7.5em',
            height: '7.5em',
            marginTop: '5em',
          }}
        />
      </div>
    );
  }
}
const mapState = ({instances}) => ({
  instances,
});
const mapDispatch = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const Controller = memo(Loading, smartCompare(['status']));
export default connect(mapState, mapDispatch)(styles(Controller));

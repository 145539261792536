export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const SHOW_SUPPORT_MODAL = 'SHOW_SUPPORT_MODAL';
export const HIDE_SUPPORT_MODAL = 'HIDE_SUPPORT_MODAL';

export const SHOW_ADMIN_MODAL = 'SHOW_ADMIN_MODAL';
export const HIDE_ADMIN_MODAL = 'HIDE_ADMIN_MODAL';

export const SHOW_CHANGE_COOKING_TIME = 'SHOW_CHANGE_COOKING_TIME';
export const HIDE_CHANGE_COOKING_TIME = 'HIDE_CHANGE_COOKING_TIME';

export const SHOW_L2ALERT_MODAL = 'SHOW_L2ALERT_MODAL';
export const HIDE_L2ALERT_MODAL = 'HIDE_L2ALERT_MODAL';

export const SET_ERROR_SNACKBAR = 'SET_ERROR_SNACKBAR';

export const SET_IDLE_UI = 'SET_IDLE_UI';

export const SHOW_JOG_MODAL = 'SHOW_JOG_MODAL';
export const HIDE_JOG_MODAL = 'HIDE_JOG_MODAL';

export const SHOW_RESOLVE_COLLISION_MODAL = 'SHOW_RESOLVE_COLLISION_MODAL';
export const HIDE_RESOLVE_COLLISION_MODAL = 'HIDE_RESOLVE_COLLISION_MODAL';

export const TOGGLE_LOADING_POWER_STATE = 'TOGGLE_LOADING_POWER_STATE';

const showLoader = () => ({
  type: SHOW_LOADER,
});

const hideLoader = () => ({
  type: HIDE_LOADER,
});

const showSupportModal = () => ({
  type: SHOW_SUPPORT_MODAL,
});

const hideSupportModal = () => ({
  type: HIDE_SUPPORT_MODAL,
});

const showAdminModal = () => ({
  type: SHOW_ADMIN_MODAL,
});

const hideAdminModal = () => ({
  type: HIDE_ADMIN_MODAL,
});

const showChangeCookingTimeModal = () => ({
  type: SHOW_CHANGE_COOKING_TIME,
});

const hideChangeCookingTimeModal = () => ({
  type: HIDE_CHANGE_COOKING_TIME,
});

const showL2AlertModal = () => ({
  type: SHOW_L2ALERT_MODAL,
});

const hideL2AlertModal = () => ({
  type: HIDE_L2ALERT_MODAL,
});

const setError = (error) => ({
  type: SET_ERROR_SNACKBAR,
  payload: error,
});

const setIdle = (data) => ({
  type: SET_IDLE_UI,
  payload: data,
});

const showJogModal = () => ({
  type: SHOW_JOG_MODAL,
});

const hideJogModal = () => ({
  type: HIDE_JOG_MODAL,
});

const showResolveCollisionModal = () => ({
  type: SHOW_RESOLVE_COLLISION_MODAL,
});

const hideResolveCollisionModal = () => ({
  type: HIDE_RESOLVE_COLLISION_MODAL,
});

const toggleLoadingPowerState = (data) => {
  return {
    type: TOGGLE_LOADING_POWER_STATE,
    payload: data,
  };
};

export {
  showLoader,
  hideLoader,
  showSupportModal,
  hideSupportModal,
  showAdminModal,
  hideAdminModal,
  showChangeCookingTimeModal,
  hideChangeCookingTimeModal,
  showL2AlertModal,
  hideL2AlertModal,
  setError,
  setIdle,
  showJogModal,
  hideJogModal,
  showResolveCollisionModal,
  hideResolveCollisionModal,
  toggleLoadingPowerState,
};

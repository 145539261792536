import React from 'react';

const ExclamationTriangle = (props) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.3173 30.5037L29.1253 10.7397C28.5905 9.85799 27.8375 9.12903 26.9389 8.62312C26.0403 8.11722 25.0265 7.85144 23.9953 7.85144C22.9641 7.85144 21.9503 8.11722 21.0517 8.62312C20.1532 9.12903 19.4001 9.85799 18.8653 10.7397L6.67331 30.5037C6.09419 31.4359 5.76913 32.5035 5.73047 33.6003C5.6918 34.6971 5.94088 35.7849 6.45293 36.7556C6.96497 37.7263 7.72213 38.546 8.64919 39.1334C9.57625 39.7208 10.6409 40.0553 11.7373 40.1037H36.2533C37.3497 40.0553 38.4144 39.7208 39.3414 39.1334C40.2685 38.546 41.0257 37.7263 41.5377 36.7556C42.0498 35.7849 42.2988 34.6971 42.2602 33.6003C42.2215 32.5035 41.8965 31.4359 41.3173 30.5037ZM38.9173 35.3877C38.6506 35.8965 38.2557 36.3269 37.7716 36.6363C37.2876 36.9457 36.7311 37.1233 36.1573 37.1517H11.8213C11.2468 37.1223 10.6901 36.9427 10.2067 36.6308C9.72335 36.3189 9.33026 35.8857 9.0667 35.3744C8.80315 34.8631 8.67839 34.2916 8.70481 33.7169C8.73124 33.1423 8.90796 32.5846 9.21733 32.0997L21.4213 12.2877C21.6852 11.8451 22.0595 11.4787 22.5076 11.2242C22.9556 10.9697 23.4621 10.836 23.9773 10.836C24.4926 10.836 24.999 10.9697 25.4471 11.2242C25.8951 11.4787 26.2694 11.8451 26.5333 12.2877L38.7373 32.0997C39.0599 32.5874 39.2477 33.1519 39.2814 33.7358C39.3151 34.3196 39.1936 34.902 38.9293 35.4237L38.9173 35.3877Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0508 15.516C23.653 15.516 23.2714 15.674 22.9901 15.9553C22.7088 16.2366 22.5508 16.6182 22.5508 17.016V27.504C22.5508 27.9018 22.7088 28.2833 22.9901 28.5647C23.2714 28.846 23.653 29.004 24.0508 29.004C24.4486 29.004 24.8301 28.846 25.1114 28.5647C25.3927 28.2833 25.5508 27.9018 25.5508 27.504V17.016C25.5508 16.6182 25.3927 16.2366 25.1114 15.9553C24.8301 15.674 24.4486 15.516 24.0508 15.516Z"
        fill={props.color}
      />
      <path
        d="M23.9883 33.8881C24.8167 33.8881 25.4883 33.2165 25.4883 32.3881C25.4883 31.5596 24.8167 30.8881 23.9883 30.8881C23.1599 30.8881 22.4883 31.5596 22.4883 32.3881C22.4883 33.2165 23.1599 33.8881 23.9883 33.8881Z"
        fill={props.color}
      />
    </svg>
  );
};

export default ExclamationTriangle;

import googleServer from '.';

const get = async (endpoint, params) => {
  return await googleServer.get(endpoint, {
    params,
  });
};

const googleService = {
  get,
};

export default googleService;

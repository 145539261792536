import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ReactTooltip from 'react-tooltip';

const MisoDialogTitle = (props) => {
  const {classes, children, onClose, disableTypography} = props;
  return (
    <DialogTitle className={classes.root} disableTypography>
      {disableTypography ? (
        children
      ) : (
        <Typography variant="h6">{children}</Typography>
      )}
      {onClose ? (
        <IconButton
          aria-label="Close"
          data-tip="Close Modal"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
          <ReactTooltip />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const styles = (theme) => ({
  root: {
    marginRight: theme.spacing.unit * 5,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
});

MisoDialogTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClose: PropTypes.func,
  disableTypography: PropTypes.bool,
};

export default withStyles(styles)(MisoDialogTitle);

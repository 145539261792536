// Define keyboard shortcuts for any screen in the application
// Using the npm package 'react-shortcuts'

export default {
  AUTOSUGGEST: {
    SELECT: 'alt+enter',
  },
  ERROR_MODAL: {
    SUBMIT: 'mod+enter',
  },
};

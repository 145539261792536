import React from 'react';

const Power = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0147 36.7078C21.3737 36.7146 18.7975 35.8905 16.6507 34.3523C14.5039 32.814 12.8953 30.6395 12.0526 28.1366C11.2099 25.6337 11.1757 22.929 11.955 20.4056C12.7342 17.8822 14.2874 15.6678 16.3947 14.0758C16.6478 13.8836 16.9669 13.7996 17.2819 13.8424C17.5968 13.8851 17.882 14.0511 18.0747 14.3038C18.267 14.557 18.3509 14.876 18.3082 15.191C18.2654 15.506 18.0995 15.7911 17.8467 15.9838C16.1286 17.289 14.8692 19.1059 14.25 21.1727C13.6307 23.2395 13.6837 25.4496 14.4012 27.4844C15.1186 29.5192 16.4636 31.2737 18.2422 32.4951C20.0208 33.7165 22.1413 34.3416 24.298 34.2805C26.4547 34.2194 28.5364 33.4751 30.243 32.155C31.9496 30.8348 33.193 29.007 33.7941 26.9348C34.3951 24.8626 34.3228 22.6531 33.5875 20.6247C32.8523 18.5962 31.492 16.8536 29.7027 15.6478C29.5434 15.5748 29.402 15.4677 29.2885 15.3342C29.175 15.2006 29.0921 15.0438 29.0457 14.8748C28.9993 14.7058 28.9905 14.5286 29.0199 14.3559C29.0493 14.1831 29.1161 14.0188 29.2158 13.8746C29.3155 13.7305 29.4455 13.6099 29.5968 13.5214C29.7481 13.4328 29.9169 13.3785 30.0914 13.3622C30.2659 13.346 30.4419 13.3681 30.6069 13.4271C30.772 13.4861 30.9221 13.5806 31.0467 13.7038C33.2856 15.2111 34.9813 17.3975 35.8841 19.941C36.7869 22.4845 36.8492 25.2507 36.0617 27.8322C35.2742 30.4138 33.6786 32.6742 31.5098 34.2807C29.341 35.8872 26.7137 36.7548 24.0147 36.7558V36.7078Z"
        fill="#525252"
      />
      <path
        d="M24.0008 19.7039C23.6825 19.7039 23.3773 19.5774 23.1523 19.3524C22.9272 19.1273 22.8008 18.8221 22.8008 18.5039V9.85186C22.8008 9.5336 22.9272 9.22837 23.1523 9.00333C23.3773 8.77828 23.6825 8.65186 24.0008 8.65186C24.319 8.65186 24.6242 8.77828 24.8493 9.00333C25.0743 9.22837 25.2008 9.5336 25.2008 9.85186V18.5039C25.2008 18.8221 25.0743 19.1273 24.8493 19.3524C24.6242 19.5774 24.319 19.7039 24.0008 19.7039Z"
        fill="#525252"
      />
    </svg>
  );
};

export default Power;

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import misoLogo from '../assets/Onion_Purple.svg';
import {colors, errorDrawerWidth} from '../styles';
import {getInfo} from '../util';
import LegendTable from './LegendTable';
import Timer from './Timer';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: errorDrawerWidth,
    backgroundColor: colors.white,
  },
  drawer: {
    width: errorDrawerWidth,
    flexShrink: 0,
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    minHeight: '8vh',
    height: 'auto',
    background: '#68535c',
  },
  drawerPaper: {
    width: errorDrawerWidth,
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    border: 'none',
  },
  toolbar: theme.mixins.toolbar,
  singleAlarmDrawer: {
    backgroundColor: colors.white,
  },
  timeHolder: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  locationHolder: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '.5em',
    justifyContent: 'space-between',
    flex: 1,
  },
  logo: {
    width: '3em',
    height: '3em',
  },
  locationColumn: {
    paddingTop: '.5em',
    display: 'flex',
    flexDirection: 'column',
  },
  hubContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    flex: 1,
  },
  drawerContainer: {
    display: 'flex',
    flex: 1,
    overflowY: 'auto',
  },
});
class ErrorStatuses extends Component {
  render() {
    const {classes, instance, instanceStates} = this.props;

    const {name, location, logo} = getInfo(instance);
    const {versionNumber} = instanceStates[instance] || '';

    return (
      <div className={classes.containerClass}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{paper: classes.drawerPaper}}
        >
          <div className={classes.title}>
            <div className={classes.timeHolder}>
              <Typography variant="subtitle2" style={{color: 'white'}}>
                MSC
              </Typography>
              <Timer
                format="LTS"
                variant="subtitle1"
                style={{color: 'white'}}
              />
            </div>
            {instance ? (
              <div className={classes.locationHolder}>
                <div
                  className={classes.locationColumn}
                  style={{color: 'white'}}
                >
                  <Typography style={{color: 'white'}}>{name}</Typography>
                  <Typography style={{color: 'white'}}>{location}</Typography>
                  <Typography style={{color: 'white'}}>
                    SW: {versionNumber}
                  </Typography>
                </div>
                {logo && <img src={logo} className={classes.logo} alt="logo" />}
              </div>
            ) : (
              <div className={classes.hubContent}>
                <Typography variant="h6" style={{color: 'white'}}>
                  Hub Page
                </Typography>
                <img src={misoLogo} className={classes.logo} alt="logo" />
              </div>
            )}
          </div>
          <Divider />
          <div className={classes.drawerContainer}>
            <LegendTable />
          </div>
          <div style={{background: '#68535c', height: '4vh'}}></div>
        </Drawer>
      </div>
    );
  }
}

ErrorStatuses.propTypes = {
  classes: PropTypes.object.isRequired,
  instance: PropTypes.string,
  instanceStates: PropTypes.object.isRequired,
};

export default withStyles(styles)(ErrorStatuses);

import React from 'react';

const Jog = (props) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0008 0.784428C13.7967 0.784428 10.6646 1.73264 8.00057 3.50917C5.33649 5.28569 3.26007 7.81073 2.03393 10.765C0.807793 13.7192 0.486989 16.97 1.11207 20.1062C1.73715 23.2424 3.28006 26.1232 5.54568 28.3843C7.81129 30.6454 10.6978 32.1852 13.8403 32.809C16.9828 33.4329 20.2401 33.1127 23.2003 31.889C26.1604 30.6653 28.6905 28.5931 30.4706 25.9343C32.2507 23.2756 33.2008 20.1497 33.2008 16.9521C33.2024 14.8285 32.7844 12.7254 31.9709 10.7631C31.1573 8.80085 29.9641 7.01792 28.4595 5.51631C26.9548 4.01469 25.1683 2.82385 23.2021 2.01191C21.2359 1.19997 19.1286 0.782853 17.0008 0.784428ZM24.6208 24.497H9.50079V9.40717H24.6208V24.497Z"
        fill={props.jogging ? '#FC648F' : '#525252'}
      />
    </svg>
  );
};

export default Jog;

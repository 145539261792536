import axios from 'axios';
import {DOMAIN_NAME} from '../../constants/env';

import * as server from './services';

const serverConfig = axios.create({
  baseURL: `http://` + DOMAIN_NAME + `:3001`,
  timeout: 1000 * 60,
});

export {server};
export default serverConfig;

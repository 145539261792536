import styles from './styles';

import {withStyles} from '@material-ui/core';
import React, {PureComponent} from 'react';

import uuidv1 from 'uuid/v1';
import {
  GrayErrorLegend,
  GrayQuestionLegend,
  GreenCheckLegend,
  RedErrorLegend,
  YellowCautionLegend,
} from '../../constants/arrays';

class LegendTable extends PureComponent {
  constructor() {
    super();
    this.tableHeaders = ['Status Icon', 'Status', 'Description'];
    this.legendData = [
      {
        icon: (
          <GreenCheckLegend.Icon
            style={{...GreenCheckLegend.legendStyle, fontSize: '2em'}}
          />
        ),
        status: 'OK',
        description: 'Everything is working fine.',
      },
      {
        icon: (
          <YellowCautionLegend.Icon
            style={{...YellowCautionLegend.legendStyle, fontSize: '2em'}}
          />
        ),
        status: 'WARN',
        description: 'If a warning threshold was set and it was exceeded.',
      },
      {
        icon: (
          <RedErrorLegend.Icon
            style={{...RedErrorLegend.legendStyle, fontSize: '2em'}}
          />
        ),
        status: 'ERROR',
        description: "There's an issue or sensor were triggered.",
      },
      {
        icon: (
          <GrayErrorLegend.Icon
            style={{...GrayErrorLegend.legendStyle, fontSize: '2em'}}
          />
        ),
        status: 'STALE',
        description: 'Status are not getting updated.',
      },
      {
        icon: (
          <GrayQuestionLegend.Icon
            style={{...GrayQuestionLegend.legendStyle, fontSize: '2em'}}
          />
        ),
        status: 'UNKNOWN',
        description: 'Cannot determine what the result is.',
      },
    ];
  }

  render() {
    const {classes} = this.props;
    return (
      // table container
      <div className={classes.tableContainer}>
        {/* table proper */}
        <table className={classes.table}>
          <thead>
            <th colSpan="3" className={classes.tableName}>
              Legend for Each Category
            </th>
          </thead>
          <tbody>
            {this.legendData.map((item, key) => {
              return (
                <tr key={key} style={{border: '0.0625rem solid black'}}>
                  <tr key={uuidv1(null, item.icon, 0)}>
                    <td className={classes.tableData}>{item.icon}</td>
                    <td className={classes.tableData}>{item.status}</td>
                  </tr>
                  <tr>
                    <td className={classes.tableData} colSpan="2">
                      {item.description}
                    </td>
                  </tr>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withStyles(styles)(LegendTable);

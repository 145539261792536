import React from 'react';
import {connect} from 'react-redux';
import {Button, Checkbox, Dialog} from '@material-ui/core';
import styles from './styles';

class PowerSettingsModal extends React.PureComponent {
  render() {
    const {
      classes,
      open,
      title,
      content,
      subtext,
      onClose,
      textLeft,
      onClickLeft,
      textRight,
      onClickRight,
      onQuickStart,
    } = this.props;

    return (
      <Dialog maxWidth="md" open={open} onClose={onClose}>
        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <p className={classes.title}>{title}</p>
          </div>
          {content && (
            <div className={classes.content}>
              {onQuickStart && <Checkbox onChange={onQuickStart} />}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '1rem',
                }}
              >
                <p className={classes.contentText}>{content}</p>
                <p className={classes.subtext}>{subtext}</p>
              </div>
            </div>
          )}
          <div className={classes.bottomPane}>
            {onClickLeft && (
              <Button className={classes.leftRightBtn} onClick={onClickLeft}>
                {textLeft}
              </Button>
            )}
            {onClickRight && textRight !== '' && (
              <Button className={classes.leftRightBtn} onClick={onClickRight}>
                {textRight}
              </Button>
            )}
          </div>
        </div>
      </Dialog>
    );
  }
}
const mapState = ({instances}) => ({
  instances,
});
const mapDispatch = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

export default connect(mapState, mapDispatch)(styles(PowerSettingsModal));

import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';

import {Button, ClickAwayListener, Typography} from '@material-ui/core';
import ReactTooltip from 'react-tooltip';

import DraggableDialog from '../components/DraggableDialog';
import MisoDialogTitle from '../components/MisoDialogTitle';

import {FaExclamationTriangle, FaArrowLeft} from 'react-icons/fa';

import {IconComponent} from '../util';
class ResolveCollisionModal extends PureComponent {
  state = {
    confirmModalVisible: false,
  };

  handleOnCloseClick = () => {
    this.setState({confirmModalVisible: false});
  };

  render() {
    const {
      classes,
      open,
      onClose,
      notificationState,
      openJogModal,
      jogRobot,
      jogging,
      troubleshooting,
      troubleshootRequester,
      troubleshootingReady,
    } = this.props;
    var window_title = 'Resolve Collision';
    var window_query = 'How would you like to resolve the collision?';

    return (
      <div>
        {notificationState && notificationState.length > 0 && (
          <>
            <DraggableDialog
              open={open}
              onClose={onClose}
              aria-labelledby="jog-confirmation-dialog"
              fullWidth={true}
              maxWidth="md"
            >
              <MisoDialogTitle
                id="jog-confirmation-dialog"
                onClose={onClose}
                style={{background: 'grey'}}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FaExclamationTriangle style={{marginRight: '0.5em'}} />
                  <span>{window_title}</span>
                </div>
              </MisoDialogTitle>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="h5"
                  style={{textAlign: 'center', paddingBottom: '2rem'}}
                >
                  Troubleshooting Mode{' '}
                  {troubleshooting ? 'ENABLED' : 'DISABLED'} by: <br />
                  <b>{troubleshootRequester}</b>
                </Typography>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: 'bold',
                      paddingRight: '1rem',
                      paddingBottom: '1rem',
                    }}
                  >
                    System Ready for Troubleshooting:
                  </Typography>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <IconComponent
                    icon2="Barrier"
                    width="2.5vw"
                    color={troubleshootingReady ? 'green' : 'red'}
                  />
                  <Typography
                    variant="h5"
                    style={{fontWeight: 'bold', paddingLeft: '0.5rem'}}
                  >
                    {troubleshootingReady ? 'YES' : 'NO'}
                  </Typography>
                </div>
              </div>
              <p
                style={{
                  color: 'red',
                  'text-align': 'center',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                {window_query}
              </p>
              <div className={classes.buttonContainerConfirmation}>
                <div className={classes.infoPadding}>
                  <ClickAwayListener
                    onClickAway={() => {
                      ReactTooltip.hide(this.sourceRef);
                    }}
                  >
                    <a
                      href={(ref) => (this.sourceRef = ref)}
                      data-for="source-custom-event"
                      data-tip="Use this option if: <br><li>The robot is stuck in a way where any piece of the robot arm (including baskets when it's grabbing them) is in contact with another equipment, OR</li><li>The sleeve is completely bunched in a specific location, OR</li><li>The arm is in contact with itself</li>"
                    >
                      <IconComponent
                        icon2="Information"
                        height={30}
                        width={30}
                        color="#000000"
                      />
                    </a>
                    <ReactTooltip
                      id="source-custom-event"
                      globalEventOff="click"
                      place="right"
                      html={true}
                      event="click"
                    />
                  </ClickAwayListener>
                </div>
                <Button
                  className={classes.button}
                  onClick={function (event) {
                    jogRobot();
                    onClose();
                    if (!jogging) {
                      openJogModal();
                    }
                  }}
                  disabled={!troubleshootingReady}
                >
                  <Typography variant="h5" color="inherit">
                    Abort Action and Jog the Robot
                  </Typography>
                </Button>
              </div>
            </DraggableDialog>
          </>
        )}
      </div>
    );
  }
}

ResolveCollisionModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  openJogModal: PropTypes.func.isRequired,
  jogging: PropTypes.bool.isRequired,
  jogRobot: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  notificationState: PropTypes.arrayOf(PropTypes.object).isRequired,
  elevatorState: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const styles = (theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  icon: {
    fontSize: '5.5rem',
  },
  buttonContainer: {
    width: '15rem',
    height: '15rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  buttonContainerConfirmation: {
    float: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  button: {
    borderStyle: 'solid',
    borderWidth: '0.3125rem',
    margin: '1em',
    width: '100%',
    height: '5rem',
  },
  infoPadding: {
    paddingLeft: '0.75rem',
  },
  containerStyle: {
    display: 'flex',
    flexDirection: 'column',
    width: '50em',
    height: '18em',
    padding: '1rem 1rem 0.5rem',
    alignItems: 'center',
  },
  negativeBtnStyle: {
    maxWidth: '7em',
    fontWeight: '900',
    background: theme.palette.secondary.main,
    fontSize: '3rem',
  },
  positiveBtnStyle: {
    minWidth: '7em',
    fontWeight: '900',
    background: '#e0e0e0',
    fontSize: '3rem',
  },
  bottomPaneStyle: {
    gap: '3.125rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  smallIcon: {
    fontSize: '3rem',
    paddingBottom: '1.5rem',
  },
});

export default withStyles(styles)(withRouter(ResolveCollisionModal));

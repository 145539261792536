const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '3rem',
  },
  smallButtonWide: {
    height: '7rem',
    marginBottom: '1rem',
  },
});

export default styles;

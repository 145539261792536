import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import ReactGA from 'react-ga';
import {withStyles} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import DraggableDialog from '../components/DraggableDialog';
import green_tick from '../assets/green_tick.svg';
import red_cross from '../assets/red_cross.svg';
import warning from '../assets/warning.svg';
import node_down from '../assets/node_down.svg';

const Table = withStyles((theme) => ({
  body: {
    margin: '2em',
  },
  bodyText: {
    fontWeight: '700',
    fontSize: '1.6rem',
    textAlign: 'center',
    alignItems: 'center',
    color: 'red',
  },
  mainHeader: {
    textAlign: 'center',
    alignItems: 'center',
  },
  headerText: {
    fontWeight: '900',
    fontSize: '1.8rem',
  },
  topHeader: {
    fontWeight: '900',
    fontSize: '1.2rem',
    padding: '0.2em 0em 0.2em 1.5em',
    backgroundColor: '#C7C7C7',
  },
  table: {
    borderCollase: 'collapse',
    width: '100%',
  },
  tableData: {
    paddingLeft: '3em',
  },
  tablerow: {
    textAlign: 'left',
    padding: '1rem',
  },
}))((props) => {
  const {data, classes} = props;
  if (data.length === 0) {
    return (
      <div className={classes.body}>
        <div className={classes.mainHeader}>
          <Typography className={classes.headerText}>
            Diagnostics Aggregator
          </Typography>
        </div>
        <div style={{padding: '5rem'}}>
          <Typography className={classes.bodyText}>
            !!! No Data Found. !!!
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.body}>
      <div className={classes.mainHeader}>
        <Typography className={classes.headerText}>
          Diagnostics Aggregator
        </Typography>
      </div>
      <div style={{margin: '1rem 0rem 0rem 1rem'}}>
        {data.map((val, key) => {
          return (
            <div key={key}>
              <Typography className={classes.topHeader}>
                {val.key
                  .replaceAll('_', ' ')
                  .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase()
                  )}{' '}
                {val.value.length < 1 && (
                  <img src={node_down} width={20} height={20} alt="node_down" />
                )}
              </Typography>
              {val.value.length > 0 &&
                val.value.map((element, key) => {
                  return (
                    <table key={key} className={classes.table}>
                      <tr className={classes.tablerow}>
                        <td
                          className={classes.tableData}
                          style={{textALign: 'left'}}
                        >
                          {element.key}
                          <span> </span>
                          {(element.value === 'Alive' &&
                            element.key.includes('Heartbeat')) ||
                          (element.value.includes('Desired frequency met') &&
                            element.key.includes('Frequency')) ||
                          element.value === 'OK' ? (
                            <img
                              src={green_tick}
                              width={20}
                              height={20}
                              alt="green_tick"
                            />
                          ) : element.value === 'Stale' ? (
                            <img
                              src={red_cross}
                              width={20}
                              height={20}
                              alt="red_tick"
                            />
                          ) : (
                            element.key.includes('Frequency') &&
                            (element.value === 'Frequency too low.' ||
                              element.value === 'Frequency too high.') && (
                              <img
                                src={warning}
                                width={20}
                                height={20}
                                alt="warning"
                              />
                            )
                          )}
                        </td>
                        <td
                          className={classes.tableData}
                          style={{textAlign: 'right'}}
                        >
                          {element.value}
                        </td>
                      </tr>
                    </table>
                  );
                })}
            </div>
          );
        })}
      </div>
    </div>
  );
});
class DiagnosticsMoadal extends PureComponent {
  constructor() {
    super();
    this.initialState = {
      resetting: false,
      resetSuccess: false,
    };
    this.state = this.initialState;
  }

  componentDidMount() {
    const page = this.props.location.pathname;
    ReactGA.modalview(page + '/diagnostics-modal');
  }
  render() {
    const {open, onClose, diagnosticsState} = this.props;
    let data = [];
    Object.entries(diagnosticsState).forEach(([key, value]) => {
      data.push({key: key, value: value});
    });

    return (
      <div style={{width: '100%', position: 'relative'}}>
        <DraggableDialog
          open={open}
          onClose={onClose}
          aria-labelledby="jog-dialog"
          fullWidth
          maxWidth="md"
        >
          <Table data={data} />
        </DraggableDialog>
      </div>
    );
  }
}

DiagnosticsMoadal.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  diagnosticsState: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const styles = {
  buttonContainer: {
    float: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
};

export default withStyles(styles)(withRouter(DiagnosticsMoadal));

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';

import {rosToMoment} from '../util';

class Timer extends Component {
  constructor() {
    super();
    this.state = {
      display: null,
    };
    this.unmounted = false;
    this.tick = this.tick.bind(this);
  }

  componentDidMount() {
    requestAnimationFrame(this.tick);
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  tick() {
    const {format, startTime, endTime} = this.props;
    if (!this.unmounted) {
      let time = moment();

      let prefix = '';
      if (startTime) {
        let diff = rosToMoment(endTime).diff(time);
        if (!diff) {
          requestAnimationFrame(this.tick);
          return;
        }

        time = moment(diff);
      }

      const display = prefix.concat(time.format(format));
      if (display !== this.state.display) {
        this.setState({display});
      }

      requestAnimationFrame(this.tick);
    }
  }

  render() {
    const {component, format, endTime, ...other} = this.props;
    const {display} = this.state;

    const ComponentProp = component ? component : Typography;
    return <ComponentProp {...other}>{display}</ComponentProp>;
  }
}

Timer.propTypes = {
  component: PropTypes.element,
  format: PropTypes.string.isRequired,
  startTime: PropTypes.object,
  endTime: PropTypes.object,
};

export default Timer;

import React, {memo} from 'react';
import {connect} from 'react-redux';

import {ReactComponent as MisoLogo} from '../../assets/new_miso_logo.svg';
import {smartCompare} from '../../util';

import styles from './styles';

class SignIn extends React.PureComponent {
  handleClose = () => {};

  render() {
    const {classes, appState} = this.props;
    const isAppLoading = appState.isLoading;
    return (
      <div className={classes.signInPane}>
        <div className={classes.topPane}>
          <MisoLogo className={classes.misoLogo} />
        </div>
        <div className={classes.centerPane}>
          <p className={classes.text}>Miso Support Center</p>
        </div>
        <div className={classes.bottomPane}>
          {isAppLoading && <p>Loading...</p>}
          <div
            id="google-btn"
            style={{display: isAppLoading ? 'none' : 'block'}}
          ></div>
        </div>
      </div>
    );
  }
}

const mapState = ({instances, appState}) => ({
  instances,
  appState,
});
const mapDispatch = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const Controller = memo(SignIn, smartCompare(['appState']));
export default connect(mapState, mapDispatch)(styles(Controller));

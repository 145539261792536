import React from 'react';
import PropTypes from 'prop-types';

const RemoteViewer = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 36 30"
    >
      <path
        d="M31.5356 -0.00024948H4.4756C3.46555 -0.0163103 2.49033 0.368787 1.76376 1.07062C1.03718 1.77245 0.618542 2.73374 0.599609 3.74375V20.6277C0.618542 21.6378 1.03718 22.5991 1.76376 23.3009C2.49033 24.0027 3.46555 24.3878 4.4756 24.3717H14.4476L14.0036 27.5037H10.9196C10.5886 27.5037 10.2712 27.6352 10.0371 27.8693C9.80309 28.1033 9.67161 28.4208 9.67161 28.7517C9.67161 29.0827 9.80309 29.4002 10.0371 29.6342C10.2712 29.8683 10.5886 29.9998 10.9196 29.9998H25.0916C25.4226 29.9998 25.74 29.8683 25.9741 29.6342C26.2081 29.4002 26.3396 29.0827 26.3396 28.7517C26.3396 28.4208 26.2081 28.1033 25.9741 27.8693C25.74 27.6352 25.4226 27.5037 25.0916 27.5037H21.9956L21.5636 24.3717H31.5356C32.0352 24.3797 32.5314 24.2891 32.996 24.1053C33.4605 23.9214 33.8843 23.6478 34.2432 23.3002C34.602 22.9525 34.8888 22.5375 35.0872 22.079C35.2856 21.6204 35.3918 21.1273 35.3996 20.6277V3.74375C35.3918 3.24418 35.2856 2.75105 35.0872 2.29251C34.8888 1.83398 34.602 1.41902 34.2432 1.07135C33.8843 0.723674 33.4605 0.450091 32.996 0.266224C32.5314 0.082357 32.0352 -0.00819121 31.5356 -0.00024948ZM19.4036 27.5037H16.6076L17.0516 24.3717H18.9596L19.4036 27.5037ZM32.8316 20.6277C32.8101 20.9556 32.6638 21.2628 32.4227 21.486C32.1816 21.7092 31.8641 21.8315 31.5356 21.8277H4.4756C4.14624 21.8345 3.82709 21.7132 3.58536 21.4894C3.34364 21.2655 3.19818 20.9567 3.1796 20.6277V20.0037H32.8316V20.6277ZM32.8316 17.4957H3.1796V3.74375C3.19818 3.41484 3.34364 3.10596 3.58536 2.88214C3.82709 2.65832 4.14624 2.53701 4.4756 2.54375H31.5356C31.8641 2.53998 32.1816 2.66229 32.4227 2.88552C32.6638 3.10874 32.8101 3.41589 32.8316 3.74375V17.4957Z"
        fill="#525252"
      />
      <path
        d="M9.9834 9.99588C12.1621 12.0245 15.0285 13.1523 18.0054 13.1523C20.9823 13.1523 23.8487 12.0245 26.0274 9.99588C23.8447 7.97512 20.9799 6.85254 18.0054 6.85254C15.0309 6.85254 12.166 7.97512 9.9834 9.99588ZM20.3754 9.99588C20.397 10.4746 20.2747 10.9487 20.0245 11.3574C19.7742 11.766 19.4074 12.0904 18.9711 12.2887C18.5349 12.487 18.0494 12.5502 17.5769 12.4702C17.1045 12.3901 16.6668 12.1705 16.3203 11.8395C15.9737 11.5086 15.7342 11.0815 15.6325 10.6132C15.5308 10.145 15.5716 9.65701 15.7496 9.21214C15.9277 8.76727 16.2348 8.38588 16.6315 8.11707C17.0282 7.84827 17.4962 7.70436 17.9754 7.70388C18.2837 7.69591 18.5905 7.74896 18.8783 7.85999C19.166 7.97101 19.429 8.13783 19.652 8.35083C19.875 8.56383 20.0538 8.81882 20.1779 9.10114C20.3021 9.38346 20.3692 9.68753 20.3754 9.99588Z"
        fill="#525252"
      />{' '}
    </svg>
  );
};

export default RemoteViewer;

RemoteViewer.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number]),
};

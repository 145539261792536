import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ExclamationTriangle from '../assets/helper-icons/ExclamationTriangle';

const AlarmMenu = ({classes, alarms, className, component}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const alarmActive = Boolean(alarms[0]);

  const openAlarms = (event) => {
    ReactGA.event({
      category: 'Robot Alarms',
      action: 'Open Alarms',
    });
    setAnchorEl(event.currentTarget);
  };

  const closeAlarms = () => {
    ReactGA.event({
      category: 'Robot Alarms',
      action: 'Close Alarms',
    });
    setAnchorEl(null);
  };

  const rootClick = (event) => {
    event.stopPropagation();
  };

  const buttonProps = {
    'aria-label': 'Alarms',
    onClick: openAlarms,
    'data-tip': 'See Alarms',
    'aria-haspopup': true,
    'aria-controls': 'alarm-menu',
    disabled: !alarmActive,
    color: 'secondary',
    className: classes.button,
  };

  return (
    <div className={className} onClick={alarmActive ? rootClick : null}>
      {component ? (
        <Button {...buttonProps} component="div">
          {component}
        </Button>
      ) : (
        <IconButton {...buttonProps}>
          <ExclamationTriangle color={alarmActive ? '#FC648F' : '#525252'} />
        </IconButton>
      )}
      <Menu
        id="alarm-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeAlarms}
        disableAutoFocusItem
        placement="bottom"
      >
        {alarms.length > 0 && (
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.alarmHeader}
          >
            Robot: {alarms[0].description}
          </Typography>
        )}
        {alarms.map((alarm, index) => (
          <MenuItem key={index} dense>
            <ListItemText
              primary={alarm.message}
              secondary={alarm.causeMessage}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const styles = (theme) => ({
  alarmHeader: {
    flex: 1,
    outline: 'none',
  },
  button: {},
});

AlarmMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  alarms: PropTypes.arrayOf(PropTypes.object).isRequired,
  component: PropTypes.element,
};

export default withStyles(styles)(AlarmMenu);

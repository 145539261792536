import {Card, Typography, Switch} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React from 'react';
import {toggleFlag} from '../ros_init';
import {callService} from '../util';

const styles = ({theme}) => ({
  mainDiv: {
    width: '16rem',
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
  },
  textDiv: {
    paddingLeft: '0.5rem',
  },
  textBold: {
    fontWeight: 'bold',
  },
});

class RushModeToggle extends React.Component {
  handleToggle = (toggle, instance) => {
    let reqMsg = {
      flag: 'rush_mode',
      value: toggle ? true : false,
    };
    callService(toggleFlag, instance, reqMsg, 'Rush Mode');
  };

  render() {
    const {classes, toggle, instance} = this.props;

    return (
      <Card className={classes.mainDiv}>
        <Switch
          checked={toggle}
          onClick={(e) => this.handleToggle(e.target.checked, instance)}
          color="primary"
        />
        <div className={classes.textDiv}>
          <Typography className={classes.textBold}>
            RUSH MODE IS ENABLED
          </Typography>
        </div>
      </Card>
    );
  }
}

export default withStyles(styles)(RushModeToggle);

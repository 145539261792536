import React from 'react';
import PropTypes from 'prop-types';

const Warning = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 80 80"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Warning</title>
      <g
        id="navbar-icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icons/warning" fill={props.color} fillRule="nonzero">
          <g>
            <path d="M78.3378287,59.4473622 L50.07766,8.91373235 C45.5371893,1.47814088 34.4689031,1.46826141 29.9223386,8.91373235 L1.66357621,59.4473622 C-2.97798833,67.0452809 2.64154539,76.6666667 11.7388931,76.6666667 L68.260168,76.6666667 C77.3498595,76.6666667 82.9793932,67.0530325 78.3378287,59.4473622 Z M39.9999993,67.5471579 C37.4159357,67.5471579 35.3124973,65.5010441 35.3124973,62.9874035 C35.3124973,60.4737629 37.4159357,58.4276492 39.9999993,58.4276492 C42.584063,58.4276492 44.6875014,60.4737629 44.6875014,62.9874035 C44.6875014,65.5010441 42.584063,67.5471579 39.9999993,67.5471579 Z M44.6875014,49.3081404 C44.6875014,51.821781 42.584063,53.8678948 39.9999993,53.8678948 C37.4159357,53.8678948 35.3124973,51.821781 35.3124973,49.3081404 L35.3124973,26.5093685 C35.3124973,23.9957279 37.4159357,21.9496141 39.9999993,21.9496141 C42.584063,21.9496141 44.6875014,23.9957279 44.6875014,26.5093685 L44.6875014,49.3081404 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Warning;

Warning.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string.isRequired,
};

import store from '../store';

/**
 * @param {(state: any) => any} callback
 * @returns {[any, () => any]}
 */
function useRedux(callback) {
  const {getState, dispatch} = store;
  const state = callback(getState());
  return [state, dispatch];
}

export {useRedux};

import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Easing from 'animated/lib/Easing';
import Animated from 'animated/lib/targets/react-dom';

import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import {FaSignOutAlt} from 'react-icons/fa';

import {SLOT_TYPES} from '../enums';
import {borderButtonPadding} from '../styles';

import 'react-dropdown/style.css';
import {rosToMoment} from '../util';
import InProgressTimer from './InProgressTimer';

const TextTypography = withStyles({
  root: {
    paddingTop: '0.625rem',
    fontSize: '1.25rem',
    fontWeight: 600,
    textAlign: 'center',
  },
})(Typography);

const styles = (theme) => ({
  slotInner: {
    paddingTop: '0.3125rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    zIndex: 1,
  },
  slotInnerInfo: {
    alignItems: 'flex-start',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    zIndex: 3,
  },
  progressBar: {
    zIndex: 2,
    bottom: 0,
    position: 'absolute',
    width: '100%',
    backgroundColor: 'rgba(158, 161, 164, .2)',
    maxHeight: '100%',
  },
  rootSelect: {
    display: 'flex',
    width: '100%',
  },
  foodSelect: {
    width: '80%',
  },
  bottomCard: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    zIndex: 3,
  },
  bottomCenter: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: borderButtonPadding,
  },
  crossedEye: {
    color: theme.palette.error.main,
  },
  bottomLeft: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    transform: 'rotate(270deg)',
  },
  bottomRight: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    color: theme.palette.error,
  },
});

class OccupiedSlot extends Component {
  constructor() {
    super();
    this.state = {
      progressHeight: new Animated.Value(0),
    };
  }

  componentDidMount() {
    const {ticket, rosHeaderTimeStamp} = this.props;
    if (ticket.submerge_time && ticket.unsubmerge_time) {
      this.animateProgress(
        ticket.submerge_time,
        ticket.unsubmerge_time,
        rosHeaderTimeStamp
      );
    }
  }

  componentDidUpdate(prevProps) {
    this.animateProgress(
      this.props.ticket.submerge_time,
      this.props.ticket.unsubmerge_time,
      this.props.rosHeaderTimeStamp
    );
  }

  animateProgress = (startTimeRos, endTimeRos, rosHeaderTimeStamp) => {
    const start_time = rosToMoment(startTimeRos);
    const end_time = rosToMoment(endTimeRos);
    const current_time = rosToMoment(rosHeaderTimeStamp);

    let time_diff = end_time.diff(current_time);
    let time_length = end_time.diff(start_time);

    time_diff = time_diff / 1000;
    time_length = time_length / 1000;

    const height = Math.floor(100 - (time_diff / time_length) * 100);

    Animated.timing(this.state.progressHeight, {
      toValue: height,
      easing: Easing.linear,
    }).start();
  };

  render() {
    const {
      classes,
      onSelectFood,
      currentFoodOption,
      onFreeSlotClick,
      slotType,
      slotID,
      rosHeaderTimeStamp,
      ticket,
      foodMenu,
      donenessLabel,
    } = this.props;
    const {progressHeight} = this.state;

    let foodList = Object.fromEntries(
      Object.entries(foodMenu).map(([key, value]) => [
        key,
        key.replace('_', ' '),
      ])
    );
    const newFoodMenu = foodList ? foodList : [currentFoodOption];

    return (
      <div className={classes.slotInner}>
        <Animated.div
          className={classes.progressBar}
          style={{
            height: progressHeight.interpolate({
              inputRange: [0, 100],
              outputRange: ['0%', '100%'],
            }),
          }}
        />
        {Object.entries(ticket).length > 0 && slotType === SLOT_TYPES.FRYER && (
          <div className={classes.slotInnerInfo}>
            <InProgressTimer
              endTime={ticket.unsubmerge_time}
              rosHeaderTimeStamp={rosHeaderTimeStamp}
            />
          </div>
        )}

        <div className={classes.slotInnerInfo} style={{height: '5vh'}}>
          <FormControl variant="outlined" fullWidth={true}>
            <Select
              value={currentFoodOption.value}
              onChange={onSelectFood}
              classes={{select: classes.foodSelect}}
              input={
                <OutlinedInput
                  fullWidth={true}
                  labelWidth={0}
                  name="foodInput"
                  id="selected-food"
                />
              }
            >
              {Object.entries(newFoodMenu).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextTypography style={{fontSize: '0.9375rem'}}>
            {donenessLabel}
          </TextTypography>
        </div>
        <div className={classes.bottomCard}>
          <IconButton
            data-tip="Free Slot"
            className={classes.bottomLeft}
            onClick={onFreeSlotClick}
          >
            <FaSignOutAlt />
          </IconButton>
        </div>
      </div>
    );
  }
}

OccupiedSlot.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectFood: PropTypes.func.isRequired,
  currentFoodOption: PropTypes.object.isRequired,
  onFreeSlotClick: PropTypes.func.isRequired,
  slotType: PropTypes.number,
  recipeState: PropTypes.string,
  actionStatus: PropTypes.number,
  ticket: PropTypes.object,
  actionTime: PropTypes.object,
  foodMenu: PropTypes.arrayOf(PropTypes.object),
};

export default withStyles(styles)(OccupiedSlot);

import React from 'react';
import PropTypes from 'prop-types';

const Broken = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 80 80"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Broken</title>
      <path
        stroke="#F69892"
        fill="#F69892"
        d="M38.27 6.531H26.345l-2.881 5.815c-.05.149 0 .298.099.397l4.62 3.23c.15.1.15.3.05.448l-5.316 5.865c-.1.1-.1.298 0 .397l2.981 3.23c.1.1.1.25.05.349l-5.068 7.603a.307.307 0 01-.546-.248l1.341-6.759c0-.1 0-.199-.1-.298l-4.123-3.926c-.1-.1-.1-.249-.05-.398l3.528-5.467c.1-.149.05-.298-.1-.397l-4.869-3.23c-.1-.05-.149-.2-.149-.3l1.441-6.36H5.826A3.828 3.828 0 002 10.307v25.347a3.828 3.828 0 003.826 3.826H38.17a3.828 3.828 0 003.826-3.826V10.308c.1-2.037-1.64-3.777-3.726-3.777z"
      ></path>
    </svg>
  );
};

export default Broken;

Broken.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string.isRequired,
};

import {GOOGLE_AUTH_TOKEN} from '../../constants/strings';
import storage from '../../storage';

const resolve = () => (config) => {
  let token = '';
  if (config.baseURL.includes('google')) {
    token = storage.get(GOOGLE_AUTH_TOKEN);
  }
  config.params = {id_token: token};
  return config;
};
const reject = () => (config) => {
  return Promise.reject(config);
};

const request = {resolve, reject};

export default request;

import React from 'react';
import PropTypes from 'prop-types';

const FryerBasket = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 32 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Fryer Basket</title>
      <g
        id="helper-icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icons/fryer-basket" fill={props.color} fillRule="nonzero">
          <path
            d="M23.8238 17.3043L23.1107 20.242L25.5591 20.2891C26.193 20.299 26.7567 19.8875 26.9405 19.2808L27.5108 17.3746L23.8238 17.3043ZM17.9955 17.1924L17.4461 20.1334L21.6881 20.2152L22.4009 17.2768L17.9955 17.1924ZM24.8426 13.106L24.1593 15.9217L27.9231 15.9933L28.7638 13.1807L24.8426 13.106ZM12.4627 17.087L12.4051 20.0368L16.0388 20.1066L16.5875 17.1659L12.4627 17.087ZM18.7801 12.9899L18.254 15.8085L22.7369 15.8939L23.4197 13.0786L18.7801 12.9899ZM25.8293 9.03951L25.1781 11.7235L29.1765 11.8L29.9772 9.11948L25.8293 9.03951ZM7.54686 16.9935L7.51853 18.5579C7.50391 19.3243 8.11315 19.9576 8.8797 19.9726L11.0141 20.0118L11.0741 17.0606L7.54686 16.9935ZM7.7627 5.88434L7.73192 7.30583L30.9174 7.74845C31.1681 7.75507 31.4052 7.62424 31.5363 7.40693C31.6674 7.18962 31.6727 6.9189 31.5502 6.69663C31.4274 6.47462 31.1955 6.33473 30.9418 6.32986L7.7627 5.88434ZM12.5441 12.8705L12.4892 15.6984L16.8461 15.7821L17.3719 12.9628L12.5441 12.8705ZM19.5401 8.91956L19.0383 11.6055L23.7551 11.696L24.4067 9.01267L19.5401 8.91956ZM7.62022 12.7676L7.56429 15.5956L11.0922 15.6651L11.1481 12.8371L7.62022 12.7676ZM12.6236 8.7866L12.5716 11.4822L17.6304 11.579L18.1324 8.89211L12.6236 8.7866ZM7.69649 8.68054L7.64318 11.376L11.1711 11.4455L11.2244 8.75004L7.69649 8.68054ZM0.837347 0.83256L0.81168 2.25592L3.62751 2.3089C3.99863 2.31621 4.35167 2.47083 4.60892 2.73854C4.86603 3.00632 5.00621 3.3653 4.99853 3.73642L4.91257 7.94513C4.90288 8.7262 5.5245 9.36909 6.30547 9.38563L6.45556 1.65659C6.46296 1.26494 6.15362 0.94041 5.76208 0.92902L0.837347 0.83256Z"
            fill={props.color}
          />
        </g>
      </g>
    </svg>
  );
};

export default FryerBasket;

FryerBasket.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string.isRequired,
};

const AWS_F3_QA_ENDPOINT = 'a1d4fkzm33sxb2-ats.iot.us-west-2.amazonaws.com'; // F3_QA #008971643127
const AWS_F3_PROD_ENDPOINT = 'a15wqol5nhxva9-ats.iot.us-west-2.amazonaws.com'; // F3_PROD #008971643125

const AWS_F3_QA_ACCOUNT_NUMBER = '008971643127';
const AWS_F3_PROD_ACCOUNT_NUMBER = '008971643125';

const AWS_ROLE_NAME = 'F3AuthenticatedMSCUser';
const AWS_REGION = 'us-west-2';

class AWS_ACCOUNT {
  constructor(name, endpoint, number, region, role_name) {
    this.name = name;
    this.endpoint = endpoint;
    this.number = number;
    this.region = region;
    this.role_name = role_name;
    this.cognito_pool_id = 'none';
  }
  get accountInfo() {
    return {
      name: this.name,
      endpoint: this.endpoint,
      number: this.number,
      region: this.region,
      role_name: this.role_name,
      cognito_pool_id: this.cognito_pool_id,
    };
  }
}
const AWS_ACCOUNTS = {
  'F3-QA': new AWS_ACCOUNT(
    'F3-QA',
    AWS_F3_QA_ENDPOINT,
    AWS_F3_QA_ACCOUNT_NUMBER,
    AWS_REGION,
    AWS_ROLE_NAME
  ),
  'F3-PROD': new AWS_ACCOUNT(
    'F3-PROD',
    AWS_F3_PROD_ENDPOINT,
    AWS_F3_PROD_ACCOUNT_NUMBER,
    AWS_REGION,
    AWS_ROLE_NAME
  ),
};

export {AWS_ACCOUNTS};

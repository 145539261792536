const MQTTRequestType = Object.freeze({
  ROS_PUBLISH: 'ros_publish',
  ROS_SUBSCRIBE: 'ros_subscribe',
  ROS_SERVICE: 'ros_service',
  ROS_PARAM_GET: 'ros_param_get',
  ROS_PARAM_SET: 'ros_param_set',
  ROS_UNSUBSCRIBE: 'ros_unsubscribe',
  ROS_UNSUBALL: 'ros_unsuball',
});

class MQTTRequest {
  constructor(request_json) {
    this.json = request_json;
    this._request_type = request_json.request_type;
    this._ros_topic = request_json.ros_topic;
    this.type = request_json.type;
    this.data = request_json.data;
    this._rid = undefined;
    this._user = request_json.user;
    this._acked = false;
    this._rTime = 0;
    this._int_id = 0;
    this._ackedTime = 0;
    this._respTime = 0;
    this._is_stat_published = false;
  }

  get request_type() {
    return this._request_type;
  }

  get ros_topic() {
    return this._ros_topic;
  }

  get acked() {
    return this._acked;
  }

  get rTime() {
    return this._rTime;
  }

  get rid() {
    return this._rid;
  }

  get user() {
    return this._user;
  }

  get int_id() {
    return this._int_id;
  }

  get is_stat_published() {
    return this._is_stat_published;
  }

  get respTime() {
    return this._respTime;
  }

  get ackedTime() {
    return this._ackedTime;
  }

  set setIntId(val) {
    this._int_id = val;
  }

  set setIsStatPublished(val) {
    this._is_stat_published = val;
  }

  set setRid(val) {
    this._rid = val;
  }
  set setUser(val) {
    this._user = val;
  }

  set setRequestType(val) {
    this._request_type = val;
  }

  set setAcked(val) {
    this._acked = val;
  }

  set setRTime(val) {
    this._rTime = val;
  }

  set setRespTime(val) {
    this._respTime = val;
  }

  set setAckedTime(val) {
    this._ackedTime = val;
  }

  set setData(val) {
    this.data = val;
  }

  getJson() {
    this.json.rid = this.rid;
    this.json.data = this.data;
    return this.json;
  }

  stat_metadata() {
    return {
      rid: this.rid,
      request_type: this.request_type,
      ros_topic: this.ros_topic,
      rTime: this.rTime,
      user: this.user,
    };
  }
}

export {MQTTRequest, MQTTRequestType};

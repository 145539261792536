import {withStyles} from '@material-ui/core';

const styles = withStyles((theme) => ({
  confirmationModal: {
    display: 'flex',
    flexDirection: 'column',
    width: '21.875rem',
    minHeight: '11.25rem',
    padding: '0 1.25rem',
  },
  topPane: {
    display: 'flex',
    flex: 0.8,
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 400,
    padding: 0,
    margin: 0,
    letterSpacing: 0,
  },
  centerPane: {
    display: 'flex',
    flex: 1.25,
    alignItems: 'center',
    justifyContent: 'center',
  },
  centerText: {
    fontSize: '1.25rem',
    fontWeight: 400,
    padding: 0,
    margin: 0,
    letterSpacing: 0,
  },
  bottomPane: {
    display: 'flex',
    flex: 0.8,
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '0.625rem',
  },
  positiveBtn: {
    width: '6.25rem',
    height: '50%',
    backgroundColor: '#68535c',
    color: 'white',
  },
  negativeBtn: {
    width: '6.25rem',
    height: '50%',
    backgroundColor: '#b04c4c',
    color: 'white',
  },
}));

export default styles;

import {withStyles} from '@material-ui/core';

const styles = withStyles((theme) => ({
  loadingPane: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
}));

export default styles;

const partial =
  (func, ...args) =>
  (...rest) =>
    func(...args, ...rest);

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function generate_mqtt_suffix(ros_topic) {
  // removing leading and trailing slashes from ros topic
  const tokens = ros_topic.replace(/^\/|\/$/g, '').split('/');
  return tokens.join('-');
}

function stat_obj(stat, desc, unit, producer, value, client_id) {
  const statObj = {};
  statObj.stat = stat;
  statObj.description = desc;
  statObj.unit = unit;
  statObj.producer = producer;
  statObj.value = value;
  statObj.client_id = client_id;

  return statObj;
}

function extract_user_from_mqtt_session(mqtt_session) {
  let user = mqtt_session.split('_')[2];
  return user ? user : 'UNKNOWN';
}

export {
  partial,
  sleep,
  generate_mqtt_suffix,
  stat_obj,
  extract_user_from_mqtt_session,
};

export const SET_ALARM_STATE = 'SET_ALARM_STATE';
export const SET_ROBOT_ARM_STATE = 'SET_ROBOT_ARM_STATE';
export const SET_FOODS = 'SET_FOODS';
export const SET_CAMERA_ID = 'SET_CAMERA_ID';
export const SET_BASKET_LIST = 'SET_BASKET_LIST';
export const SET_HOME_JOINTS = 'SET_HOME_JOINTS';
export const SET_SCANNER_STATE = 'SET_SCANNER_STATE';
export const SET_IN_RACK_STATES = 'SET_IN_RACK_STATES';
export const SET_READY_NEXT_RACK_STATES = 'SET_READY_NEXT_RACK_STATES';

export const SET_BASKET_STATES = 'SET_BASKET_STATES';
export const SET_COOKING_STATES = 'SET_COOKING_STATES';
export const SET_SHELF_STATES = 'SET_SHELF_STATES';
export const SET_GRIPPER_STATE = 'SET_GRIPPER_STATE';

export const RESET_TIMER = 'RESTART_TIMER';
export const ROBOT_PAUSED = 'ROBOT_PAUSED';
export const ARM_CLEANING = 'ARM_CLEANING';
export const HOME_POSITION = 'HOME_POSITION';
export const CALIBRATE = 'CALIBRATE';
export const ROBOT_TURNED_ON = 'ROBOT_TURNED_ON';

export const SET_UI_STATE = 'SET_UI_STATE';
export const SET_DIAGNOSTICS_STATE = 'SET_DIAGNOSTICS_STATE';

export const SET_HOPPER_STATE = 'SET_HOPPER_STATE';
export const SET_ELEVATOR_STATE = 'SET_ELEVATOR_STATE';
export const SET_FRYERS_STATE = 'SET_FRYERS_STATE';
export const SET_RELAY_STATE = 'SET_RELAY_STATE';
export const SET_NOTIFICATION_STATE = 'SET_NOTIFICATION_STATE';

export const SET_FLIPPY_ON = 'SET_FLIPPY_ON';
export const SET_MQTT_CONNECTION = 'SET_MQTT_CONNECTION';
export const RESET_ALL = 'RESET_ALL';

export const SET_MISO_MSG = 'SET_MISO_MSG';
export const SET_VERSION_NUMBER = 'SET_VERSION_NUMBER';
export const SET_GET_GRIPPER_SENSOR = 'SET_GET_GRIPPER_SENSOR';
export const SET_GET_DISPENSER_LOCK_SENSOR = 'SET_GET_DISPENSER_LOCK_SENSOR';
export const SET_GET_ELEVATOR_SENSOR = 'SET_GET_ELEVATOR_SENSOR';
export const SET_OFFSET_UPDATED = 'SET_OFFSET_UPDATED';
export const SET_ROS_HEADER_TIME_STAMP = 'SET_ROS_HEADER_TIME_STAMP';
export const SET_OFFSET_DATA = 'SET_OFFSET_DATA';

export const SET_AUTHENTICATED = 'SET-AUTHORIZED';
export const SET_AUTH_USER = 'SET-AUTH-USER';
export const CLEAR_AUTH_USER = 'CLEAR-AUTH-USER';
export const SET_AUTH_LOADING = 'SET-AUTH-LOADING';

export const SET_AWS_AVAILABLE = 'SET_AWS_AVAILABLE';

export const SET_DISABLED_FRYER_SENSOR_ARRAY =
  'SET_DISABLED_FRYER_SENSOR_ARRAY';
export const SET_NUM_FRYER_SENSORS = 'SET_NUM_FRYER_SENSORS';
export const SET_RUSH_MODE_FLAG = 'SET_RUSH_MODE_FLAG';
export const SET_BIT_STATE = 'SET_BIT_STATE';
export const SET_SAFETY_CONTROLLER_STATUS = 'SET_SAFETY_CONTROLLER_STATUS';

import React from 'react';

const OffsetTuning = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.2 14.172C6.88174 14.172 6.57652 14.0456 6.35148 13.8205C6.12643 13.5955 6 13.2903 6 12.972V7.2C6 6.88174 6.12643 6.57652 6.35148 6.35147C6.57652 6.12643 6.88174 6 7.2 6H12.972C13.2903 6 13.5955 6.12643 13.8205 6.35147C14.0456 6.57652 14.172 6.88174 14.172 7.2C14.172 7.51826 14.0456 7.82348 13.8205 8.04853C13.5955 8.27357 13.2903 8.4 12.972 8.4H8.4V12.972C8.4 13.2903 8.27357 13.5955 8.04853 13.8205C7.82349 14.0456 7.51826 14.172 7.2 14.172Z"
        fill="#525252"
      />
      <path
        d="M35.3793 13.8205C35.6044 14.0456 35.9096 14.172 36.2279 14.172C36.5461 14.172 36.8513 14.0456 37.0764 13.8205C37.3014 13.5955 37.4279 13.2903 37.4279 12.972V7.2C37.4279 6.88174 37.3014 6.57652 37.0764 6.35147C36.8513 6.12643 36.5461 6 36.2279 6H30.4559C30.1376 6 29.8324 6.12643 29.6073 6.35147C29.3823 6.57652 29.2559 6.88174 29.2559 7.2C29.2559 7.51826 29.3823 7.82348 29.6073 8.04853C29.8324 8.27357 30.1376 8.4 30.4559 8.4H35.0279V12.972C35.0279 13.2903 35.1543 13.5955 35.3793 13.8205Z"
        fill="#525252"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1516 15.6719H41.5196C41.5831 15.6703 41.6463 15.6816 41.7053 15.7051C41.7643 15.7286 41.8178 15.7639 41.8627 15.8089C41.9076 15.8538 41.9429 15.9073 41.9665 15.9663C41.99 16.0253 42.0013 16.0885 41.9996 16.1519V41.5199C41.9996 41.6472 41.9491 41.7693 41.8591 41.8593C41.769 41.9494 41.6469 41.9999 41.5196 41.9999H16.1516C16.0243 41.9999 15.9022 41.9494 15.8122 41.8593C15.7222 41.7693 15.6716 41.6472 15.6716 41.5199V16.1519C15.67 16.0885 15.6813 16.0253 15.7048 15.9663C15.7284 15.9073 15.7637 15.8538 15.8086 15.8089C15.8535 15.7639 15.907 15.7286 15.966 15.7051C16.025 15.6816 16.0882 15.6703 16.1516 15.6719ZM37.0762 37.0765C37.3012 36.8514 37.4276 36.5462 37.4276 36.2279V30.4559C37.4276 30.1377 37.3012 29.8324 37.0762 29.6074C36.8511 29.3824 36.5459 29.2559 36.2276 29.2559C35.9094 29.2559 35.6042 29.3824 35.3791 29.6074C35.1541 29.8324 35.0276 30.1377 35.0276 30.4559V35.0279H30.4556C30.1374 35.0279 29.8322 35.1544 29.6071 35.3794C29.3821 35.6044 29.2556 35.9097 29.2556 36.2279C29.2556 36.5462 29.3821 36.8514 29.6071 37.0765C29.8322 37.3015 30.1374 37.4279 30.4556 37.4279H36.2276C36.5459 37.4279 36.8511 37.3015 37.0762 37.0765Z"
        fill="#525252"
      />
      <path
        d="M7.2 37.4279H12.972C13.2903 37.4279 13.5955 37.3014 13.8205 37.0764C14.0456 36.8513 14.172 36.5461 14.172 36.2279C14.172 35.9096 14.0456 35.6044 13.8205 35.3793C13.5955 35.1543 13.2903 35.0279 12.972 35.0279H8.4V30.4559C8.4 30.1376 8.27357 29.8324 8.04853 29.6073C7.82349 29.3823 7.51826 29.2559 7.2 29.2559C6.88174 29.2559 6.57652 29.3823 6.35148 29.6073C6.12643 29.8324 6 30.1376 6 30.4559V36.2279C6 36.5461 6.12643 36.8513 6.35148 37.0764C6.57652 37.3014 6.88174 37.4279 7.2 37.4279Z"
        fill="#525252"
      />
    </svg>
  );
};

export default OffsetTuning;

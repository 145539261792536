import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FaCheck} from 'react-icons/fa';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
class JogButton extends Component {
  state = {
    inProgress: false,
  };

  componentDidUpdate(prevProps) {
    const {failed} = this.props;
    const {inProgress} = this.state;

    if (failed && inProgress) {
      // If 'failed' is true and 'inProgress' is true, update 'inProgress' to false
      this.setState({inProgress: false});
    }
  }

  callback = () => {
    this.setState({inProgress: false});
  };

  click = () => {
    const {onClick, disableInProgressProcess} = this.props;
    const {callback} = this;
    !disableInProgressProcess &&
      this.setState((prev) => ({...prev, inProgress: true}));
    onClick(callback);
  };

  render() {
    const {
      classes,
      children,
      label,
      disabled,
      color,
      success,
      disableInProgressProcess,
      disabledText,
    } = this.props;
    const {inProgress} = this.state;

    if (success) {
      return (
        <Paper className={classNames(classes.root, classes.successContainer)}>
          <Typography variant="h6" color="inherit" className={classes.label}>
            {label}
          </Typography>
          <FaCheck className={classes.icon} />
        </Paper>
      );
    } else {
      return (
        <Button
          variant="contained"
          color={color}
          className={classes.root}
          onClick={this.click}
          disabled={disabled}
        >
          {disabled && (
            <Typography className={classes.disabledText}>
              {disabledText}
            </Typography>
          )}
          {children}
          <Typography color="inherit" className={classes.label}>
            {label}
            {}
          </Typography>
          {/* {!disableInProgressProcess && inProgress && !success && (
            <CircularProgress className={classes.overlay} size={100} />
          )} */}
        </Button>
      );
    }
  }
}

const styles = (theme) => ({
  root: {
    width: '15rem',
    height: '15rem',
    display: 'flex',
    flexDirection: 'column',
    '&:disabled': {
      filter: 'contrast(0.5)',
      backgroundColor: 'rgba(0,0,0,0.65)',
      cursor: 'not-allowed',
      '& span': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  label: {
    position: 'absolute',
    bottom: theme.spacing.unit,
    fontSize: '1em',
    fontWeight: 900,
  },
  successContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    textTransform: 'uppercase',
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  icon: {
    fontSize: '5.5rem',
  },
  disabledText: {
    fontWeight: 'bolder',
    position: 'absolute',
    color: '#000000',
    fontSize: '1.875rem',
  },
});

JogButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  disabledText: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  success: PropTypes.bool,
  failed: PropTypes.bool,
};

JogButton.defaultPropTypes = {
  disabledText: '',
};

export default withStyles(styles)(JogButton);
